import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { IUser } from "../pages/business-dashboard/loan"
import { useEffect, useState } from "react"
import axios from "axios"
import { errorToastMessage, successToastMessage } from "../utils/toast-message"

const NotificationModal = ({ notifications }: any) => {
  const [loading, setLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)

  const acceptOrReject = async (endpoint: string, notification: any) => {
    if (endpoint.includes("accept")) {
      setLoading(true)
    } else {
      setRejectLoading(true)
    }
    try {
      const data = await axios.post(
        endpoint + "?notification_id=" + notification.id
      )
      console.log(data)

      setLoading(false)
      setRejectLoading(false)
      successToastMessage(data?.data?.message)
      window.location.reload()
    } catch (error) {
      setLoading(false)
      setRejectLoading(false)
      errorToastMessage(error)
      console.log(error)
    }
  }

  const markNotificationAsSeen = async () => {
    try {
      await axios.patch("/users/notifications/all")
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    markNotificationAsSeen()
  }, [])
  return (
    <div className="overflow-y-scroll p-[15px] absolute h-[300px] shadow-sm right-[220px] rounded-l-[10px] rounded-b-[10px] top-[70px] z-[5000]  bg-[#fff] drop-shadow-lg w-[350px]">
      <h4 className="text-[18px] font-[600] mb-[10px]">NOTIFICATIONS</h4>

      {notifications?.map((notification: any, index: number) => (
        <div
          key={index}
          className="hover:bg-gray-50 cursor-pointer py-[10px] border-t-[1px] border-t-[#888] "
        >
          <h5 className="font-[600] text-[16px]">{notification?.title}</h5>
          <p className="text-[14px] mt-[10px]">{notification.message}</p>

          <p className="text-[12px] mt-[10px]">
            {new Date(notification.createdAt).toDateString()}
          </p>

          {notification?.accept && (
            <div className="flex items-center mt-[14px]">
              <button
                disabled={loading || rejectLoading}
                onClick={() =>
                  acceptOrReject(notification.accept, notification)
                }
                className="active:opacity-30 bg-green-500 text-[#fff] text-[10px] p-[5px] px-[7px] rounded-[3px]"
              >
                {loading ? "Accepting..." : "Accept"}
              </button>
              <button
                disabled={loading || rejectLoading}
                onClick={() =>
                  acceptOrReject(notification.reject, notification)
                }
                className="active:opacity-30 ml-[10px] bg-red-500 text-[#fff] text-[10px] p-[5px] px-[7px] rounded-[3px]"
              >
                {rejectLoading ? "Rejecting..." : "Reject"}
              </button>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default function Navbar() {
  const location = useLocation()
  const [showNotifications, setShowNotifications] = useState(false)
  const [notifications, setNotifications] = useState()
  const [unreadNotifications, setUnreadNotifications] = useState(0)

  const getNotifications = async () => {
    try {
      const response = await axios.get("/users/notifications/all")
      setNotifications(response.data.notifications)
      const unread = response.data.notifications.filter(
        (data: any) => data.status.toLowerCase() === "unread"
      )
      setUnreadNotifications(parseInt(unread.length))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const controller = new AbortController()

    getNotifications()
    return () => {
      controller.abort()
    }
  }, [])
  let pathname = location.pathname
  const currentUser: IUser = useSelector((state: any) => state.user.user)

  return (
    <div
      className={`z-[100 fixed top-0 left-0 right-0 h-[75px]  bg-[#fff] flex ${
        pathname.includes("onboarding") ? "justify-between" : "justify-end"
      } items-center px-[59px] py-[20px]`}
    >
      {/* <Link
        to={"/"}
        className={`${pathname.includes("onboarding") ? "block" : "hidden"}`}
      >
        <img
          src="/images/logo.svg"
          alt="logo"
          className={`${pathname.includes("onboarding") ? "block" : "hidden"}`}
        />
      </Link> */}

      <div className="flex items-center">
        <button
          className="relative"
          onClick={() => setShowNotifications(!showNotifications)}
        >
          <img
            src="/images/bell-icon.svg"
            className="h-[24px] w-[24px]"
            alt="notification"
          />
          {unreadNotifications > 0 && (
            <div className="absolute items-center justify-center text-[8px] text-[#fff] font-[600] top-0 right-0 bg-[#D09600] h-[12px] w-[12px] rounded-full">
              {unreadNotifications}
            </div>
          )}
        </button>
        <button className="ml-[30px] flex items-center">
          {!currentUser?.currentUser?.profile_image && (
            <div className="bg-[#000] flex items-center justify-center rounded-full h-[35px] w-[35px]">
              <h4 className="text-[18px] text-[#fff] font-[700]">
                {currentUser?.currentUser?.name?.split(" ")[0][0]}
                {currentUser?.currentUser?.name?.split(" ")[0].length > 1
                  ? currentUser?.currentUser?.name?.split(" ")[0][1]
                  : ""}{" "}
              </h4>
            </div>
          )}
          {currentUser?.currentUser?.profile_image && (
            <img
              src={currentUser?.currentUser?.profile_image}
              className="w-[35px] h-[35px] rounded-full  object-cover overflow-hidden"
              alt="profile"
            />
          )}
          <div className="ml-[16px]">
            <h5 className="text-[#292D32] text-[16px] font-[700]">
              Hello {currentUser?.currentUser?.name?.split(" ")[0]}
            </h5>
          </div>
        </button>
      </div>

      {showNotifications && <NotificationModal notifications={notifications} />}
    </div>
  )
}
