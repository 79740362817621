export function isValidDate(dateString: string): boolean {
    // Define an array of month names for validation
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Regular expression pattern to match the format
    const datePattern = /^(\d{2}) (January|February|March|April|May|June|July|August|September|October|November|December) (\d{4})$/;

    // Check if the date string matches the pattern
    const match = dateString.match(datePattern);

    if (!match) {
        return false; // Doesn't match the pattern
    }

    const day = parseInt(match[1], 10);
    const month = match[2];
    const year = parseInt(match[3], 10);

    // Check if the month is valid
    if (!months.includes(month)) {
        return false;
    }

    // Check if the day is valid for the given month
    const daysInMonth = new Date(year, months.indexOf(month) + 1, 0).getDate();
    if (day < 1 || day > daysInMonth) {
        return false;
    }

    // Check if the year is valid
    if (year < 1000 || year > 9999) {
        return false;
    }

    return true; // Date is valid
}

// Example usage:
const date1 = '04 October 1960';
const date2 = '32 February 2022'; // Invalid date

console.log(isValidDate(date1)); // true
console.log(isValidDate(date2)); // false
