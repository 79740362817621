import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import { motion } from "framer-motion";
import { Modal } from "../../components";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../utils/base64-converter";
import { FLATINTEREST, ILoan } from "./sme";
import axios from "axios";
import Loader from "../../components/Loader";
import { errorToastMessage } from "../../utils/toast-message";

interface LoanProps {
  modalType: string;
  setModalType: (type: any) => void;
  setLoanAmount: (term: any) => void;
  lpo_source: string;
  setLpoSource: (source: any) => void;
  loan_amount: string;
  setShowModal: (shiw: any) => void;
  lpoSources: any[];
}
const ApplyForLoanModal = ({
  lpo_source,
  setLpoSource,
  modalType,
  setModalType,
  setShowModal,
  loan_amount,
  lpoSources,
  setLoanAmount,
}: LoanProps) => {
  const [invoice, setInvoice] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [preferred_tenure, setPreferredTenure] = useState(3);

  const MAX_FILE_SIZE = 1048576;

  const apply = async () => {
    let invoices = await convertToBase64(invoice);

    setLoading(true);
    let body = {
      amount_requested: loan_amount,
      lpo_source,
      loan_type: "lpo",
      invoice: invoices,
      preferred_tenure,
      interest_rate: FLATINTEREST,
    };

    // let body = {
    //   amount_requested: loan_amount,
    //   lpo_source,
    //   loan_type: "lpo",
    //   invoice: invoices,
    // };

    try {
      const response = await axios.post("user-loans/apply", body);

      console.log(response.data);
      setLoading(false);
      setModalType("processing");
    } catch (error: any) {
      setLoading(false);
      let errorMessage = error?.response?.data?.errors[0];
      console.log(errorMessage);
      toast.error(errorMessage ? errorMessage.message : "An Error Occurred!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "apply"
            ? "Apply for an LPO"
            : modalType === "review"
              ? "Review and apply"
              : ""}
        </h4>
        <button
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img alt="close" src="/images/close-circle.svg" />
        </button>
      </div>

      {modalType === "apply" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <label className="sm:text-[20px] text-[15px] text-[#333]">
            LPO Source

            <input
              value={lpo_source}
              id="lpo-source"
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] text-[14px] sm:text-[16px]"
              onChange={(e) => setLpoSource(e.target.value)}
            />
            {/* <select
              onChange={(e) => setLpoSource(e.target.value)}
              className="w-[100%] mt-[11px] block outline-none px-[26px] sm:h-[50px] h-[40px]  border-[#292D32] border-[1px] rounded-[8.43px] sm:text-[16px] text-[14px]"
            >
              <option value={""}>----Select Lpo Source-----</option>
              {lpoSources.map((source, index) => (
                <option key={index} value={source.name}>
                  {source.name}
                </option>
              ))}
            </select> */}
          </label>

          <div className="w-[100%] mt-[40px]">
            <label
              htmlFor="amount"
              className="text-[#292D32] sm:text-[20px] text-[14px] font-[600]"
            >
              Requested amount
            </label>

            <input
              value={loan_amount}
              id="amount"
              type={"number"}
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] text-[14px] sm:text-[16px]"
              onChange={(e) => setLoanAmount(e.target.value)}
            />
          </div>

          <div className="sm:text-[20px] text-[14px] text-[#333] my-[40px]">
            Upload LPO
            <input
              type={"file"}
              className="hidden"
              id="upload"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  if (e.target.files[0].size > MAX_FILE_SIZE) {
                    toast.error("File size cannot be greater than 1mb", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    return;
                  }
                  setInvoice(e.target.files[0]);
                }
              }}
            />
            <label
              className="sm:text-[16px] text-[14px] active:bg-[#f5f5f5] flex items-center text-[#1E1E1E] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] border-[1px] rounded-[8.43px]"
              htmlFor="upload"
            >
              {invoice ? invoice.name : "Choose file"}
            </label>
          </div>

          <h4 className="sm:text-[25px] text-[20px] mb-[20px] font-[600]">
            Choose A Tenure
          </h4>

          {[3, 6, 9, 12, 15, 18, 21, 24].map((v, i) => (
            <label key={i} className="form-control max-[620px]:text-[16px] ">
              <input
                onChange={() => setPreferredTenure(v)}
                value={v}
                checked={preferred_tenure === v}
                type="radio"
                name="radio"
              />
              {v} Months
            </label>
          ))}

          <button
            disabled={loading || !loan_amount || !lpo_source || !invoice}
            onClick={apply}
            className={`
            ${!invoice || !loan_amount || !lpo_source
                ? "opacity-10"
                : "opacity-100"
              }
            flex items-center justify-center rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px]`}
          >
            {loading && (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
            {loading ? "Processing..." : "Apply"}
          </button>
        </div>
      ) : (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <img
            alt="processing"
            src="/images/processing-icon.svg"
            className="mb-[50px]"
          />
          <h5 className="text-center font-[600] sm:text-[24px] text-[18px] mb-[15px]">
            Application processing
          </h5>
          <p className="text-center sm:text-[20px] text-[14px] mb-[60px]">
            you will be notified on the status of your application
          </p>
          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] sm:ml-[37px] text-[#fff] sm:text-[24px] text-[15px] font-[600] px-[76px] py-[15px]"
          >
            Confirm
          </button>
        </div>
      )}
    </motion.div>
  );
};

function Lpo() {
  const [date, setDate] = useState<any>(new Date());
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [lpo_source, setLpoSource] = useState("");
  const [loan_amount, setLoanAmount] = useState("0");
  const [loading, setLoading] = useState(false);
  const [active_loan_amount, setActiveLoanAmount] = useState(0);
  const [due_balance, setDueBalance] = useState(0);
  const [payback_date, setPaybackDate] = useState("");
  const [outstanding_payment, setOutstandingPayment] = useState(0);
  const [hasActiveLoan, setHasActiveLoan] = useState(false);

  const [lpoSources, setLpoSources] = useState([]);
  const [myLoans, setMyLoans] = useState<ILoan[]>([]);
  const getLoans = async () => {
    try {
      const response = await axios.get("user-loans/?loan_type=lpo");
      setMyLoans(response.data.loans);
      setHasActiveLoan(response.data.hasActiveLoan);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getLpoSources = async () => {
    try {
      const response = await axios.get("admin/lpo-company");
      setLpoSources(response.data.lpoCompanies);
    } catch (error) {
      console.log(error);
    }
  };
  const getDueLoan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "user-loans/current-month/outstanding-amount?loan_type=lpo"
      );
      setDueBalance(response.data.monthly_payment);
      setOutstandingPayment(response.data.outstanding_payment);
      setPaybackDate(response.data.payback_date);
      setActiveLoanAmount(response.data.active_loan_amount);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getLoans();
    getDueLoan();
    getLpoSources();
  }, []);

  if (loading) {
    <Loader />;
  }
  return (
    <div className="flex sm:flex-row flex-col h-[100%] w-[100%] pt-[80px] px-[20px] sm:pt-[55px] sm:px-[55px] sm:p-[55px] transition-opacity ease-in duration-300 bg-[#F9F9F9]">
      <div className="sm:flex-[8] sm:mr-[24px] flex flex-col">
        <div className=" max-[620px]:max-w-[calc(100vw-40px)] overflow-x-scroll min-h-[200px]">
          <div className="w-fit flex items-center justify-between">
            <div className="max-[620px]:w-[220px] sm:min-w-[60%] pt-[12px] sm:pt-[26px]  sm:px-[26px] px-[23px] sm:p-[26px] bg-[#fff] rounded-[10px] flex-1 mr-[33px] max-[620px]:h-[119px]">
              <p className="text-[#292D32] sm:mb-[35px] mb-[30px] sm:text-[14px] text-[12px]">
                Active loan amount
              </p>

              <h4 className="font-[600] sm:text-[48px] text-[25px] text-[#000000C7]">
                ₦{active_loan_amount.toLocaleString()}
              </h4>
            </div>

            <div className="max-[620px]:w-[220px] sm:min-w-[60%] pt-[12px] sm:pt-[26px] sm:px-[26px] px-[23px] sm:p-[26px] bg-[#fff] rounded-[10px] flex-1 mr-[33px] max-[620px]:h-[119px]">
              <p className="text-[#292D32] sm:mb-[35px] mb-[30px] sm:text-[14px] text-[12px]">
                Due Balance
              </p>

              <h4 className="sm:text-[48px] text-[24px] font-[700]">
                ₦{parseFloat(due_balance.toFixed(1)).toLocaleString()}
              </h4>
              {due_balance > 0 && (
                <span className="text-gray-400 text-[14px]">
                  Due by: {new Date(payback_date).toLocaleDateString()}
                </span>
              )}
              {outstanding_payment > 0 && (
                <span className="text-red-400 text-[14px] block mt-[5px] font-[700]">
                  Outstanding payment: ₦{outstanding_payment.toLocaleString()}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="rounded-[10px] sm:w-[100%] w-[90%] self-center mt-[27px] bg-[#533C00] p-[18px] flex flex-col text-[#fff] items-center">
          <p className="font-[600] sm:text-[24px] text-[14px] mb-[21px]">Lpo</p>

          <button
            onClick={() => {
              if (hasActiveLoan) {
                errorToastMessage({
                  response: {
                    data: { errors: [{ message: "You have unrepaid loan" }] },
                  },
                });
              } else {
                setShowModal(true);
                setModalType("apply");
              }
            }}
            className="px-[39px] py-[12px] active:bg-[#eae9e9] bg-[#fff] w-fit rounded-[10px] text-[#292D32C7]  sm:text-[20px] text-[14px] font-[600]"
          >
            Apply
          </button>
        </div>

        <div className="mt-[40px] justify-between flex items-center">
          <h4 className="sm:text-[24px] text-[14px] font-[500] text-[#252525]">
            Lpo Loans
          </h4>
        </div>
        <div className=" overflow-x-auto mt-[12px] rounded-t-[10px]">
          <table className="w-full text-[12px] text-left text-[#292D32]">
            <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Loan ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
              </tr>
            </thead>
            <tbody>
              {myLoans?.map((loan, index) => (
                <tr
                  className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                  key={index}
                >
                  <td className="px-6 py-4  ">
                    <p className="truncate max-w-[100px]"> {loan.id}</p>
                  </td>
                  <td className="px-6 py-4">{loan.user.name}</td>
                  <td className="px-4 py-3">
                    <span
                      className={`${loan.loan_status === "invested" ||
                        loan.loan_status === "granted" ||
                        loan.loan_status === "repaid" ||
                        loan.loan_status === "repaying"
                        ? "bg-[#059e33]"
                        : loan.loan_status === "pending" ||
                          loan.loan_status === "published"
                          ? "bg-[#fcba03]"
                          : "bg-[#fc2403]"
                        } text-[#fff] px-[5px] rounded-[5px] py-[3px] capitalize`}
                    >
                      {" "}
                      {loan.loan_status}
                    </span>
                  </td>

                  <td className="px-6 py-4">
                    {" "}
                    ₦{loan.amount_requested.toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* <div className='flex items-center justify-between mt-[28px]'>
                        <span className='text-[16px] text-[#000000A1]'>Showing 1-30 of list</span>

                        <div className='flex items-center '>
                            <button className='bg-[#D09600] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                                <img src='/images/caret-back.svg' />
                            </button>
                            {
                                [1, 2, 3, 4].map((val, index) => (
                                    <button key={index} className='hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                                        {val}
                                    </button>

                                ))
                            }

                            <button className='bg-[#D09600] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                                <img src='/images/caret-right.svg' />
                            </button>

                        </div>
                    </div> */}
        </div>
      </div>
      <div className="sm:flex-[4] max-[620px]:mt-[24px] rounded-[10px] bg-[#fff] max-[620px]:mb-[100px]">
        <Calendar onChange={setDate} value={date} />
        <div className="sm:p-[33px] p-[12px] max-[620px]:mt-[12px]">
          <button className="text-[#fff] items-center mb-[19px] rounded-[6px] px-[19px] py-[12px] bg-[#D09600] flex justify-between w-[100%]">
            <div>
              <h5 className="text-[20px] font-[500] mb-[7px]">Upcoming</h5>
              <span className="text-[16px] font-[300]">Due: March 13</span>
            </div>
            <div className="bg-[#fff] p-[4px] rounded-full">
              <img src="/images/arrow-right.svg" alt="icon" />
            </div>
          </button>
          <button className="border-[0.5px] border-[#292D32] text-[#292D32] items-center mb-[19px] rounded-[6px] sm:px-[19px] px-[12px] sm:py-[12px] py-[8px] bg-[#FFF] flex justify-between w-[100%]">
            <div>
              <h5 className="sm:text-[20px] text-[14px] font-[500] mb-[7px]">
                Upcoming
              </h5>
              <span className="sm:text-[16px] text-[12px] font-[300]">
                Due: March 13
              </span>
            </div>
            <div className="bg-[#fff] p-[4px] border-[0.5px] border-[#292D32] rounded-full">
              <img
                src="/images/arrow-right.svg"
                alt="icon"
                className="max-[620px]:w-[12px] max-[620px]:w-[12px] "
              />
            </div>
          </button>

          <button className="border-[0.5px] border-[#292D32] text-[#292D32] items-center mb-[19px] rounded-[6px] px-[19px] py-[12px] bg-[#FFF] flex justify-between w-[100%]">
            <div>
              <h5 className="sm:text-[20px] text-[14px] font-[500] mb-[7px]">
                Upcoming
              </h5>
              <span className="sm:text-[16px] text-[12px] font-[300]">
                Due: March 13
              </span>
            </div>
            <div className="bg-[#fff] p-[4px] border-[0.5px] border-[#292D32] rounded-full">
              <img
                src="/images/arrow-right.svg"
                alt="icon"
                className="max-[620px]:w-[12px] max-[620px]:w-[12px] "
              />
            </div>
          </button>
        </div>
      </div>

      {showModal && (
        <Modal>
          <ApplyForLoanModal
            lpo_source={lpo_source}
            lpoSources={lpoSources}
            setLpoSource={setLpoSource}
            modalType={modalType}
            setModalType={setModalType}
            setLoanAmount={setLoanAmount}
            loan_amount={loan_amount}
            setShowModal={setShowModal}
          />
        </Modal>
      )}
    </div>
  );
}

export default Lpo;
