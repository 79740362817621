import React from "react";
import { Modal } from "./Modal";

function ImagePopUp({ image, close }: { image: string; close: () => void }) {
  return (
    <Modal>
      <div className="w-[100%] h-[100%] flex items-center justify-center overflow-y-scroll">
        <button
          onClick={close}
          className="active:scale-75 transform-gpu fixed top-10 right-10"
        >
          <img
            alt="close"
            src="/images/close-circle-white.svg"
            className="w-[50px] h-[50px]"
          />
        </button>

        <img src={image} alt="popup" className="max-w-[90vw]" />
      </div>
    </Modal>
  );
}

export default ImagePopUp;
