import React, { useEffect, useRef } from "react"
import AuthHeader from "../auth/auth-header"

import "./style.css"
import { Footer } from "./landing-home"
import { useLocation } from "react-router-dom"

function AboutUs() {
  const targetRef = useRef<any>(null)

  let location = useLocation()

  const scrollToTarget = () => {
    targetRef?.current!.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    if (location.hash.includes("contact")) {
      scrollToTarget()
    }
  }, [location.hash])
  return (
    <div className="bg-[#fff] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] ">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
        <div className="sm:px-[78px] overflow-y-hidden overflow-x-hidden px-[20px] pb-[50px] sm:py-[100px] py-[10px]  flex flex-wrap max-[600px]:flex-col ">
          <div className="flex-1 z-[100] ">
            <h2 className="sm:leading-[68px] font-[600] sm:text-[64px] text-[40px] text-[#fff]">
              About Us
            </h2>
            <p className="my-[29px] sm:w-[60%] sm:text-[16px] text-[13px] text-[#fff] ">
              Monzone started out with the desire to give businesses in Africa a
              better chance of survival, through easy access to credit,
              financial advisory, and cashflow management. Our credit
              marketplace demystifies lending, connecting investors to
              trustworthy businesses{" "}
            </p>

            <div className=" sm:px-[30px] sm:py-[15px] px-[23px] py-[12px]" />
          </div>

          {/* <img alt='bg' src='/images/bg-image.svg' className='xl:max-w-[60vw] sm:block flex-1 sm:mt-[0]  min-w-[200px] max-[600px]:w-[100%] max-[600px]:h-[auto]  object-cover ' /> */}
        </div>
      </div>

      <div className="p-[3%] py-[8%] bg-[#fff]">
        <div className="flex items-center sm:flex-row flex-col-reverse">
          <div className="flex-1 px-[19px]">
            <h3 className="sm:mb-[36px] mb-[15px] sm:text-[64px] text-[40px] font-[600] sm:leading-[68px] leading-[38px]">
              Who we are
            </h3>

            <p className="sm:text-[20px]  text-[16px] leading-[25px] sm:leading-[42px]">
              Monzone Credit Limited is a financial services company, licensed
              and authorized by the Lagos State Government of Nigeria to meet
              the financial needs of individuals and organizations by providing
              Business and consumer finance services, Trade Finance services,
              Contract finance services, Purchase order and Invoice discounting
              services, Equipment leasing services, Funds Management and
              Financial Advisory.
            </p>
          </div>

          <div className="flex-1 sm:ml-[90px]  sm:mb-[0px] mb-[60px]">
            <div className="w-fit relative" style={{ padding: "19px" }}>
              <div className="max-[620px]:hidden h-[100%] w-[100%] absolute top-[-100px] left-[-100px] bg-[#fff]"></div>
              <div className="max-[620px]:hidden h-[100%] w-[100%] absolute top-[100px] right-[-100px] bg-[#fff]"></div>
              <img
                alt="loan"
                src="/images/laptop.jpeg"
                className="z-[100000]"
                style={{
                  borderRadius: "10px",
                  objectFit: "contain"
                }}
              />
              <img
                alt="loan"
                src="/images/laptop.jpeg"
                className="max-[720px]:hidden absolute top-[19px] left-[19px]"
                style={{
                  borderRadius: "10px",
                  objectFit: "contain"
                }}
              />
            </div>
          </div>
        </div>
        <div ref={targetRef} />
        <div className="flex  sm:flex-row flex-col sm:mt-[280px] mt-[100px]">
          <div className="flex-1 px-[19px]">
            <h3 className="sm:mb-[36px] mb-[15px] sm:text-[64px] text-[40px] font-[600] sm:leading-[68px] leading-[38px]">
              You can talk to us
            </h3>
            {/* <p className="sm:text-[20px] text-[16px] leading-[25px] sm:leading-[42px] ">
              We're more than happy to offer financial advisory. Please leave a
              message below and we will be in touch{" "}
            </p> */}

            <p className="sm:text-[20px] text-[16px] leading-[25px] sm:leading-[42px] ">
              Got questions? We are more than happy to answer them. Please leave
              a message below and we would be in touch
            </p>
          </div>

          <form
            className="flex-1 sm:mt-[0px] mt-[50px] px-[19px]"
            onSubmit={(e) => e.preventDefault()}
          >
            <label>
              Your name
              <input className="outline-none px-[15px] sm:w-[90%] w-[100%] mt-[9px] mb-[43px] border-[1px] h-[50px] rounded-[5px] block" />
            </label>
            <label>
              Email
              <input
                type="email"
                className="outline-none px-[15px] sm:w-[90%] w-[100%] mt-[9px] mb-[43px] border-[1px] h-[50px] rounded-[5px] block"
              />
            </label>
            <label>
              Message
              <textarea className="outline-none p-[15px] sm:w-[90%] w-[100%] mt-[9px] mb-[43px] border-[1px] h-[213px] rounded-[5px] block" />
            </label>

            <button className="active:opacity-30 bg-[#D09600] text-[#fff] rounded-[8px] px-[30px] py-[15px]">
              Send
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AboutUs
