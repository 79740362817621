import { motion } from "framer-motion"
import React, { useEffect, useRef, useState } from "react"
import OtpInput from "react-otp-input"
import { Modal } from "../../components"
import { PaystackButton } from "react-paystack"
import { IUser } from "../business-dashboard/loan"
import { useSelector } from "react-redux"
import axios from "axios"
import {
  errorToastMessage,
  successToastMessage
} from "../../utils/toast-message"
import Loader from "../../components/Loader"
import Select from "react-select"

interface IBank {
  active: boolean
  code: number
  country: string
  id: number
  name: string
  slug: string
}
interface IWallet {
  balance: number
}

interface IAccounts {
  nuban: string
  accountName: string
  bank: string
  bvnCustomerConsent: boolean
  isActive: boolean
}
interface ITransaction {
  type: string
  reference: string
  sender: any
  recipient: any
  metadata: string
}

interface SendModalProps {
  banks: IBank[]
  modalType: string
  currUser: IUser
  setShowModal: (show: any) => void
  setModalType: (show: any) => void
  amount: string
  setAmount: (amt: any) => void
  fundWallet: (ref?: any) => void
  wallet: any
  consent: any
  loading: boolean
  accounts: any
  reOrderedBanks: { value: number; label: string }[]
}
const SendMoneyModal = ({
  reOrderedBanks,
  wallet,
  modalType,
  setModalType,
  setShowModal,
  amount,
  currUser,
  setAmount,
  fundWallet,
  loading,
  banks,
  consent,
  accounts
}: SendModalProps) => {
  const [pin, setPin] = useState("")
  const [selectedBank, setSelectedBank] = useState<IBank>()
  const [account_number, setAccountNumber] = useState("")
  const [account_name, setAccountName] = useState("")
  const [verifyingAccount, setVerifyingAccount] = useState(false)
  const [isTransferring, setIsTransferring] = useState(false)
  const [selected] = useState()
  const [currTab, setCurrTab] = useState("to-bank")
  const [toWalletModalType, setToWalletModalType] = useState("transfer")
  const [transfer_amount, setTransferAmount] = useState("")
  const [selectedUser, setSelectedUser] = useState<any>()
  const [users, setUsers] = useState<any>()
  const [reference, setReference] = useState("")
  const [toKyc, setToKyc] = useState("")
  const [withdrawing, setWithdrawing] = useState(false)
  const [recipientDetails, setRecipientDetails] = useState({
    clientId: "",
    accountId: ""
  })
  const [verifyBVN, setVerifyBVN] = useState(false)
  const [consentGiven, setConsentGiven] = useState(false)
  const [generateAccountLoading, setGenerateAccountLoading] = useState(false)
  const [bvnUrl, setBvnUrl] = useState("https://services.vfdtech.ng/")
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [click, setClick] = useState(false)
  const getUsers = async () => {
    try {
      const resp = await axios.get("users/all")
      const data = []
      for (const user of resp.data.users) {
        data.push({
          label: user.name,
          value: user.id
        })
      }

      setUsers(data)

      // console.log(resp.data.users);
    } catch (error) {
      console.log(error)
    }
  }

  const makeTransfer = async () => {
    console.log(
      amount,
      account_name,
      account_number,
      recipientDetails,
      selectedBank
    )
    if (
      amount &&
      account_name &&
      account_number &&
      // recipientDetails.clientId &&
      // recipientDetails.accountId &&
      selectedBank
    ) {
      setWithdrawing(true)

      try {
        const response = await axios.post("wallet/withdraw-to-bank", {
          amount,
          accountName: account_name,
          accountId: recipientDetails.accountId,
          accountNumber: account_number,
          clientId: recipientDetails.clientId,
          bankCode: selectedBank!.code,
          toKyc
        })

        console.log(response.data, "RESPONSE TRANSFER")
        setModalType("processing")

        setWithdrawing(false)
      } catch (error) {
        setWithdrawing(false)
        console.log(error)
        errorToastMessage(error)
      }
    } else {
      console.log("something wrong")
    }
  }

  const verifyAccount = async (account_number: string) => {
    if (selectedBank) {
      setVerifyingAccount(true)
      try {
        const response = await axios.get(
          `wallet/bank-acct-details?accountNo=${account_number}&bank=${selectedBank?.code}`
        )

        console.log(response.data.bank)

        setAccountName(response.data.bank.name ?? response.data.bank.data.name)
        setToKyc(response.data.bank.status)
        const recipient = response.data.bank
        setRecipientDetails({
          clientId: response.data.bank?.clientId ?? "",
          accountId: recipient.account?.id ?? ""
        })
        console.log(response.data.bank)
        setVerifyingAccount(false)
      } catch (error) {
        console.log(error)
        setVerifyingAccount(false)
        setAccountName("Invalid Details")
      }
    }
  }

  const transferToWallet = async () => {
    setIsTransferring(true)
    if (currUser?.currentUser?.account_type === "admin") {
      try {
        const res = await axios.post("wallet/transfer", {
          reason: reference,
          recipient: selectedUser.value,
          amount: transfer_amount
        })

        setIsTransferring(false)
        // console.log(res.data);
        successToastMessage(res.data.message)
        setShowModal(false)
      } catch (error) {
        console.log(error)
        errorToastMessage(error)
        setIsTransferring(false)
      }
    } else {
      try {
        const res = await axios.post("user-loans/wallet/repay", {
          amount: transfer_amount
        })

        setIsTransferring(false)
        // console.log(res.data);
        successToastMessage(res.data.message)
        setShowModal(false)
      } catch (error) {
        console.log(error)
        errorToastMessage(error)
        setIsTransferring(false)
      }
    }
  }

  const generateAccount = async () => {
    try {
      setGenerateAccountLoading(true)
      const accountRespose = await axios.post("/wallet/generate-account")

      successToastMessage(accountRespose.data.message)
      setGenerateAccountLoading(false)
      window.location.reload()
      return true
    } catch (error: any) {
      // console.log(error);
      console.log("error2", error.response.data.errors[0].message)
      // localStorage.removeItem("@user")
      // localStorage.removeItem("@token")
    }
  }
  useEffect(() => {
    const controller = new AbortController()

    getUsers()

    return () => {
      controller.abort()
    }
  }, [])

  const componentProps = {
    email: currUser?.currentUser?.email,
    amount: parseFloat(amount) * 100,
    publicKey: "pk_test_a7740b1c8f7778b555c851523e487a56dd91ee5d",
    text: `${loading ? "Please wait..." : "Fund Wallet"}`,
    onSuccess: (ref: any) => {
      console.log(ref, "REF")
      fundWallet(ref)
    },
    onerror: (ref: any) => {
      console.log(ref, "REF")
    },
    onClose: (ref: any) => {
      console.log(ref, "REF")
    }
  }

  const handleChange = (selectedOption: any) => {
    // console.log(selectedOption);
    const bank = banks.find((b) => b.code === selectedOption.value)
    setSelectedBank(bank)
  }

  const handleClick: React.MouseEventHandler<HTMLParagraphElement> = (
    event
  ) => {
    // Handle the click event here
    setClick(!click)
  }

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="rounded-[8.43px] md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <div className="flex w-[90%] items-center justify-between mb-[40px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "send-money"
            ? "Send Money"
            : modalType === "breakdown"
            ? "Payment breakdown"
            : modalType === "enter-pin"
            ? "Your pin"
            : modalType === "enter-password"
            ? "Password"
            : modalType === "new-pin"
            ? "New Pin"
            : modalType === "fund-wallet"
            ? "Fund Wallet"
            : ""}
        </h4>
        {!modalType.includes("pin") ? (
          <button
            disabled={loading}
            onClick={() => setShowModal(false)}
            className="active:scale-75 transform-gpu"
          >
            <img alt="close" src="/images/close-circle.svg" />
          </button>
        ) : (
          <div />
        )}
      </div>

      {modalType !== "fund-wallet" && (
        <div className="flex items-center mb-[40px]">
          <button
            onClick={() => setCurrTab("to-bank")}
            className={`py-[10px] flex-1 ${
              currTab === "to-bank" ? "border-b-[1px] border-b-[#888]" : ""
            }`}
          >
            To Bank
          </button>
          <button
            onClick={() => setCurrTab("to-wallet")}
            className={`py-[10px] flex-1 ${
              currTab === "to-wallet" ? "border-b-[1px] border-b-[#888]" : ""
            }`}
          >
            {currUser?.currentUser?.account_type === "admin"
              ? "To User"
              : "Repay Loan"}
          </button>
        </div>
      )}
      {modalType === "send-money" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <label className="sm:text-[20px] text-[14px] text-[#333] sm:mb-[32px] mb-[20px]">
            Choose a recipient
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: state.isFocused ? "#292D32" : "#292D32",
                  borderRadius: "8.43px",
                  paddingLeft: "10px",
                  paddingRight: "10px"
                })
              }}
              value={selected}
              onChange={handleChange}
              options={reOrderedBanks}
            />
          </label>
          <input
            type={"number"}
            value={account_number}
            onChange={(e) => {
              setAccountNumber(e.target.value)
              if (e.target.value.length === 10) {
                verifyAccount(e.target.value)
              }
            }}
            placeholder="Enter Destination Account"
            maxLength={10}
            className=" outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
          />
          <div className="flex justify-end px-[30px]">
            {verifyingAccount && <span className="account-loading"></span>}
            {!verifyingAccount && account_name.length > 2 && (
              <p className="sm:text-[16px] text-[12px] mr-[-15px]">
                {" "}
                {account_name}
              </p>
            )}
          </div>

          <div className="sm:h-[20px] h-[15px]" />
          <input
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            type={"number"}
            placeholder="Enter Amount"
            maxLength={10}
            className="sm:mb-[32px] mb-[20px] outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
          />
          <input
            type={"text"}
            placeholder="Enter Narration"
            className="outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
          />

          <button
            disabled={!selectedBank || !account_name || !amount}
            onClick={() => setModalType("breakdown")}
            className={`${
              !selectedBank || !account_name || !amount ? "opacity-20" : ""
            } mt-[53px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px]`}
          >
            Confirm
          </button>
        </div>
      ) : modalType === "fund-wallet" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <p>Make transfer to your this account: </p>
          {/* 
          {click && (
            <div>
              <Modal>
                <div className="bg-[#fff] w-[95%] h-[95%] relative">
                  <button
                    onClick={() => {
                      setVerifyBVN(false)
                      setConsentGiven(true)
                    }}
                    className="active:scale-75 transform-gpu absolute right-[10px] top-[10px]"
                  >
                    <img
                      alt="close"
                      src="/images/close-circle.svg"
                      className="sm:h-[21.5px] sm:w-[21.5px] h-[15px] w-[15px]"
                    />
                  </button>

                  <iframe
                    ref={iframeRef}
                    title="Otp constent"
                    src={consent?.url}
                    width="90%"
                    height="90%"
                  ></iframe>
                </div>
              </Modal>
            </div>
          )} */}

          <div className="mt-[15px] border-[1px] p-[10px] border-[#D09600] border-dashed rounded-[10px] flex flex-col items-center text-center">
            {accounts &&
              accounts.length > 0 &&
              accounts.map((a: IAccounts) => (
                <React.Fragment>
                  {a.isActive ? (
                    <>
                      <p className="font-[600]">{a.nuban}</p>
                      <p>{a?.accountName}</p>
                      <p>{a?.bank} Bank</p>{" "}
                    </>
                  ) : null}
                  <>
                    <br />
                    <br />
                    {generateAccountLoading ? (
                      <b>Generating.....</b>
                    ) : (
                      !accounts.some(
                        (item: IAccounts) => item.bank === "PROVIDUS"
                      ) && (
                        <b
                          style={{ cursor: "pointer" }}
                          onClick={generateAccount}
                        >
                          Click here to Generate Providus account
                        </b>
                      )
                    )}
                  </>
                </React.Fragment>
              ))}

            {/* <p className="font-[600]">
              {consent?.user?.consent_given === false
                ? "*******"
                : wallet?.accountNo}
              {consent?.user?.consent_given === false && (
                <p>
                  <a href={consent?.url} target="_blank" rel="noreferrer">
                    {" "}
                    Kindly click Here to grant BVN Consent
                  </a>
                </p>
              )}
            </p>
            <p>
              {consent?.user?.consent_given === false
                ? null
                : currUser.currentUser.name}
            </p>
            <p>VFD</p> */}
          </div>
          {/* <label className="sm:text-[20px] text-[14px] text-[#333] sm:mb-[32px] mb-[20px] pt-[30px] text-center">
            OR
          </label>
          <label className="sm:text-[20px] text-[14px] text-[#333] sm:mb-[32px] mb-[20px] pt-[30px]">
            Enter Amount
          </label> */}
          {/* <input
            type={"number"}
            onChange={(e) => setAmount(e.target.value)}
            placeholder="Enter Amount"
            maxLength={10}
            className="sm:mb-[32px] mb-[20px] outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
          /> */}

          {/* <PaystackButton
            {...componentProps}
            className="mt-[53px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px]"
          /> */}
        </div>
      ) : modalType === "breakdown" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col  items-center">
          <div className="w-[320px]">
            <div className="mb-[23px] sm:text-[16px] text-[13px] text-[#292D32] flex justify-between items-center">
              <span>You send: </span> <span>&#8358;{amount}</span>
            </div>
            <div className="mb-[23px] sm:text-[16px] text-[13px] text-[#292D32] flex justify-between items-center">
              <span>Recipent gets: </span>{" "}
              <span>&#8358;{parseFloat(amount) - 100}</span>
            </div>
            <div className="mb-[23px] sm:text-[16px] text-[13px] text-[#292D32] flex justify-between items-center">
              <span>Fee: </span> <span>&#8358;100</span>
            </div>
          </div>

          <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px] text-center mb-[24px]">
            Recipent Bank Information
          </h4>

          <div className="w-[320px]">
            <div className="mb-[23px] sm:text-[16px] text-[13px] text-[#292D32] flex justify-between items-center">
              <span>Bank: </span> <span>{selectedBank?.name}</span>
            </div>
            <div className="mb-[23px] sm:text-[14px] text-[11px] text-[#292D32] flex justify-between items-center">
              <span>Recipent name: </span> <span>{account_name}</span>
            </div>
            <div className="mb-[23px] sm:text-[16px] text-[13px] text-[#292D32] flex justify-between items-center">
              <span>Account number: </span> <span>{account_number}</span>
            </div>
          </div>

          <div className="flex items-center mt-[44px]">
            <button
              onClick={() => setModalType("send-money")}
              className="rounded-[8.43px] self-center active:bg-[#f4f4f4d2] bg-[#FFF] text-[#292D32] sm:text-[20px] text-[14px] font-[400] sm:px-[93px] px-[25px] sm:h-[62px] h-[45px] border-[1px] border-[#292D32]"
            >
              Back
            </button>
            <button
              disabled={withdrawing}
              onClick={() => makeTransfer()}
              className="flex items-center justify-center rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] ml-[37px] text-[#fff] sm:text-[20px] text-[14px] font-[400] sm:px-[93px] px-[25px] sm:h-[62px] h-[45px]"
            >
              {withdrawing ? (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#F6EACC]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                "Confirm"
              )}
            </button>
          </div>
        </div>
      ) : modalType === "enter-pin" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col  items-center">
          <h5 className="mb-[55px] mt-[-55px] sm:text-[20px] text-[14px] font-[400] text-[#000000C7]">
            Kindly put in your monzone secret pin number
          </h5>
          <div className="sm:w-[100%] w-[80%] h-[66.3px] rounded-[5px]">
            <OtpInput
              containerStyle={{
                width: "100%",
                height: "66.3px",
                justifyContent: "space-between"
              }}
              value={pin}
              onChange={(text) => {
                setPin(text)
                console.log(text, pin)
                if (text.length === 4) {
                  setModalType("processing")
                }
              }}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  maxLength={1}
                  className="outline-none sm:w-[65px] sm:h-[100%] w-[50px] h-[50px]"
                  placeholder=""
                  style={{
                    borderRadius: "10px",
                    paddingTop: "10px",
                    // height: '100%',
                    borderWidth: "0.5px",
                    borderColor: "#000",
                    textAlign: "center"
                    // width: '65px'
                  }}
                />
              )}
            />
          </div>
          <span className="mt-[26px] sm:text-[16.37px] text-[12px] font-[600] text-[#000000C7]">
            you don't have a monzone secret pin number?{" "}
            <button
              onClick={() => setModalType("enter-password")}
              className="text-[#D09600] underline"
            >
              click here to start
            </button>
          </span>
        </div>
      ) : modalType === "enter-password" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col  items-center">
          <h5 className="mb-[55px] mt-[-55px] sm:text-[20px] text-[14px] font-[400] text-center text-[#000000C7]">
            Kindly put in your password to continue
          </h5>

          <label className="relative w-[100%] sm:text-[16px] text-[14px]">
            Password
            <input
              type={"password"}
              placeholder="Enter your password"
              className="mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
            <button className="absolute right-[3%] sm:top-[40%] top-[43%]">
              <img
                alt="show"
                src="/images/eye-icon.svg"
                className="sm:h-[24px] sm:w-[24px] h-[16px] w-[16px]"
              />
            </button>
          </label>

          <button
            onClick={() => setModalType("new-pin")}
            className="mt-[33px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px]"
          >
            Confirm
          </button>
        </div>
      ) : modalType === "processing" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <img
            alt="processing"
            src="/images/processing-icon.svg"
            className="mb-[50px]"
          />
          <h5 className="text-center font-[600] sm:text-[24px] text-[16px] mb-[15px]">
            Transfer Successful
          </h5>
          <p className="text-center sm:text-[20px] text-[13px] mb-[60px]">
            Press close to continue
          </p>
          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] sm:ml-[37px] text-[#fff] sm:text-[20px] text-[14px] font-[400] px-[76px] py-[15px]"
          >
            Close
          </button>
        </div>
      ) : modalType === "new-pin" && currTab === "to-bank" ? (
        <div className="px-[5%] w-[100%] flex flex-col  items-center">
          <h5 className="mb-[55px] mt-[-55px] sm:text-[20px] text-[14px] font-[400] text-[#000000C7] text-center">
            Kindly put in your monzone secret pin number
          </h5>
          <div className="sm:w-[100%] w-[100%] h-[66.3px] rounded-[5px]">
            <OtpInput
              containerStyle={{
                width: "100%",
                height: "66.3px",
                justifyContent: "space-between"
              }}
              value={pin}
              onChange={(text) => {
                setPin(text)
                console.log(text, pin)
                if (text.length === 4) {
                  setModalType("processing")
                }
              }}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              renderInput={(props) => (
                <input
                  {...props}
                  maxLength={1}
                  className="outline-none sm:w-[65px] sm:h-[100%] w-[50px] h-[50px]"
                  placeholder=""
                  style={{
                    borderRadius: "10px",
                    paddingTop: "10px",
                    // height: '100%',
                    borderWidth: "0.5px",
                    borderColor: "#000",
                    textAlign: "center"
                    // width: '65px'
                  }}
                />
              )}
            />
          </div>
          <button
            onClick={() => setModalType("processing")}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] mt-[43px] text-[#fff] sm:text-[20px] text-[14px] font-[400] px-[93px] sm:h-[62px] h-[45px]"
          >
            Confirm
          </button>
        </div>
      ) : toWalletModalType === "transfer" && currTab === "to-wallet" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <label className="sm:text-[20px] text-[14px] text-[#333] ">
            Enter Amount
          </label>
          <input
            value={transfer_amount}
            type={"number"}
            onChange={(e) => setTransferAmount(e.target.value)}
            placeholder="Enter Amount"
            maxLength={10}
            className="sm:mb-[32px] mb-[20px] outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
          />

          {currUser?.currentUser?.account_type === "admin" && (
            <label className="sm:text-[20px] text-[14px] text-[#333] sm:mb-[32px] mb-[20px]">
              Choose a recipient
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "#292D32" : "#292D32",
                    borderRadius: "8.43px",
                    paddingLeft: "10px",
                    paddingRight: "10px"
                  })
                }}
                value={selected}
                onChange={setSelectedUser}
                options={users}
              />
            </label>
          )}

          {currUser?.currentUser?.account_type === "admin" && (
            <>
              <label className="sm:text-[20px] text-[14px] text-[#333] ">
                Reference
              </label>
              <input
                value={reference}
                type={"text"}
                onChange={(e) => setReference(e.target.value)}
                placeholder="Enter Reference"
                className="sm:mb-[32px] mb-[20px] outline-none mt-[9px] sm:h-[50px] h-[40px] w-[100%] rounded-[8.43px] px-[26px] border-[0.5px] border-[#292D32] sm:text-[16px] text-[14px]"
              />
            </>
          )}
          <button
            disabled={
              isTransferring ||
              (currUser?.currentUser?.account_type === "admin" &&
                !selectedUser) ||
              (currUser?.currentUser?.account_type === "admin" && !reference) ||
              !transfer_amount
            }
            onClick={transferToWallet}
            className={`${
              isTransferring ||
              (currUser?.currentUser?.account_type === "admin" &&
                !selectedUser) ||
              (currUser?.currentUser?.account_type === "admin" && !reference) ||
              !transfer_amount
                ? "opacity-30"
                : ""
            } mt-[53px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px]`}
          >
            {isTransferring ? (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#F6EACC]"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : currUser?.currentUser?.account_type === "admin" ? (
              "Transfer"
            ) : (
              "Repay Loan"
            )}
          </button>
        </div>
      ) : (
        <div />
      )}
    </motion.div>
  )
}

function Wallet() {
  const currUser: IUser = useSelector((state: any) => state.user.user)

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")
  const [amount, setAmount] = useState("")
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [loading, setLoading] = useState(false)
  const [wallet, setWallet] = useState<IWallet>()
  const [transactions, setTransactions] = useState<ITransaction[]>([])
  const [consent, setConsent] = useState<any>()
  const [banks, setBanks] = useState<IBank[]>([])
  const [showBalance, setShowBalance] = useState(true)
  const [reOrderedBanks, setReOrderedBanks] = useState<
    { value: number; label: string }[]
  >([])

  const [threshold, setThreshold] = useState("100")
  const [totalWalletBal, setTotalWalletBal] = useState(0)
  const [thresLoading, setThresLoading] = useState(false)

  const [ledgerBal, setLedgerBal] = useState(0)
  const [accounts, setAccount] = useState<IAccounts>()

  const getLedgerBal = async () => {
    try {
      const tempWalletResp = await axios.get("wallet/temp-wallets")

      console.log(tempWalletResp.data)
      setLedgerBal(tempWalletResp.data.amount)
    } catch (error: any) {
      // console.log(error);
      console.log("error2", error.response.data.errors[0].message)
      // localStorage.removeItem("@user")
      // localStorage.removeItem("@token")
    }
  }
  const getWallet = async () => {
    setLoading(true)
    try {
      const response = await axios.get("wallet")

      setWallet(response.data.userWallet)
      setConsent(response.data.consentResponse)
      setAccount(response.data.accountNumbers)

      setTransactions(response.data.transactions)
      console.log(response.data.transactions)

      setLoading(false)
    } catch (error: any) {
      console.log("error3", error.response.data.errors[0].message)
      // localStorage.removeItem("@user")
      // localStorage.removeItem("@token")
      setLoading(false)
    }
  }

  console.log(consent)

  const getAllUsersWalletBalance = async () => {
    setThresLoading(true)

    try {
      const res = await axios.get(
        "admin/wallet-balances?threshold=" + threshold
      )
      // console.log(res)
      setThresLoading(false)
      setTotalWalletBal(res.data.totalAmount)
    } catch (error: any) {
      console.log(error.response.data.errors[0].message)

      setThresLoading(false)
    }
  }

  const fundWallet = async (reference: any) => {
    setPaymentLoading(true)

    try {
      const res = await axios.post("wallet/fund", {
        amount,
        reference
      })

      successToastMessage(res.data.message)
      getWallet()
      setShowModal(false)
      setPaymentLoading(false)
    } catch (error: any) {
      console.log(error)
      errorToastMessage(error)
      setPaymentLoading(false)
    }
  }

  const getAllBanks = async () => {
    console.log("TOUCHED")
    try {
      const response = await axios.get("wallet/banks")

      setBanks(response.data.banks)

      const reOrderedBanks = []

      for (const bank of response.data.banks) {
        let reOrderedBank = {
          value: bank.code,
          label: bank.name
        }

        reOrderedBanks.push(reOrderedBank)
      }

      setReOrderedBanks(reOrderedBanks)
    } catch (error) {
      console.log(error, "ERROR")
    }
  }

  const reOrderBank = () => {}

  useEffect(() => {
    const controller = new AbortController()

    const getWalletD = async () => {
      setLoading(true)
      await getLedgerBal()
      await getWallet()
      setLoading(false)
    }

    getWalletD()
    getAllBanks()
    reOrderBank()
    getAllUsersWalletBalance()

    return () => {
      controller.abort()
    }
  }, [])

  if (loading) {
    return <Loader />
  }
  console.log(transactions)
  return (
    <div className="h-[100%] w-[100%] sm:p-[55px] max-[620px]:pt-[100px] p-[30px]">
      <h5 className="font-[500] sm:text-[24px] text-[16px]">Overview</h5>

      <div className="mt-[11px] sm:w-[100%] max-[620px]:max-w-[calc(100vw-60px)] bg-[#fff] sm:py-[42px] sm:px-[40px] rounded-[8px] flex sm:flex-row flex-col items-center">
        <div className="sm:flex-1 justify-between text-[#fff] bg-[#D09600] sm:h-[212px] h-[144px] rounded-[19.2px] flex items-center px-[50px] max-[620px]:w-[100%]">
          <div>
            <span className="mb-[4px] font-[600] sm:text-[24px] text-[16px] sm:leading-[30px] leading-[20px]">
              Current Balance
            </span>
            {showBalance ? (
              <h3 className="font-[700] sm:text-[48px] text-[24px] sm:leading-[55px] leading-[30px]">
                &#8358;{wallet?.balance.toLocaleString()}
              </h3>
            ) : (
              <h3 className="font-[700] sm:text-[48px] text-[24px] sm:leading-[55px] leading-[30px]">
                ******
              </h3>
            )}
            {currUser?.currentUser?.account_type === "admin" && (
              <div className="mt-[15px]">
                <span className="mb-[4px] font-[600] sm:text-[14px] text-[12px] sm:leading-[20px] leading-[20px]">
                  Ledger Balance
                </span>
                <h3 className="font-[600] sm:text-[28px] text-[18px] sm:leading-[35px] leading-[20px]">
                  &#8358;{ledgerBal.toLocaleString()}
                </h3>
              </div>
            )}
          </div>

          <button
            onClick={() => setShowBalance(!showBalance)}
            className="relative"
          >
            <img
              src="/images/eye-icon-white.svg"
              alt="hide"
              className="w-[26.88px] h-[26.88px]"
            />

            {!showBalance && (
              <span className="absolute top-[-28%] opacity-[0.63] font-[700] text-[30px] left-[32%] ">
                /
              </span>
            )}
          </button>
        </div>

        <div className="sm:ml-[45px] max-[620px]:mt-[26px] flex sm:flex-col flex-row justify-between sm:h-[212px] max-[620px]:w-[100%]">
          <button
            onClick={() => {
              setShowModal(true)
              setModalType("send-money")
            }}
            className="sm:w-[311px] max-[620px]:flex-1 justify-center flex items-center sm:h-[66px] h-[42px] rounded-[8.43px] sm:border-[2px] border-[1px] border-[#0000004F] text-[#0000009C] text-[20px] "
          >
            <img
              alt="icon"
              src="/images/send-circle.svg"
              className="sm:w-[32.56px] sm:h-[32.56px] w-[20px] h-[20px]"
            />
            <span className="sm:ml-[30px] ml-[6px] sm:text-[16px] text-[14px]">
              Send
            </span>
          </button>
          <button
            onClick={() => {
              setShowModal(true)
              setModalType("fund-wallet")
            }}
            className="active:opacity-30 sm:mt-[auto] sm:w-[311px] max-[620px]:ml-[25px] max-[620px]:flex-1 justify-center flex items-center sm:h-[66px] h-[42px] rounded-[8.43px] sm:border-[2px] border-[1px] border-[#0000004F] text-[#0000009C] text-[20px] "
          >
            <img
              alt="icon"
              src="/images/topup.svg"
              className="sm:w-[32.56px] sm:h-[32.56px] w-[20px] h-[20px]"
            />
            <span className="sm:ml-[30px] ml-[6px] sm:text-[16px] text-[14px]">
              Top up
            </span>
          </button>
        </div>
      </div>

      {currUser?.currentUser?.account_type === "admin" && (
        <div className="mt-[11px] sm:w-[100%] max-[620px]:max-w-[calc(100vw-60px)] bg-[#fff] sm:py-[42px] sm:px-[40px] rounded-[8px] flex sm:flex-row flex-col items-center">
          <div className="sm:flex-1 justify-between text-[#fff] bg-[#D09600] sm:h-[212px] h-[144px] rounded-[19.2px] flex items-center px-[50px] max-[620px]:w-[100%]">
            <div>
              <span className="mb-[4px] font-[600] sm:text-[24px] text-[16px] sm:leading-[30px] leading-[20px]">
                Total Wallet Balance
              </span>
              {thresLoading ? (
                <svg
                  aria-hidden="true"
                  className="w-[50px] h-[50px] mr-2  animate-spin text-gray-600 fill-[#F6EACC]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#D09600"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              ) : (
                <h3 className="font-[700] sm:text-[48px] text-[24px] sm:leading-[55px] leading-[30px]">
                  &#8358;{totalWalletBal.toLocaleString()}
                </h3>
              )}
            </div>

            <div>
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  getAllUsersWalletBalance()
                }}
              >
                <p> Adjust Threshold</p>
                <input
                  type={"number"}
                  value={threshold}
                  onChange={(e) => {
                    setThreshold(e.target.value)
                  }}
                  placeholder="Enter Destination Account"
                  max={100}
                  maxLength={3}
                  className="text-gray-700 outline-none mt-[9px] sm:h-[50px] h-[40px] w-[40%] rounded-[8.43px] px-[16px] border-[0.3px] border-gray-200 sm:text-[16px] text-[14px]"
                />{" "}
                %
              </form>
            </div>
          </div>
        </div>
      )}

      <div className="mt-[47px] justify-between flex items-center max-[620px]:max-w-[calc(100vw-60px)]">
        <h4 className="sm:text-[24px] text-[16px] font-[500]">
          Transaction history
        </h4>

        <div className="flex items-center">
          <span className="text-[#000000A1] font-[600] sm:text-[16px] text-[14px]">
            Show
          </span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#D9D9D900] sm:text-[14px] text-[12px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block w-[88.14px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600] sm:text-[16px] text-[14px]">
            List
          </span>
        </div>
      </div>
      <div className=" overflow-x-auto mt-[12px] rounded-t-[10px] pb-[48px] max-[620px]:max-w-[calc(100vw-62px)]">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Transaction Code
              </th>
              <th scope="col" className="px-6 py-3">
                Recipient
              </th>
              <th scope="col" className="px-6 py-3">
                Sender
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Transaction Type
              </th>
              <th scope="col" className="px-6 py-3">
                Transaction Date
              </th>
            </tr>
          </thead>
          {transactions.length ? (
            <tbody>
              {transactions.map((transaction, index) => (
                <tr
                  className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                  key={index}
                >
                  <td className="px-6 py-4">
                    {transaction?.reference}
                    {/* {JSON.parse(transaction.metadata)} */}
                  </td>
                  <td className="px-6 py-4">
                    {transaction.recipient?.name ??
                      (transaction.type === "debit" &&
                        JSON.parse(transaction?.metadata)
                          ?.originator_account_name)}
                  </td>

                  <td className="px-6 py-4">
                    {transaction.sender?.name ??
                      (transaction.type === "credit" &&
                        JSON.parse(transaction?.metadata)
                          ?.originator_account_name)}
                  </td>

                  <td className="px-6 py-4">
                    &#8358;
                    {transaction
                      ? (
                          JSON.parse(transaction?.metadata)?.amount ??
                          JSON.parse(transaction?.metadata)?.data?.amount / 100
                        ).toLocaleString()
                      : ""}
                  </td>

                  <td className="px-6 py-4 capitalize">{transaction.type}</td>
                  <td className="px-6 py-4 capitalize">
                    {transaction
                      ? JSON.parse(
                          transaction?.metadata
                        )?.data?.createdAt?.split("T")[0] ??
                        JSON.parse(transaction?.metadata)?.timestamp?.split(
                          "T"
                        )[0]
                      : ""}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr className="text-gray-700  text-[16px]">No Data</tr>
            </tbody>
          )}
        </table>
        {/* <div className="flex items-center justify-between mt-[28px]">
          <span className="sm:text-[16px] text-[12px] text-[#000000A1]">
            Showing 1-30 of list
          </span>

          <div className="flex items-center ">
            <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
              <img
                src="/images/caret-back.svg"
                alt="icon"
                className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
              />
            </button>
            {[1, 2, 3, 4].map((val, index) => (
              <button
                key={index}
                className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] sm:text-[14px] text-[11px]"
              >
                {val}
              </button>
            ))}

            <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
              <img
                src="/images/caret-right.svg"
                alt="icon"
                className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
              />
            </button>
          </div>
        </div> */}
      </div>

      {showModal && (
        <Modal>
          <SendMoneyModal
            wallet={wallet}
            reOrderedBanks={reOrderedBanks}
            fundWallet={fundWallet}
            setModalType={setModalType}
            setShowModal={setShowModal}
            modalType={modalType}
            setAmount={setAmount}
            amount={amount}
            currUser={currUser}
            banks={banks}
            loading={paymentLoading}
            consent={consent}
            accounts={accounts}
          />
        </Modal>
      )}
    </div>
  )
}

export default Wallet
