import { motion } from "framer-motion";
import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "../../components";

const USERS = [
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 700000,
    interest: "15%",
    phone_number: "08045842452",
    status: "pending",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "accepted",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "accepted",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "accepted",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "pending",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "pending",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "pending",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "pending",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "accepted",
  },
  {
    name: "Emeka Williams",
    date: "03-05-2023",
    amount: 7000000,
    interest: "15%",
    phone_number: "08045842452",
    status: "accepted",
  },
];
function BuyIn() {
  const [selectedRequest, setSelectedRequest] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [amount, setAmount] = useState("100000");

  const DetailsModal = () => {
    const [startDate, setStartDate] = useState<any>(new Date());
    const [amount, setAmount] = useState("100000");
    const [interest, setInterest] = useState("1.5");

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className={`overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px] ${modalType === "view-user-details" || modalType === "add-user"
            ? "h-[100%] w-[50%] self-end"
            : ""
          }`}
      >
        <div className="flex w-[100%] items-center justify-between mb-[60px]">
          <div />
          <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
            {modalType === "buy-in"
              ? "Buy In"
              : modalType === "view-loan-details"
                ? "Loan details"
                : modalType === "invested"
                  ? ""
                  : ""}
          </h4>
          {modalType !== "invested" && (
            <button
              onClick={() => setShowModal(false)}
              className="active:scale-75 transform-gpu"
            >
              <img alt="close" src="/images/close-circle.svg" />
            </button>
          )}
        </div>

        {modalType === "buy-in" ? (
          <div className="px-[5%] w-[100%] flex flex-col">
            <label className="sm:text-[20px] text-[14px]">
              Amount (&#8358;)
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type={"number"}
                className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[15px] border-[1px] border-[#292D32]"
              />
            </label>

            <label className="sm:text-[20px] text-[14px]">
              Due date
              <button className="text-left mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[15px] border-[1px] border-[#292D32]">
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="outline-none cursor-pointer w-[100%]"
                />
              </button>
            </label>

            <label className="sm:text-[20px] text-[14px]">
              Interest rate (%)
              <input
                value={interest}
                onChange={(e) => setInterest(e.target.value)}
                type={"number"}
                className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[15px] border-[1px] border-[#292D32]"
              />
            </label>

            <div className="flex flex-col items-center mt-[42px]">
              <button
                onClick={() => {
                  setShowMenu(false);
                  setShowModal(false);
                }}
                className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
              >
                Invest
              </button>
            </div>
          </div>
        ) : (
          modalType === "view-loan-details" && (
            <div className="px-[5%] w-[100%] flex flex-col  items-center">
              <div className="w-[380px]">
                <div className="mb-[23px] sm:text-[16px] text-[14px] text-[#292D32] flex justify-between items-center">
                  <span>Sender name: </span> <span>Emeka William</span>
                </div>
                <div className="mb-[23px] sm:text-[16px] text-[14px] text-[#292D32] flex justify-between items-center">
                  <span>Amount: </span> <span>&#8358;10,000</span>
                </div>
                <div className="mb-[23px] sm:text-[16px] text-[14px] text-[#292D32] flex justify-between items-center">
                  <span>Date: </span> <span>01-02-2023</span>
                </div>
                <div className="mb-[23px] sm:text-[16px] text-[14px] text-[#292D32] flex justify-between items-center">
                  <span>Due date: </span> <span>04-04-2023</span>
                </div>
                <div className="mb-[23px] sm:text-[16px] text-[14px] text-[#292D32] flex justify-between items-center">
                  <span>Interest rate: </span> <span>13%</span>
                </div>
              </div>

              <div className="flex items-center mt-[44px]">
                <button
                  onClick={() => setShowModal(false)}
                  className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] px-[93px] sm:h-[62px] h-[43px]"
                >
                  Invest
                </button>
              </div>
            </div>
          )
        )}
      </motion.div>
    );
  };

  return (
    <div className="h-[100%] w-[100%] sm:pt-[55px] sm:pb-[55px] sm:px-[55px] pt-[95px] px-[31px] bg-[#F9F9F9]">
      <div className="flex items-center justify-between mb-[43px]">
        <h5 className="font-[500] sm:text-[24px] text-[18px]">
          Buy-in Requests
        </h5>

        <div className="ml-[65px] flex-1 sm:flex hidden items-center bg-[#fff] rounded-[15px] px-[15px] text-[16px] h-[37px]">
          <img alt="search" src="/images/search-icon.svg" />
          <input
            className="outline-none px-[10px] h-[37px] w-[100%]"
            placeholder="Search"
          />
        </div>

        <div className="sm:flex hidden items-center ml-[200px]">
          <span className="text-[#000000A1] font-[600]">Show</span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#fff] text-[14px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block w-[88.14px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600]">List</span>
        </div>
      </div>

      <div className=" overflow-x-auto mt-[15px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)] ">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-4 py-3">
                Name
              </th>
              <th scope="col" className="px-4 py-3 min-w-[140px]">
                Date
              </th>
              <th scope="col" className="px-4 py-3">
                Amount
              </th>
              <th scope="col" className="px-4 py-3 min-w-[140px]">
                Date
              </th>

              <th scope="col" className="px-4 py-3">
                Interest rate
              </th>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {USERS.map((user, index) => (
              <tr
                className=" cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[500] "
                key={index}
              >
                <td className="px-5 py-4">{user.name}</td>

                <td className="px-5 py-4">{user.date}</td>

                <td className="px-5 py-4">{user.amount.toLocaleString()}</td>
                <td className="px-5 py-4">{user.date}</td>

                <td className="px-5 py-4">{user.interest}</td>
                <td className={`px-5 py-4 text-[#30B956]`}>
                  {user.status === "accepted" ? (
                    "Accepted"
                  ) : (
                    <div className="flex items-center">
                      <button className="active:scale-150">
                        <img alt="accept" src="/images/accept-icon.svg" />
                      </button>
                      <button className="ml-[17px] active:scale-150">
                        <img alt="accept" src="/images/reject-icon.svg" />
                      </button>
                    </div>
                  )}
                </td>

                <td className="px-5 py-4 relative">
                  <button
                    onClick={() => {
                      setShowMenu(!showMenu);
                      setSelectedRequest(index.toString());
                    }}
                    className="min-w-[20px] rounded-[5.38px] flex justify-center items-center "
                  >
                    <img
                      src="/images/more.svg"
                      className="min-w-[20px] min-h-[20px]"
                    />
                  </button>

                  {showMenu && selectedRequest === index.toString() && (
                    <div className="absolute z-[10] right-[20px] pt-[16px] pb-[8px] text-[#292D32]  rounded-[2px] sm:w-[241px] w-[180px]  bg-[#fff] drop-shadow-md">
                      <h2 className="px-[22px] sm:mb-[25px] mb-[15px] sm:text-[20px] text-[14px] font-[600]">
                        Loan options
                      </h2>

                      {[
                        {
                          label: "Buy In",
                          icon: "/images/edit-loan.svg",
                          onClick: () => {
                            setShowMenu(false);
                            setShowModal(true);
                            setModalType("buy-in");
                          },
                        },
                        {
                          label: "View details",
                          icon: "/images/eye-icon.svg",
                          onClick: () => {
                            setShowMenu(false);

                            setShowModal(true);
                            setModalType("view-loan-details");
                          },
                        },
                      ].map(({ label, icon, onClick }, index) => (
                        <button
                          onClick={onClick}
                          key={index}
                          className={`w-[100%] ${index < 3 ? "border-b-[0.5px]" : ""
                            } border-b-[#292D324D] active:bg-[#e8e8e8ba] py-[11.5px] px-[22px] flex items-center`}
                        >
                          <img
                            alt={label}
                            src={icon}
                            className="mr-[14px] min-w-[16px] min-h-[16px] h-[16px] w-[16px]"
                          />
                          {label}
                        </button>
                      ))}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
        </div>
      </div> */}

      {showModal && (
        <Modal>
          <DetailsModal />
        </Modal>
      )}
    </div>
  );
}

export default BuyIn;
