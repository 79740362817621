import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Modal } from "../../components";
import { ICredentials } from "../admin-dashboard/sme-requests";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toast-message";
import Loader from "../../components/Loader";
import axios from "axios";
import ImagePopUp from "../../components/image-popup";

interface DetailsModalProps {
  credentials: ICredentials;
  credentialsLoading: boolean;
  modalType: string;
  selectedUser: any;
  setShowModal: (d: any) => void;
  setShowMenu: (d: any) => void;
  setAccountType: (d: any) => void;
  account_type: string;
  addUser: () => void;
  setName: (n: any) => void;
  setPhoneNumber: (n: any) => void;
  setEmail: (n: any) => void;
  isAdding: boolean;
  name: string;
  phone_number: string;
  email: string;
}

const DetailsModal = ({
  isAdding,
  selectedUser,

  credentials,
  credentialsLoading,
  setAccountType,
  name,
  phone_number,
  email,
  setName,
  setPhoneNumber,
  setEmail,
  addUser,
  modalType,
  setShowModal,
  setShowMenu,
}: DetailsModalProps) => {
  // const [startDate, setStartDate] = useState<any>(new Date());
  const [showBvn, setShowBvn] = useState(false);
  const [showBankStatementPopup, setShowBankStatementPopup] = useState(false);
  const [showCacPopup, setShowCacPopup] = useState(false);
  const [showUtilityBillPopup, setShowUtilityBillPopup] = useState(false);
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className={`overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px] ${modalType === "view-user-details" || modalType === "add-user"
          ? "h-[100%] sm:w-[50%] w-[70%] self-end"
          : ""
        }`}
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        {modalType !== "view-user-details" && modalType !== "add-user" && (
          <div className="w-[10px]" />
        )}
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "edit-loan"
            ? "Edit loan request"
            : modalType === "share"
              ? "Share with partner"
              : modalType === "uploaded-doc"
                ? "Uploaded document"
                : modalType === "view-user-details"
                  ? "Customer details"
                  : modalType === "add-user"
                    ? "Add User"
                    : ""}
        </h4>
        {modalType !== "user-added" && (
          <button
            onClick={() => setShowModal(false)}
            className="active:scale-75 transform-gpu"
          >
            <img alt="close" src="/images/close-circle.svg" />
          </button>
        )}
      </div>

      {modalType === "uploaded-doc" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
            <span>
              {showBvn
                ? credentials?.bvn
                  ? credentials.bvn
                  : "No Data"
                : "BVN"}
            </span>

            <button
              onClick={() => {
                if (!credentialsLoading) {
                  setShowBvn(!showBvn);
                }
              }}
              className="active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
            >
              {credentialsLoading ? "Please wait" : "View"}
            </button>
          </div>
          <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
            <span>Certicate of incorporation</span>

            <button
              onClick={() => setShowCacPopup(true)}
              className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
            >
              {credentialsLoading
                ? "Please wait"
                : !credentials?.cac_certificate
                  ? "N/A"
                  : "View"}
            </button>
          </div>
          <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
            <span>One year bank statement</span>

            <button
              onClick={() => setShowBankStatementPopup(true)}
              className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
            >
              {credentialsLoading
                ? "Please wait"
                : !credentials?.bank_statement
                  ? "N/A"
                  : "View"}
            </button>
          </div>
          <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
            <span>Utility bill</span>

            <button
              onClick={() => setShowUtilityBillPopup(true)}
              className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
            >
              {credentialsLoading
                ? "Please wait"
                : !credentials?.utility_bill
                  ? "N/A"
                  : "View"}
            </button>
          </div>

          <div className="flex flex-col items-center mt-[42px]">
            <button
              onClick={() => {
                //   setShowMenu(false);
                setShowModal(false);
              }}
              className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
            >
              Confirm
            </button>
          </div>
        </div>
      ) : modalType === "view-user-details" ? (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <div className="overflow-hidden sm:w-[134px] sm:h-[134px] w-[100px] h-[100px] rounded-full bg-[#533C00] flex items-center justify-center mb-[74px]">
            <img
              alt="dp"
              src={
                selectedUser?.profile_image
                  ? selectedUser?.profile_image
                  : "/images/camera-icon.svg"
              }
              className={`${selectedUser?.profile_image
                  ? "sm:w-[134px] sm:h-[134px] w-[100px] h-[100px]"
                  : "max-w-[100px] max-h-[100px]"
                }
                     object-cover`}
            />
          </div>

          <label className="sm:text-[18px] text-[14px] w-[100%]">
            Name
            <input
              type={"text"}
              value={selectedUser?.name}
              disabled
              className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[14px] border-[1px] border-[#292D32]"
            />
          </label>

          <label className="sm:text-[18px] text-[14px] w-[100%]">
            Email
            <input
              type={"email"}
              value={selectedUser.email}
              className="mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[14px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="sm:text-[18px] text-[14px] w-[100%]">
            Phone number
            <input
              type={"tel"}
              value={selectedUser.phone_number}
              disabled
              className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[14px] border-[1px] border-[#292D32]"
            />
          </label>

          <label className="sm:text-[18px] text-[14px] w-[100%]">
            Account
            <input
              disabled
              type={"text"}
              value={selectedUser.account_type}
              className="mb-[20px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:px-[26px] px-[14px] border-[1px] border-[#292D32]"
            />
          </label>

          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] w-[80%] sm:h-[54px] h-[40px] text-[#fff] sm:text-[20px] text-[14px] font-[600] "
          >
            Back
          </button>
        </div>
      ) : modalType === "add-user" ? (
        <div className="w-[100%] flex flex-col items-center">
          {/* <div className="w-[134px] h-[134px] rounded-full bg-[#533C00] flex items-center justify-center mb-[74px]">
            <img alt="dp" src="/images/camera-icon.svg" className="" />
          </div> */}

          <label className="text-[18px] w-[100%]">
            Name
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type={"text"}
              className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] h-[55px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>

          <label className="text-[18px] w-[100%]">
            Email
            <input
              type={"email"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] h-[55px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="text-[18px] w-[100%]">
            Phone number
            <input
              type={"tel"}
              maxLength={11}
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] h-[55px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>

          <label className="text-[18px] w-[100%]">
            User Type
            <select
              onChange={(e) => setAccountType(e.target.value)}
              className="mb-[20px] bg-[#F9F9F9] outline-none mt-[9px] h-[55px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            >
              <option>---Select User Type---</option>

              <option value={"business"}>Business</option>
              <option value={"investor"}>Investor</option>
              <option value={"partner"}>Partner</option>
              <option value={"admin"}>Admin</option>
            </select>
          </label>

          <button
            disabled={
              isAdding ||
              !name.length ||
              phone_number.length < 11 ||
              (!email.length && !email.includes("@"))
            }
            onClick={() => {
              addUser();
            }}
            className={`${!name.length ||
                phone_number.length < 11 ||
                !email.length ||
                !email.includes("@")
                ? "opacity-30"
                : ""
              } flex items-center justify-center mt-[47px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] w-[80%] h-[54px] text-[#fff] text-[20px] font-[600] `}
          >
            {isAdding && (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
            {isAdding ? "Adding..." : "Add User"}
          </button>
        </div>
      ) : (
        modalType === "user-added" && (
          <div className="px-[5%] w-[100%] flex flex-col items-center">
            <img src="/images/success-icon.svg" alt="success" />

            <h3 className="mt-[25px] font-[600] text-[24px] text-center">
              User added
            </h3>
            <p className="text-[16px] mt-[15px] leading-[22px] text-center">
              You have successfully added this user. You can go to the user
              section to view details
            </p>

            <button
              onClick={() => setShowModal(false)}
              className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] mt-[27px]  w-[80%] h-[54px] text-[#fff] text-[20px] font-[600] "
            >
              Back
            </button>
          </div>
        )
      )}

      {showBankStatementPopup && credentials?.bank_statement && (
        <ImagePopUp
          image={credentials?.bank_statement}
          close={() => setShowBankStatementPopup(false)}
        />
      )}
      {showCacPopup && credentials?.cac_certificate && (
        <ImagePopUp
          image={credentials?.cac_certificate}
          close={() => setShowCacPopup(false)}
        />
      )}
      {showUtilityBillPopup && credentials?.utility_bill && (
        <ImagePopUp
          image={credentials?.utility_bill}
          close={() => setShowUtilityBillPopup(false)}
        />
      )}
    </motion.div>
  );
};

function AllUsers() {
  const [selectedRequest, setSelectedRequest] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [users, setUsers] = useState<any[]>([]);
  const [isAdding, setIsAdding] = useState(false);

  const [name, setName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [account_type, setAccountType] = useState("business");
  const [loading, setLoading] = useState(false);
  const [credentialsLoading, setCredentialLoading] = useState(false);
  const [credentials, setCredentials] = useState<any>();
  const [selectedUser, setSelectedUser] = useState();
  const [approving, setApproving] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);

  const getUsers = async () => {
    try {
      const resp = await axios.get("users/all");
      setUsers(resp.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const getCredentials = async (user_id: string) => {
    setCredentialLoading(true);
    try {
      const response = await axios.get(`admin/user/${user_id}`);
      setCredentials(response.data.credentials);
      setCredentialLoading(false);
    } catch (error) {
      setCredentialLoading(false);
      console.log(error, "ERROR");
    }
  };

  const addUser = async () => {
    setIsAdding(true);
    let password = name.charAt(0).toUpperCase() + name.slice(1);
    try {
      await axios.post("users/register", {
        email,
        account_type,
        phone_number,
        password: `${password.split(" ")[0]}@123456`,
        name,
      });
      setModalType("user-added");
      setIsAdding(false);

      successToastMessage("User Added!");
      getUsers();
    } catch (error: any) {
      console.log(error);
      errorToastMessage(error);
      setIsAdding(false);
    }
  };

  const approveOrDisapproveUser = async (user_id: string, status: string) => {
    setApproving(true);
    try {
      const res = await axios.patch(`users/approve/${user_id}`, {
        status,
      });
      successToastMessage(res.data.message);

      setApproving(false);
      getUsers();
    } catch (error) {
      setApproving(false);
      console.log(error);
      errorToastMessage(error);
    }
  };
  useEffect(() => {
    const controller = new AbortController();

    const getAllUsers = async () => {
      setLoading(true);
      await getUsers();

      setLoading(false);
    };

    getAllUsers();

    return () => {
      controller.abort();
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="h-[100%] w-[100%] pt-[80px] px-[31px] sm:pt-[55px] sm:px-[55px] sm:p-[55px]">
      <div className="sm:flex items-center justify-between mb-[43px] hidden">
        <h5 className="font-[500] text-[24px] sm:block hidden ">Users</h5>

        <button
          onClick={() => {
            setShowModal(true);
            setModalType("add-user");
          }}
          className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] px-[23px] py-[13px] flex items-center justify-center rounded-[10px] text-[#fff] text-[20px] font-[600]"
        >
          Add User
        </button>
      </div>

      <div className="sm:flex hidden items-center justify-between">
        <div className="bg-[#fff] py-[7px] px-[19px] rounded-[5px]">
          <select className="outline-none text-[#00000080] text-[12px] font-[500]">
            <option>Sort by</option>
            <option>Interest</option>
            <option>Partners</option>
          </select>
        </div>

        <div className=" hidden ml-[65px] flex-1 sm:flex items-center bg-[#fff] rounded-[5px] px-[15px] text-[16px] h-fit">
          <img alt="search" src="/images/search-icon.svg" />
          <input
            className="outline-none px-[10px] h-[37px] w-[100%]"
            placeholder="Search"
          />
        </div>

        <div className="ml-[38px] sm:flex hidden bg-[#fff] items-center border-[0.5px] border-[#292D32] rounded-[5px] h-[36px] px-[30px]">
          <img alt="icon" src="/images/calendar.svg" />
          <select className="ml-[18.3px] outline-none text-[#00000080] text-[12px] font-[500]">
            <option>Last 60 Days</option>
            <option>Last 3 Months</option>
            <option>Last 6 Months</option>
          </select>
        </div>
        <div className="ml-[38px] sm:flex hidden bg-[#fff] items-center border-[0.5px] border-[#292D32] rounded-[5px] h-[36px] px-[30px] font-[400] text-[12px]">
          <button>20, Aug 2022</button>
          <span className="text-[11px] font-[200] mx-[5px]">to</span>
          <button>20, Oct 2022</button>
        </div>
      </div>

      <div className=" overflow-x-auto mt-[15px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)] ">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-4 py-3">
                Name
              </th>
              <th scope="col" className="px-4 py-3">
                Date
              </th>
              <th scope="col" className="px-4 py-3">
                Account type
              </th>
              <th scope="col" className="px-4 py-3">
                Email
              </th>
              <th scope="col" className="px-4 py-3">
                Phone Number
              </th>
              <th scope="col" className="px-4 py-3">
                Status
              </th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                className=" cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[500] "
                key={index}
              >
                <td className="px-4 py-4">{user?.name}</td>

                <td className="px-4 py-4">{user?.createdAt?.split("T")[0]}</td>

                <td className="px-4 py-4 capitalize">{user?.account_type}</td>
                <td className="px-4 py-4">{user?.email}</td>
                <td className="px-4 py-4">{user?.phone_number}</td>
                <td
                  className={`px-4 py-4 ${user?.verification_status === "verified"
                      ? "text-[#30B956]"
                      : "text-red-300"
                    }`}
                >
                  {user?.verification_status === "verified" ? (
                    "Verified"
                  ) : user?.verification_status === "not:verified" ? (
                    "Not Verified"
                  ) : (
                    <>
                      {approving && index === currIndex ? (
                        <svg
                          aria-hidden="true"
                          className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-[#F6EACC]"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#fff"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      ) : (
                        <div className="flex items-center">
                          {
                            <button
                              disabled={approving}
                              onClick={() => {
                                setCurrIndex(index);

                                approveOrDisapproveUser(user?.id, "verified");
                              }}
                              className="active:scale-150"
                            >
                              <img alt="accept" src="/images/accept-icon.svg" />
                            </button>
                          }
                          {
                            <button
                              disabled={approving}
                              onClick={() => {
                                setCurrIndex(index);

                                approveOrDisapproveUser(
                                  user?.id,
                                  "not:verified"
                                );
                              }}
                              className="ml-[17px] active:scale-150"
                            >
                              <img alt="reject" src="/images/reject-icon.svg" />
                            </button>
                          }
                        </div>
                      )}
                    </>
                  )}
                </td>

                <td className="px-4 py-4 relative">
                  <button
                    onClick={() => {
                      setShowMenu(!showMenu);
                      setSelectedRequest(index.toString());
                      setSelectedUser(user);
                    }}
                    className="min-w-[20px] rounded-[5.38px] flex justify-center items-center "
                  >
                    <img
                      src="/images/more.svg"
                      className="min-w-[20px] min-h-[20px]"
                      alt="more"
                    />
                  </button>

                  {showMenu && selectedRequest === index.toString() && (
                    <div className="absolute top-0 z-[10] right-[45px] pt-[16px] pb-[8px] text-[#292D32]  rounded-[2px] sm:w-[241px] w-[150px]  bg-[#fff] drop-shadow-md">
                      {[
                        {
                          label: "View User",
                          onClick: () => {
                            setShowMenu(false);

                            setShowModal(true);
                            setModalType("view-user-details");
                          },
                        },
                        // {
                        //   label: "Suspend User",
                        //   onClick: () => {
                        //     setShowMenu(false);

                        //     // setShowModal(true)
                        //     // setModalType('suspend')
                        //     console.log("SUSPEND");
                        //   },
                        // },
                        // {
                        //   label: "Ban User",
                        //   onClick: () => {
                        //     setShowMenu(false);

                        //     // setShowModal(true)
                        //     // setModalType('view-user-details')
                        //     console.log("SUSPEND");
                        //   },
                        // },
                        {
                          label: "View Docs",
                          onClick: () => {
                            setShowModal(true);
                            setModalType("uploaded-doc");
                            getCredentials(user.id);
                            // console.log("SUSPEND");
                          },
                        },
                      ].map(({ label, onClick }, index) => (
                        <button
                          onClick={onClick}
                          key={index}
                          className={`w-[100%] ${index < 3 ? "border-b-[0.5px]" : ""
                            } border-b-[#292D324D] active:bg-[#e8e8e8ba] py-[11.5px] px-[22px] flex items-center sm:text-[14px] text-[12px]`}
                        >
                          {label}
                        </button>
                      ))}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
        </div>
      </div> */}

      {showModal && (
        <Modal>
          <DetailsModal
            account_type={account_type}
            setAccountType={setAccountType}
            name={name}
            selectedUser={selectedUser}
            credentialsLoading={credentialsLoading}
            credentials={credentials}
            setName={setName}
            phone_number={phone_number}
            setPhoneNumber={setPhoneNumber}
            setEmail={setEmail}
            setShowMenu={setShowMenu}
            setShowModal={setShowModal}
            isAdding={isAdding}
            modalType={modalType}
            addUser={addUser}
            email={email}
          />
        </Modal>
      )}
    </div>
  );
}

export default AllUsers;
