import React, { useCallback, useEffect, useState } from "react";
import AuthHeader from "../auth/auth-header";
import { Footer } from "./landing-home";
import { useParams } from "react-router-dom";
import { errorToastMessage } from "../../utils/toast-message";
import axios from "axios";
import { IBlog } from "./blog";
import ReactQuill from "react-quill";
import Moment from "react-moment";
import Loader from "../../components/Loader";

function BlogDetails() {
  const params = useParams();
  const [blog, setBlog] = useState<IBlog>();
  const [, setWidth] = useState(1440);
  const [pageLoading, setPageLoading] = useState(false);

  const maxWidth = 1440;
  const percentage = 100;

  let dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  let zoom =
    dimensions.width > 1200 ? (dimensions.width * percentage) / maxWidth : 100;

  function handleResize() {
    dimensions.width = window.innerWidth;
    setWidth(window.innerWidth);
    zoom =
      dimensions.width > 1200
        ? (dimensions.width * percentage) / maxWidth
        : 100;
    // @ts-ignore
    document.body.style.zoom = zoom + "%";
  }

  // @ts-ignore
  document.body.style.zoom = zoom + "%";

  //change dimension on page resize
  window.addEventListener("resize", handleResize);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  const getBlog = useCallback(async () => {
    setPageLoading(true);
    try {
      const response = await axios.get("blogs/" + params.id);

      setBlog(response.data.blog);
      setPageLoading(false);
    } catch (error) {
      errorToastMessage(error);
      setPageLoading(false);
    }
  }, [params]);
  useEffect(() => {
    const controller = new AbortController();
    getBlog();

    return () => controller.abort()
  }, [getBlog]);
  if (pageLoading) {
    return <Loader />;
  }
  return (
    <div className="bg-[#fff] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] ">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
        {/* <div className="sm:px-[78px] overflow-y-hidden overflow-x-hidden px-[20px] pb-[50px] sm:py-[100px] py-[10px]  flex flex-wrap max-[600px]:flex-col ">
          <div className="flex-1 sm:max-w-[40%] w-[95%] z-[100] ">
            <h2 className="sm:leading-[68px] font-[600] sm:text-[64px] text-[40px] text-[#fff]">
              Blog
            </h2>
            <p className="my-[29px]  sm:text-[16px] text-[13px] text-[#fff] ">
              Get the best and latest information about financing and loan.{" "}
            </p>

            <div className=" sm:px-[30px] sm:py-[15px] px-[23px] py-[12px]" />

            <div className=" flex items-center pl-[4px] bg-[#fff] rounded-[7.15px] w-[100%]">
              <input
                className="px-[10px] outline-none h-[40.07px] bg-[#fff] w-[100%]"
                placeholder="Enter email"
              />

              <button className=" bg-[#D09600] text-[#fff] text-[12.2px] h-[40.07px] px-[14px] ">
                Subscribe
              </button>
            </div>
          </div>

        </div> */}
      </div>

      <div className="p-[5%] flex justify-center">
        <div className="max-w-[730px] sm:w-[730px]">
          <h3 className="font-[800] mb-[10px] sm:text-[38px] text-[20px] capitalize">
            {blog?.title}
          </h3>
          {/* <p className="sm:text-[18px] text-[15px]">{blog?.description}</p> */}

          <div className="flex items-center mt-[15px]">
            <p className="font-[600] sm:text-[14px] text-[12px] mr-[5px]">
              By: {blog?.author.name} {"   "}{" "}
            </p>{" "}
            |{" "}
            <p className="ml-[5px] sm:text-[14px] text-[12px]">
              {/* {" 13 Mar 2023"} */}
              <Moment format="DD MMM, YYYY">{blog?.createdAt}</Moment>
            </p>
          </div>

          <div className="my-[20px] ">
            <img
              src={blog?.coverImage}
              alt="future-of-lending"
              className="w-[100%]"
            />

            <ReactQuill
              value={blog?.details}
              readOnly={true}
              theme={"bubble"}
            />

            {/* <div
              className="text-left mt-[30px]"
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: blog?.details }}
            /> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogDetails;
