import { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import { useLocation, useNavigate, Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { showSidebar } from "../store/sidebar-reducer"
import { IUser } from "../pages/business-dashboard/loan"
import { logout } from "../utils/logout"

function MobileSidebar({ show }: { show: boolean }) {
  const dispatch = useDispatch()

  const currentUser: IUser = useSelector((state: any) => state.user.user)

  const [showLoanTypes, setShowLoanTypes] = useState(false)
  const [showUserTypes, setShowUserTypes] = useState(false)
  const [showLogout, setShowLogout] = useState(false)
  const location = useLocation()
  const navigate = useNavigate()
  const [user, setUser] = useState<IUser>(currentUser)

  let path = location.pathname

  // useEffect(() => {
  //   const getUser = async () => {
  //     let res = await getCUrrentUser();
  //     setUser(res);

  //     dispatch(updateUser(res));
  //   };

  //   getUser();
  // }, []);
  let accountType = user?.currentUser?.account_type

  const closeSidebar = () => {
    dispatch(showSidebar({ show: false }))
  }

  const loanReqestMenu = [
    {
      title: "SME Loans",
      link: "/loan-requests/sme"
    },
    {
      title: "Local Purchase Order",
      link: "/loan-requests/lpo"
    },
    {
      title: "Invoice",
      link: "/loan-requests/invoice"
    }
  ].map(({ title, link }, index) => (
    <Link
      onClick={closeSidebar}
      to={link}
      key={index}
      className={`block px-[44px] ${
        path.includes(link) ? "text-[#D09600]" : ""
      }  ${
        index === 0 ? "mt-[-10px]" : index === 2 ? "mb-[20px]" : ""
      } ml-[6px] mb-[16px] text-[12.5px]`}
    >
      {title}
    </Link>
  ))

  const userTypeMenu = [
    {
      title: "All Users",
      link: "/users"
    },
    {
      title: "Partners",
      link: "/users/partners"
    }
  ].map(({ title, link }, index) => (
    <Link
      onClick={closeSidebar}
      to={link}
      key={index}
      className={`block px-[44px] ${path === link ? "text-[#D09600]" : ""}  ${
        index === 0 ? "mt-[-10px]" : index === 2 ? "mb-[20px]" : ""
      } ml-[6px] mb-[16px] text-[12.5px]`}
    >
      {title}
    </Link>
  ))

  return (
    <AnimatePresence>
      {show ? (
        <motion.div
          initial={{ opacity: 0, scale: 0.5, width: 0 }}
          animate={{ opacity: 1, scale: 1, width: "80%" }}
          exit={{ scale: 0.5, width: 0, opacity: 0 }}
          transition={{ duration: 0.5 }}
          // className="overflow-y-scroll fixed w-[80%] bg-[#fff] top-0  p-[27px] sidebar overflow-y-scroll z-[10000] right-0 h-[100%]"
          className=" fixed w-[80%] bg-[#fff] top-0  p-[27px] sidebar z-[10000] right-0 h-[100%]"
        >
          <div className="w-[115%] flex justify-end pr-[40px] mb-[40px]  ">
            <button
              onClick={closeSidebar}

              // onClick={() => setShowMenu(!showMenu)}
            >
              <img
                src={"/images/close-circle.svg"}
                className="sm:hidden block w-[21.5px] h-[21.5px] object-contain"
                alt="menu"
              />
            </button>
          </div>

          <div className="mb-[46px]">
            <img
              src="/images/logo-mini.svg"
              className="w-[48px] h-[48px] mb-[20px]"
              alt="logo"
            />{" "}
            <h4 className="text-[16px]">
              Welcome back {currentUser?.currentUser?.name}
            </h4>
          </div>

          <div className="flex flex-col justify-between w-[100%] h-[100%]">
            <div className="w-[100%]">
              <button
                onClick={() => {
                  closeSidebar()
                  accountType === "business"
                    ? navigate("/loan/dashboard")
                    : accountType === "investor"
                    ? navigate("/investor/dashboard")
                    : accountType === "admin"
                    ? navigate("/admin/dashboard")
                    : accountType === "partner" &&
                      navigate("/partner/dashboard")
                }}
                className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                  (accountType === "business" && path.includes("loan")) ||
                  path.includes("dashboard") ||
                  path.includes("admin") ||
                  path === "/"
                    ? "bg-[#D09600] text-[#fff]"
                    : "bg-[transparent] text-[#292D32]"
                } `}
              >
                <div className="flex items-center">
                  <img
                    src={
                      (accountType === "business" && path.includes("loan")) ||
                      path.includes("investor") ||
                      path.includes("dashboard") ||
                      path === "/"
                        ? "/images/category-active.svg"
                        : "/images/category.svg"
                    }
                    alt="icon"
                    className="w-[24px] h-[24px]"
                  />
                  <span className="sm:text-[20px] text-[14px]  ml-[13px]">
                    Dashboard
                  </span>
                </div>

                <img
                  className=""
                  alt="icon"
                  src={
                    (accountType === "business" && path.includes("loan")) ||
                    path.includes("investor") ||
                    path.includes("dashboard") ||
                    path === "/"
                      ? "/images/caret-down-white.svg"
                      : "/images/caret-down-black.svg"
                  }
                />
              </button>
              {/* {dashboardMenu} */}

              {accountType === "admin" && (
                <button
                  onClick={() => {
                    setShowLoanTypes(!showLoanTypes)

                    if (!path.includes("loan-requests")) {
                      closeSidebar()

                      navigate("/loan-requests/sme")
                    }
                  }}
                  className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                    path.includes("loan-request")
                      ? "bg-[#D09600] text-[#fff]"
                      : "bg-[#fff] text-[#292D32]"
                  }`}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        path.includes("loan-requests")
                          ? "/images/loan-icon-active.svg"
                          : "/images/loan-icon.svg"
                      }
                      alt="icon"
                    />
                    <span className="sm:text-[20px] text-[14px] ml-[13px]">
                      Loan request
                    </span>
                  </div>

                  <img
                    className={`${showLoanTypes ? "rotate-180" : ""}`}
                    alt="icon"
                    src={
                      path.includes("/loan-request")
                        ? "/images/caret-down-white.svg"
                        : "/images/caret-down-black.svg"
                    }
                  />
                </button>
              )}
              {showLoanTypes && path.includes("loan-request") && loanReqestMenu}

              <button
                onClick={() => {
                  closeSidebar()
                  navigate("/wallet")
                }}
                className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                  path.includes("wallet")
                    ? "bg-[#D09600] text-[#fff]"
                    : "bg-[#fff] text-[#292D32]"
                }`}
              >
                <div className="flex items-center">
                  <img
                    src={
                      path.includes("wallet")
                        ? "/images/wallet-icon-active.svg"
                        : "/images/wallet-icon.svg"
                    }
                    alt="icon"
                  />
                  <span className="sm:text-[20px] text-[14px] ml-[13px]">
                    Wallet
                  </span>
                </div>

                <img
                  className=""
                  alt="icon"
                  src={
                    path.includes("/wallet")
                      ? "/images/caret-down-white.svg"
                      : "/images/caret-down-black.svg"
                  }
                />
              </button>
              {accountType !== "business" && accountType !== "partner" && (
                <button
                  onClick={() => {
                    closeSidebar()
                    navigate("/marketplace")
                  }}
                  className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                    path.includes("marketplace")
                      ? "bg-[#D09600] text-[#fff]"
                      : "bg-[#fff] text-[#292D32]"
                  }`}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        path.includes("marketplace")
                          ? "/images/marketplace-icon-active.svg"
                          : "/images/marketplace-icon.svg"
                      }
                      alt="icon"
                    />
                    <span className="sm:text-[20px] text-[14px] ml-[13px]">
                      Marketplace
                    </span>
                  </div>

                  <img
                    className=""
                    alt="icon"
                    src={
                      path.includes("/marketplace")
                        ? "/images/caret-down-white.svg"
                        : "/images/caret-down-black.svg"
                    }
                  />
                </button>
              )}

              {accountType === "partner" && (
                <button
                  onClick={() => {
                    closeSidebar()
                    navigate("/buy-in-requests")
                  }}
                  className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                    path.includes("buy-in-requests")
                      ? "bg-[#D09600] text-[#fff]"
                      : "bg-[#fff] text-[#292D32]"
                  }`}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        path.includes("buy-in-requests")
                          ? "/images/archive-book.svg"
                          : "/images/archive-book-dark.svg"
                      }
                      className="h-[24px] w-[24px]"
                      alt="icon"
                    />
                    <span className="sm:text-[20px] text-[14px] ml-[13px]">
                      Buy-in requests
                    </span>
                  </div>

                  <img
                    className=""
                    alt="icon"
                    src={
                      path.includes("buy-in-requests")
                        ? "/images/caret-down-white.svg"
                        : "/images/caret-down-black.svg"
                    }
                  />
                </button>
              )}
              {accountType === "partner" && (
                <button
                  onClick={() => {
                    closeSidebar()
                    navigate("/sme-referrals")
                  }}
                  className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                    path.includes("sme-referrals")
                      ? "bg-[#D09600] text-[#fff]"
                      : "bg-[#fff] text-[#292D32]"
                  }`}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        path.includes("sme-referrals")
                          ? "/images/sme-referral-active.svg"
                          : "/images/sme-referral.svg"
                      }
                      className="h-[24px] w-[24px]"
                      alt="icon"
                    />
                    <span className="sm:text-[20px] text-[14px] ml-[13px]">
                      SME Referrals
                    </span>
                  </div>

                  <img
                    className=""
                    alt="icon"
                    src={
                      path.includes("sme-referrals")
                        ? "/images/caret-down-white.svg"
                        : "/images/caret-down-black.svg"
                    }
                  />
                </button>
              )}

              {accountType === "admin" && (
                <button
                  onClick={() => {
                    setShowUserTypes(!showUserTypes)

                    if (!path.includes("users")) {
                      closeSidebar()
                      navigate("/users")
                    }
                  }}
                  className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                    path.includes("users")
                      ? "bg-[#D09600] text-[#fff]"
                      : "bg-[#fff] text-[#292D32]"
                  }`}
                >
                  <div className="flex items-center">
                    <img
                      src={
                        path.includes("users")
                          ? "/images/users-active.svg"
                          : "/images/users.svg"
                      }
                      alt="icon"
                    />
                    <span className="sm:text-[20px] text-[14px] ml-[13px]">
                      User
                    </span>
                  </div>

                  <img
                    className={`${showUserTypes ? "rotate-180" : ""}`}
                    alt="icon"
                    src={
                      path.includes("/users")
                        ? "/images/caret-down-white.svg"
                        : "/images/caret-down-black.svg"
                    }
                  />
                </button>
              )}
              {showUserTypes && path.includes("users") && userTypeMenu}

              <button
                onClick={() => {
                  closeSidebar()
                  navigate("/settings")
                }}
                className={`mb-[30px] w-[100%] rounded-[10px] flex justify-between items-center px-[24px] py-[12px] ${
                  path.includes("settings")
                    ? "bg-[#D09600] text-[#fff]"
                    : "bg-[#fff] text-[#292D32]"
                }`}
              >
                <div className="flex items-center">
                  <img
                    src={
                      path.includes("settings")
                        ? "/images/setting-icon-active.svg"
                        : "/images/setting-icon.svg"
                    }
                    alt="icon"
                  />
                  <span className="sm:text-[20px] text-[14px] ml-[13px]">
                    Settings
                  </span>
                </div>

                <img
                  className=""
                  alt="icon"
                  src={
                    path.includes("settings")
                      ? "/images/caret-down-white.svg"
                      : "/images/caret-down-black.svg"
                  }
                />
              </button>

              <button
                onClick={async () => {
                  await logout()
                  closeSidebar()

                  navigate("/auth/login")
                }}
                className="rounded-[5px] px-[64px] py-[10px] active:bg-[#ffece6c4] bg-[#FFECE6] mt-[37px] text-[#FF3D00] flex items-center font-[600] text-[18px]"
              >
                <img alt="icon" src="/images/logout.svg" />
                <span className="ml-[15px]">Log out</span>
              </button>
            </div>

            {/* <div className="px-[19px] mt-[100px] pb-[50px]">
              <p className="sm:text-[20px] text-[14px] text-[#292D32] mb-[14.5px]">
                Profile
              </p> */}

            {/* <div className="flex items-center relative">
                <img
                  src="https://images.unsplash.com/photo-1593351799227-75df2026356b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=995&q=80"
                  className="w-[52px] h-[52px] rounded-full  object-cover overflow-hidden"
                  alt="profile"
                />
                <div className="ml-[10px]">
                  <h5 className="text-[#292D32] text-[18px]">
                    {currentUser?.currentUser?.name}
                  </h5>
                  <span className="mt-[4px] text-[#292D32] text-[14px] font-[300]">
                    {currentUser?.currentUser.email}
                  </span>
                </div> */}

            {/* <button
                  className="ml-[auto]"
                  onClick={() => setShowLogout(!showLogout)}
                >
                  <img
                    alt="menu"
                    src="/images/more.svg"
                    className="rotate-90"
                  />
                </button>

                {showLogout && (
                  <motion.button
                    initial={{
                      opacity: 0,
                      paddingRight: "0px",
                      paddingLeft: "0px",
                      paddingTop: "0px",
                      paddingBottom: "0px"
                    }}
                    animate={{
                      opacity: 1,
                      paddingRight: "29px",
                      paddingLeft: "29px",
                      paddingTop: "13px",
                      paddingBottom: "13px"
                    }}
                    transition={{ duration: 0.3 }}
                    onClick={async () => {
                      closeSidebar()
                      await logout()
                      navigate("/auth/login")
                    }}
                    className="drop-shadow-lg absolute right-0 top-[-95px] rounded-[20px] px-[29px] py-[13px] active:bg-[#ffece6c4] bg-[#FFF] mt-[37px] text-[#292D32] flex items-center font-[400] text-[14px]"
                  >
                    <img
                      alt="icon"
                      src="/images/logout-dark.svg"
                      className="w-[16px] h-[16px]"
                    />
                    <span className="ml-[15px]">Log out</span>
                  </motion.button>
                )} */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  )
}

export default MobileSidebar
