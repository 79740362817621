import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    token: null,
  },
  reducers: {
    updateUser: (state, action) => {
      state.user = { ...action.payload };
    },
    updateToken: (state, action) => {
      state.token = action.payload;
    },
  },
});

export const { updateUser, updateToken } = userSlice.actions;

export default userSlice.reducer;
