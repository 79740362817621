import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthHeader from './auth-header'
import './styles.css'

interface TabsInterface {
    label: string;
    details: string
}




function InvoiceSetup() {
    const TABS: TabsInterface[] = [
        {
            label: 'Personal Information',
            details: 'Please enter your personal details'
        },
        {
            label: 'Company Information',
            details: 'Please enter your company information'
        },
        {
            label: 'Invoice Document',
            details: 'Please upload the invoice documents'
        },
    ]
    const navigate = useNavigate()

    const upload = () => {
        localStorage.setItem('invoice-profile-completed', 'true')
        navigate('/loan/dashboard')
    }
    const [currentTab, setCurrentTab] = useState<TabsInterface>(TABS[0])

    function getAllBefore() {
        let current: TabsInterface = TABS[0]

        for (const tab of TABS) {
            if (tab.label.toLowerCase() === currentTab.label.toLowerCase()) {
                current = tab
            }
        }

        var i = TABS.indexOf(current);
        const result: any = i > -1 ? TABS.slice(0, i) : [];
        return result
    }

    return (
        <div className='h-[100%] overflow-y-scroll pt-[70px] sm:px-[94px] px-[24px] flex flex-col text-[#292D32]'>

            <div className='flex items-center justify-between'>
                {TABS.map((tab, index) => (
                    <div key={index} className={`${index > 0 ? 'ml-[73px]' : ''} sm:block hidden`}>
                        <h5 className='font-[600] text-[24px] leading-[30px] text-center'>{tab.label}</h5>
                        <p className='sm:text-[20px] leading-[22px] text-center text-[#333333] mt-[12px]'>{tab.details}</p>
                        <div className={`w-[100%] h-[7px] rounded-[4px] mt-[30px] ${currentTab.label === tab.label || getAllBefore().includes(tab) ? 'bg-[#D09600]' : 'bg-[#292D32]'}`} />
                    </div>
                ))}
            </div>

            <div className='sm:hidden flex items-center justify-between mt-[34px]'>
                {TABS.map((tab, index) => (
                    <div key={index} className={`${index > 0 ? 'ml-[13px]' : ''} w-[30%] h-[5px] rounded-[4px] mt-[30px] ${currentTab.label === tab.label || getAllBefore().includes(tab) ? 'bg-[#D09600]' : 'bg-[#292D32]'}`} />

                ))}
            </div>

            {
                currentTab.label.toLowerCase() === TABS[0].label.toLowerCase() ?
                    <div className='sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] py-[76px] sm:px-[46px] px-[0px] self-center'>
                        <h4 className='sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]'>{currentTab.label} </h4>
                        <p className='text-center mb-[80px] max-[620px]:text-[14px]'>{'Kindly fill in the following information to proceed.'}</p>



                        <label className='sm:text-[20px] text-[16px]'>
                            Name

                            <input type={"text"} placeholder='What is your personal name?' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                        </label>
                        <label className='sm:text-[20px] text-[16px]'>
                            Email

                            <input type={"email"} placeholder='Enter your email address' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                        </label>
                        <label className='sm:text-[20px] text-[16px]'>
                            BVN

                            <input type={"number"} placeholder='Enter your BVN here' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                        </label>

                        <div className='flex items-center justify-between mt-[21px]'>
                            <button onClick={() => {
                                console.log("OK")
                            }
                            } type='submit' className='mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32]'>
                                Back
                            </button>
                            <button onClick={() => {
                                setCurrentTab(TABS[1])
                            }
                            } type='submit' className='rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff]'>
                                Continue
                            </button>

                        </div>
                    </div> :
                    currentTab.label.toLowerCase() == TABS[1].label.toLowerCase() ?
                        <div className='sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] py-[76px] sm:px-[46px] px-[0px] self-center'>
                            <h4 className='sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]'>{currentTab.label} </h4>
                            <p className='text-center mb-[80px] max-[620px]:text-[14px]'>{'Kindly fill in the following information to proceed.'}</p>



                            <label className='sm:text-[20px] text-[16px]'>
                                Company's name

                                <input type={"text"} placeholder='What is your personal name?' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                            </label>
                            <label className='sm:text-[20px] text-[16px]'>
                                Company's email

                                <input type={"email"} placeholder='Enter your email address' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                            </label>
                            <div className='flex sm:flex-row flex-col sm:items-center'>
                                <label className='sm:text-[20px] text-[16px] flex-1'>
                                    Company's phone number

                                    <div className='flex items-center sm:border-[1px] border-[0.5px] border-[#292D32] rounded-[5px] h-[40px] sm:h-[55px] mb-[37px] mt-[9px]'>
                                        <span className='h-[40px] sm:h-[55px] border-r-[1px] border-r-[#292D3280]  flex items-center px-[10px] max-[620px]:text-[14px]'>+234</span>
                                        <input type={"tel"} placeholder="Enter company's tel " className=' outline-none  sm:h-[53px] h-[38px] w-[86%]  pl-[26px] max-[620px]:text-[14px] ' />
                                    </div>
                                </label>
                                <label className='sm:text-[20px] text-[16px] sm:ml-[24px] flex-1'>
                                    Company's website

                                    <input type={"text"} placeholder='Enter Website URL here' className='mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]' />
                                </label>
                            </div>
                            <div className='flex items-center justify-between mt-[21px]'>
                                <button onClick={() => {
                                    setCurrentTab(TABS[0])
                                }
                                } type='submit' className='mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32] '>
                                    Back
                                </button>
                                <button onClick={() => {
                                    setCurrentTab(TABS[2])

                                }
                                } type='submit' className='rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff]'>
                                    Continue
                                </button>

                            </div>
                        </div> :

                        <div className='sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] w-[100%] py-[76px] sm:px-[46px] px-[0px] self-center'>
                            <h4 className='sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]'>{currentTab.label} </h4>
                            <p className='text-center mb-[80px] max-[620px]:text-[14px]'>{'Kindly upload the following documents to proceed.'}</p>



                            <label className='sm:text-[20px] text-[16px] w-[100%]'>

                                CAC certificate of incorporation
                                <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
                                    <input type={"file"} className="hidden" id='upload'
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                console.log(e.target.files[0])
                                            }
                                        }

                                        }
                                    />
                                    <label className='text-[18px] active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]' htmlFor='upload'>

                                    </label>
                                </div>
                            </label>
                            <label className='sm:text-[20px] text-[16px]'>

                                A year bank statement
                                <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
                                    <input type={"file"} className="hidden" id='upload'
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                console.log(e.target.files[0])
                                            }
                                        }

                                        }
                                    />
                                    <label className='text-[18px] active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]' htmlFor='upload'>

                                    </label>
                                </div>
                            </label>
                            <label className='sm:text-[20px] text-[16px]'>

                                Valid ID card
                                <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
                                    <input type={"file"} className="hidden" id='upload'
                                        onChange={(e) => {
                                            if (e.target.files) {
                                                console.log(e.target.files[0])
                                            }
                                        }

                                        }
                                    />
                                    <label className='text-[18px] active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]' htmlFor='upload'>

                                    </label>
                                </div>
                            </label>


                            <div className='flex items-center justify-between sm:mt-[58px] mt-[30px]'>
                                <button onClick={() => {
                                    setCurrentTab(TABS[1])
                                }
                                } type='submit' className='mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[43px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32]'>
                                    Back
                                </button>
                                <button onClick={() =>
                                    upload()

                                } type='submit' className='rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[43px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff]'>
                                    Continue
                                </button>

                            </div>
                        </div>
            }

        </div>
    )
}

export default InvoiceSetup