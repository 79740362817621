import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes, useLocation } from "react-router-dom"
import { Navbar, Sidebar } from "./components"
import MobileNavbar from "./components/MobileNavbar"
import MobileSidebar from "./components/MobileSidebar"
import ReactGA from "react-ga"

import "react-toastify/dist/ReactToastify.css"

import {
  Dashboard as AdminDashboard,
  InvoiceRequests,
  LpoRequests,
  SmeRequests
} from "./pages/admin-dashboard"
import { InvoiceSetup, LpoSetup, Signin, Signup, SmeSetup } from "./pages/auth"
import { Invoice, Loan, Sme } from "./pages/business-dashboard"
import {
  InvestorDashboard,
  LoanDetails,
  Marketplace
} from "./pages/investor-dashboard"
import { AboutUs, Blog, EditBlog, LandingHome } from "./pages/landing-page"
import {
  BuyIn,
  PartnerDashboard,
  SmeReferrals
} from "./pages/partner-dashboard"
import Settings from "./pages/settings"
import { AllUsers, Partners } from "./pages/users"
import Wallet from "./pages/wallet"
import GuardedRoute from "./routes-guard/guarded-route"
import { ToastContainer } from "react-toastify"
import RoleGuardedRoute from "./routes-guard/role-guarded-route"
import axios from "axios"
import { updateUser } from "./store/user-reducer"
import BlogDetails from "./pages/landing-page/blog-details"
import Waitlist from "./pages/waitlist"
import CreateBlog from "./pages/landing-page/create-blog"
import ResetPassword from "./pages/auth/reset-password"
import SignupComplete from "./pages/auth/signup-complete"

function App() {
  const dispatch = useDispatch()
  const mytoken = localStorage.getItem("@token")
  const [token, setToken] = useState<any>(mytoken ? JSON.parse(mytoken) : null)
  const location = useLocation()
  const [width, setWidth] = useState(1440)
  const showSidebar = useSelector((state: any) => state.sidebar.show.show)

  const maxWidth = 1440
  const percentage = 100

  let pathname = location.pathname

  // axios.defaults.baseURL = "http://192.168.43.135:4000/api/";
  axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}api/`
  axios.defaults.headers.common["Authorization"] = "Bearer " + token

  if (
    localStorage.getItem("@token") &&
    axios.defaults.headers.common.Authorization.split(" ")[1] == null
  ) {
    window.location.reload()
  }

  const getCurrentUser = useCallback(async () => {
    try {
      const response = await axios.get("users/currentuser")

      dispatch(updateUser(response.data))
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getCurrentUser()
  }, [getCurrentUser])

  useEffect(() => {
    const mytoken = localStorage.getItem("@token")
    if (mytoken) {
      setToken(JSON.parse(mytoken))
    }
    const parsedToken = mytoken ? JSON.parse(mytoken) : ""
    axios.defaults.headers.common["Authorization"] = "Bearer " + parsedToken
  }, [token, mytoken])

  let dimensions = {
    width: window.innerWidth,
    height: window.innerHeight
  }
  let zoom =
    dimensions.width > 600 ? (dimensions.width * percentage) / maxWidth : 100

  function handleResize() {
    dimensions.width = window.innerWidth
    setWidth(window.innerWidth)
    zoom =
      dimensions.width > 600 ? (dimensions.width * percentage) / maxWidth : 100
    // @ts-ignore
    document.body.style.zoom = zoom + "%"
  }

  // @ts-ignore
  document.body.style.zoom = zoom + "%"

  //change dimension on page resize
  window.addEventListener("resize", handleResize)

  useEffect(() => {
    setWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    // Track the page view
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [location])

  return (
    <div className="flex min-h-[100vh] overflow-y-scroll overflow-x-hidden">
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        // ltr={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {!pathname.includes("/auth") &&
        pathname !== "/" &&
        !pathname.includes("onboarding") &&
        !pathname.includes("/about") &&
        !pathname.includes("/blog") &&
        !pathname.includes("/waitlist") &&
        width > 620 && <Sidebar />}
      <div
        className={`${
          pathname.includes("/auth") ||
          pathname === "/" ||
          pathname.includes("/about") ||
          pathname.includes("/blog") ||
          pathname.includes("waitlist") ||
          pathname.includes("onboarding") ||
          width < 620
            ? "flex-1"
            : "ml-[280px] w-[100%] h-[100%]"
        }  relative`}
      >
        {!pathname.includes("/auth") &&
        !pathname.includes("/about") &&
        !pathname.includes("/blog") &&
        !pathname.includes("waitlist") &&
        pathname !== "/" &&
        width > 620 ? (
          <Navbar />
        ) : (
          !pathname.includes("/auth") &&
          !pathname.includes("/about") &&
          !pathname.includes("/blog") &&
          !pathname.includes("waitlist") &&
          pathname !== "/" && <MobileNavbar />
        )}
        <div
          className={`${
            pathname.includes("/auth") ||
            pathname === "/" ||
            pathname.includes("/about") ||
            pathname.includes("/blog") ||
            pathname.includes("/waitlist") ||
            width < 620
              ? "h-[100%]"
              : "xl:h-[100vh] lg:h-[120vh] md:h-[150vh] sm:h-[210vh] mt-[75px] content "
          } w-[100%] overflow-x-hidden `}
        >
          <Routes>
            <Route path="/" element={<LandingHome />} />
            <Route
              path="/waitlist"
              element={
                <RoleGuardedRoute>
                  <Waitlist />
                </RoleGuardedRoute>
              }
            />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/create" element={<CreateBlog />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/blog/edit/:id" element={<EditBlog />} />
            <Route path="/blog/:id" element={<BlogDetails />} />

            {/* <Route path='/' element={<GuardedRoute ><div></div></GuardedRoute>} /> */}
            <Route
              path="/loan/dashboard"
              element={
                <GuardedRoute>
                  <Loan />
                </GuardedRoute>
              }
            />
            <Route
              path="/loan/sme"
              element={
                <GuardedRoute>
                  <Sme />
                </GuardedRoute>
              }
            />
            <Route
              path="/loan/invoice"
              element={
                <GuardedRoute>
                  <Invoice />
                </GuardedRoute>
              }
            />

            <Route
              path="/investor/dashboard"
              element={
                <GuardedRoute>
                  <InvestorDashboard />
                </GuardedRoute>
              }
            />
            <Route
              path="/admin/dashboard"
              element={
                <GuardedRoute>
                  <AdminDashboard />
                </GuardedRoute>
              }
            />
            <Route
              path="/partner/dashboard"
              element={
                <GuardedRoute>
                  <PartnerDashboard />
                </GuardedRoute>
              }
            />
            <Route
              path="/buy-in-requests"
              element={
                <GuardedRoute>
                  <BuyIn />
                </GuardedRoute>
              }
            />
            <Route
              path="/sme-referrals"
              element={
                <GuardedRoute>
                  <SmeReferrals />
                </GuardedRoute>
              }
            />

            <Route
              path="/loan-requests/sme"
              element={
                <GuardedRoute>
                  <SmeRequests />
                </GuardedRoute>
              }
            />
            <Route
              path="/loan-requests/lpo"
              element={
                <GuardedRoute>
                  <LpoRequests />
                </GuardedRoute>
              }
            />
            <Route
              path="/loan-requests/invoice"
              element={
                <GuardedRoute>
                  <InvoiceRequests />
                </GuardedRoute>
              }
            />
            <Route
              path="/marketplace"
              element={
                <GuardedRoute>
                  <Marketplace />
                </GuardedRoute>
              }
            />
            <Route
              path="/marketplace/investment/:id"
              element={<LoanDetails />}
            />

            <Route
              path="/users"
              element={
                <GuardedRoute>
                  <AllUsers />
                </GuardedRoute>
              }
            />
            <Route
              path="/users/partners"
              element={
                <GuardedRoute>
                  <Partners />
                </GuardedRoute>
              }
            />

            <Route
              path="/wallet"
              element={
                <GuardedRoute>
                  <Wallet />
                </GuardedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <GuardedRoute>
                  <Settings />
                </GuardedRoute>
              }
            />

            <Route
              path="/auth/login"
              element={
                <RoleGuardedRoute>
                  <Signin />
                </RoleGuardedRoute>
              }
            />
            <Route
              path="/auth/complete"
              element={
                <RoleGuardedRoute>
                  <SignupComplete />
                </RoleGuardedRoute>
              }
            />
            <Route
              path="/auth/register"
              element={
                <RoleGuardedRoute>
                  <Signup />
                </RoleGuardedRoute>
              }
            />
            <Route
              path="/auth/resetpassword"
              element={
                <RoleGuardedRoute>
                  <ResetPassword />
                </RoleGuardedRoute>
              }
            />
            <Route path="/onboarding/sme" element={<SmeSetup />} />
            <Route path="/onboarding/invoice" element={<InvoiceSetup />} />
            <Route path="/onboarding/lpo" element={<LpoSetup />} />
          </Routes>
        </div>

        <MobileSidebar show={showSidebar} />
      </div>
      {/* Nav bar */}
      {/* Sidebar */}
    </div>
  )
}

export default App
