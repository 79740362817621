import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Modal } from "../../components"
import { ILoan } from "../business-dashboard/sme"
import axios from "axios"
import { IUser } from "../business-dashboard/loan"
import { useSelector } from "react-redux"
import {
  errorToastMessage,
  successToastMessage
} from "../../utils/toast-message"
import Loader from "../../components/Loader"

interface InvestModalProps {
  modalType: string
  amount_invested: string
  setAmountInvested: (val?: any) => void
  setShowModal: (show?: any) => void
  investInLoan: () => void
  investing: boolean
  selectedRequest: ILoan
}
const InvestModal = ({
  modalType,
  setAmountInvested,
  selectedRequest,
  amount_invested,
  investing,
  setShowModal,
  investInLoan
}: InvestModalProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="rounded-sm sm:w-[700px] w-[90%]  bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[16px]">
          {modalType === "invest" ? "Sure you want to invest" : ""}
        </h4>
        <button
          disabled={investing}
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img
            alt="close"
            src="/images/close-circle.svg"
            className="max-[620px]:w-[16.13px] max-[620px"
          />
        </button>
      </div>

      {modalType === "invest" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <p className="text-center text-[#54595ED6] sm:text-[20px] text-[14px] font-[400]">
            Amount available for investment is &#8358;
            {selectedRequest.amount_left.toLocaleString()}
          </p>

          <div className="w-[100%] mt-[40px]">
            <label
              htmlFor="amount"
              className="text-[#292D32] sm:text-[20px] text-[14px] font-[600]"
            >
              Amount To Invest
            </label>

            <input
              value={amount_invested}
              id="amount"
              type={"number"}
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] text-[14px] sm:text-[16px]"
              onChange={(e) => setAmountInvested(e.target.value)}
            />
          </div>

          <div className="flex items-center justify-center sm:mt-[42px] mt-[30px]">
            <button
              disabled={investing}
              onClick={() => setShowModal(false)}
              className="sm:mr-[37px] mr-[14px] rounded-[8.43px] self-center active:bg-[#f4f1f1d2] bg-[#FFF] text-[#292D32] sm:text-[20px] text-[14px] font-[400] sm:w-[213px] sm:h-[52px] border-[#000000] border-[0.5px] max-[620px]:px-[27px] max-[620px]:py-[12px]"
            >
              Cancel
            </button>
            <button
              disabled={investing || parseFloat(amount_invested) <= 0}
              onClick={() => investInLoan()}
              className={`flex items-center justify-center rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] sm:w-[213px] sm:h-[52px] max-[620px]:px-[27px] max-[620px]:py-[12px] ${
                !amount_invested.length ? "opacity-30" : ""
              }`}
            >
              {investing && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              {investing ? "Investing..." : "Invest"}
            </button>
          </div>
        </div>
      ) : (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <img
            alt="processing"
            src="/images/processing-icon.svg"
            className="mb-[50px]"
          />
          <h5 className="text-center font-[600] sm:text-[24px] text-[18px] mb-[15px]">
            Application processing
          </h5>
          <p className="text-center sm:text-[20px] text-[14px] mb-[60px]">
            you will be notified on the status of your application
          </p>
          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] sm:ml-[37px] text-[#fff] sm:text-[24px] text-[14px] font-[600] sm:px-[76px] px-[27px] sm:py-[15px] py-[12px]"
          >
            Confirm
          </button>
        </div>
      )}
    </motion.div>
  )
}

interface MarketProps {
  setSelectedLoan?: (data?: any) => void
  setShowLoanDetails?: (data?: any) => void
}
function Marketplace({ setSelectedLoan, setShowLoanDetails }: MarketProps) {
  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")
  const [showMenu, setShowMenu] = useState(false)
  const [loans, setLoans] = useState<ILoan[]>()

  const [selectedRequest, setSelectedRequest] = useState<ILoan | null>(
    loans ? loans[0] : null
  )

  const [pageLoading, setPageLoading] = useState(false)
  const [amount_invested, setAmountInvested] = useState("")
  const currUser: IUser = useSelector((state: any) => state.user.user)
  const [investing, setInvesting] = useState(false)

  let accountType = currUser?.currentUser?.account_type
  accountType = accountType ? accountType : ""

  const getLoans = async () => {
    setPageLoading(true)
    try {
      const response = await axios.get("user-loans/published/all")
      setLoans([...response.data.loans])
      setPageLoading(false)
    } catch (error: any) {
      console.log(error)
      setPageLoading(false)
    }
  }

  useEffect(() => {
    const controller = new AbortController()
    getLoans()

    return () => {
      controller.abort()
    }
  }, [])

  const openLoan = (id: string) => {
    if (setSelectedLoan && setShowLoanDetails) {
      setSelectedLoan(id)
      setShowLoanDetails(true)
    } else {
      navigate("/marketplace/investment/" + id)
    }
  }

  const investInLoan = async () => {
    setInvesting(true)
    try {
      const res = await axios.post(`user-loans/${selectedRequest!.id}/invest`, {
        amount_invested: amount_invested
      })

      setInvesting(false)
      setShowModal(false)
      successToastMessage(res.data.message)
    } catch (error: any) {
      console.log(error)
      errorToastMessage(error)
      setInvesting(false)
    }
  }

  if (pageLoading && !setSelectedLoan) {
    return <Loader />
  }

  return (
    <div className="h-[100%] w-[100%] sm:pt-[55px] sm:pb-[55px] sm:px-[55px] pt-[95px] px-[31px] bg-[#F9F9F9]">
      <h5
        className={`font-[500] text-[24px] mb-[25px] ${
          setSelectedLoan ? "block" : "hidden"
        } sm:block`}
      >
        Marketplace
      </h5>

      {!setSelectedLoan && (
        <div className="flex items-center justify-between">
          <div className="bg-[#fff] py-[7px] px-[19px] rounded-[5px]">
            <select className="outline-none text-[#00000080] text-[12px] font-[500]">
              <option>Sort by</option>
              <option>Interest</option>
              <option>Partners</option>
            </select>
          </div>

          <div className="sm:ml-[65px] ml-[12px] flex-1 flex items-center bg-[#fff] rounded-[5px] px-[15px] text-[16px] h-[37px]">
            <img
              alt="search"
              src="/images/search-icon.svg"
              className="max-[620px]:w-[11.54px] max-[620px]:h-[11.54px]"
            />
            <input
              className="sm:text-[14px] text-[10.81px] outline-none px-[10px] h-[37px] w-[100%]"
              placeholder="Search"
            />
          </div>

          <div className="ml-[38px] sm:flex hidden bg-[#fff] items-center border-[0.5px] border-[#292D32] rounded-[5px] h-[36px] px-[30px]">
            <img alt="icon" src="/images/calendar.svg" />
            <select className="ml-[18.3px] outline-none text-[#00000080] text-[12px] font-[500]">
              <option>Last 60 Days</option>
              <option>Last 3 Months</option>
              <option>Last 6 Months</option>
            </select>
          </div>
          <div className="ml-[38px] sm:flex hidden bg-[#fff] items-center border-[0.5px] border-[#292D32] rounded-[5px] h-[36px] px-[30px] font-[400] text-[12px]">
            <button>20, Aug 2022</button>
            <span className="text-[11px] font-[200] mx-[5px]">to</span>
            <button>20, Oct 2022</button>
          </div>
        </div>
      )}

      <div className="sm:hidden flex items-center mb-[15px] mt-[35px]">
        <img alt="scroll" src="/images/hand-scroll.svg" />
        <span className="ml-[12px] font-[500] text-[#292D32] text-[12px]">
          Swipe horizontal to see more details
        </span>
      </div>

      <div className=" overflow-x-auto mt-[15px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)] ">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[14px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-4 py-3">
                Market originator
              </th>
              <th scope="col" className="px-4 py-3">
                No
              </th>
              <th scope="col" className="px-4 py-3 ">
                Date
              </th>
              <th scope="col" className="px-4 py-3">
                Amount
              </th>
              <th scope="col" className="px-4 py-3">
                Available
              </th>
              <th scope="col" className="px-4 py-3">
                Term
              </th>
              <th scope="col" className="px-4 py-3">
                Interest rate
              </th>
              <th scope="col" className="px-4 py-3"></th>
            </tr>
          </thead>
          {loans?.length ? (
            <tbody>
              {loans?.map((loan, index) => (
                <tr
                  className=" cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[500] "
                  key={index}
                >
                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    {loan.partner.name}
                  </td>
                  <td
                    onClick={() => openLoan(loan.id)}
                    className="px-4 py-4 uppercase max-w-[120px] truncate"
                  >
                    {loan.id}
                  </td>

                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    {loan?.createdAt?.split("T")[0]}
                  </td>

                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    &#8358;{loan?.amount_granted?.toLocaleString()}
                  </td>
                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    &#8358;{loan.amount_left.toLocaleString()}
                  </td>
                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    {loan.approved_tenure
                      ? loan.approved_tenure
                      : loan.preferred_tenure}
                  </td>
                  <td onClick={() => openLoan(loan.id)} className="px-4 py-4">
                    {loan.interest_rate}%
                  </td>

                  <td className="px-4 py-4 relative">
                    {accountType !== "admin" ? (
                      <button
                        onClick={() => {
                          if (accountType?.length) {
                            setShowModal(true)
                            setModalType("invest")
                            setSelectedRequest(loan)
                          } else {
                            navigate("/auth/register")
                          }
                        }}
                        className="min-w-[80px] rounded-[5.38px] flex justify-center items-center text-[#000000A1] font-[500] p-[8px] text-[12px] bg-[#D09600] text-[#fff]"
                      >
                        <span className="ml-[5.46px]">Invest</span>
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setShowMenu(!showMenu)
                          setSelectedRequest(loan)
                        }}
                        className="min-w-[20px] rounded-[5.38px] flex justify-center items-center "
                      >
                        <img
                          src="/images/more.svg"
                          className="min-w-[20px] min-h-[20px]"
                          alt="more"
                        />
                      </button>
                    )}

                    {showMenu && selectedRequest === loan && (
                      <div className="absolute z-[10] top-0 right-[20px] pt-[16px] pb-[8px] text-[#292D32]  rounded-[2px] sm:min-w-[241px] min-w-[220px] w-fit  bg-[#fff] drop-shadow-md">
                        {/* <h2 className="px-[22px] sm:mb-[28px] mb-[15px] sm:text-[20px] text-[14px] font-[600]">
                          Loan Options
                        </h2> */}

                        {[
                          {
                            label: "Suspend Loan",
                            onClick: () => {
                              setShowMenu(false)

                              // setShowModal(true)
                              // setModalType('suspend')
                              console.log("SUSPEND")
                            }
                          }
                        ].map(({ label, onClick }, index) => (
                          <button
                            onClick={onClick}
                            key={index}
                            className={`sm:text-[16px] text-[12px] w-[100%] ${
                              index < 2 ? "border-b-[0.5px]" : ""
                            } border-b-[#292D324D] active:bg-[#e8e8e8ba] py-[11.5px] px-[22px] flex items-center`}
                          >
                            {label}
                          </button>
                        ))}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td
                  className="px-4 py-4 text-gray-700   text-[16px] "
                  colSpan={5}
                >
                  New listings coming soon
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="w-[10px] h-[10px]"
            />
          </button>
        </div>
      </div> */}

      {showModal && (
        <Modal>
          <InvestModal
            selectedRequest={selectedRequest!}
            amount_invested={amount_invested}
            setAmountInvested={setAmountInvested}
            investing={investing}
            investInLoan={investInLoan}
            modalType={modalType}
            setShowModal={setShowModal}
          />
        </Modal>
      )}
    </div>
  )
}

export default Marketplace
