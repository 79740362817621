import axios from "axios";

export const uploadImage = async (image: any) => {
  const data = new FormData();
  data.append("file", image);
  data.append("upload_preset", "overcomers");
  data.append("cloud_name", "diils");
  try {
    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/diils/image/upload",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: null,
        },
      }
    );

    return response.data.secure_url;
  } catch (error) {
    console.log(error);
  }
};
