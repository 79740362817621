import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { showSidebar } from "../store/sidebar-reducer";
import { IUser } from "../pages/business-dashboard/loan";

function MobileNavbar() {
  const currentUser: IUser = useSelector((state: any) => state.user.user);

  const dispatch = useDispatch();
  return (
    <div className="z-[100] fixed top-0 right-0 h-[70px] w-[100%] flex justify-between items-center px-[30px] bg-[#fff] drop-shadow-sm">
      <div className="flex items-center">
        <img
          src="/images/logo-mini.svg"
          className="w-[58px] h-[58px] mr-[10px]"
          alt="logo"
        />{" "}
        <h4 className="text-[16px]">
          Welcome back {currentUser?.currentUser?.name?.split(" ")[0]}
        </h4>
      </div>

      <div className="flex items-center">
        <button>
          <img
            alt="noti.."
            src="/images/notification.svg"
            className="w-[16.32px] h-[17px]"
          />
        </button>
        <button
          className="ml-[17px]"
          onClick={() => dispatch(showSidebar({ show: true }))}
        >
          <img
            alt="menu"
            src="/images/menu.svg"
            className="w-[16.32px] h-[17px]"
          />
        </button>
      </div>
    </div>
  );
}

export default MobileNavbar;
