import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        show: false
    },
    reducers: {
        showSidebar: (state, action) => {
            state.show = { ...action.payload }
        }
    }
});

export const { showSidebar } = sidebarSlice.actions

export default sidebarSlice.reducer