/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IUser } from "../pages/business-dashboard/loan";
// import { updateUser } from "../store/user-reducer";

const RoleGuardedRoute = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = localStorage.getItem("@user");

  let navigation = useNavigate();

  useEffect(() => {
    if (isLoggedIn !== null) {
      let user: IUser = JSON.parse(isLoggedIn);

      switch (user?.currentUser?.account_type) {
        case "business":
          navigation("/loan/dashboard");
          // <Navigate to={"/loan/dashboard"} />;
          break;
        case "partner":
          navigation("/partner/dashboard");

          break;

        case "investor":
          navigation("/investor/dashboard");

          break;

        case "admin":
          navigation("/admin/dashboard");

          break;

        default:
          break;
      }
    }
  }, [isLoggedIn]);
  return children;
};

export default RoleGuardedRoute;
