/* eslint-disable no-useless-escape */
import { motion } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "../../components"
import AuthHeader from "./auth-header"

import "./styles.css"
import {
  errorToastMessage,
  successToastMessage,
  warningToastMessage
} from "../../utils/toast-message"
import axios from "axios"
import OtpPopUp from "../../components/otp-popup"
import { ToastContainer, toast } from "react-toastify"
import { isValidDate } from "../../utils/date-helper"

interface RegModalProps {
  name: string
  bvn: string
  dob: string
  modalType: string
  consentGiven: boolean
  setShowModal: (data: any) => void
  setShowOtpPopup: (data: any) => void
  setModalType: (data: any) => void
  setAccountType: (data: any) => void
  setPassword: (data: any) => void
  setVerifyBVN: (data: any) => void
  setBvnUrl: (data: any) => void
  account_type: string
  password: string
  email: string
  phone_number: string
  navigate: (val?: any) => void
}
const RegModal = ({
  name,
  bvn,
  dob,
  modalType,
  setShowModal,
  setModalType,
  account_type,
  setAccountType,
  password,
  phone_number,
  email,
  setBvnUrl,
  setVerifyBVN,
  setPassword,
  navigate,
  consentGiven,
  setShowOtpPopup
}: RegModalProps) => {
  const [containNumber, setContainNumber] = useState(false)
  const [containSpecial, setContainSpecial] = useState(false)
  const [containUppercase, setContainUppercase] = useState(false)
  const [containLowercase, setContainLowercase] = useState(false)
  const [minLengthSat, setMinLengthSat] = useState(false)
  const [passwordValidationError, setPasswordValidationError] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const VALIDATIONS = [
    "Uppercase",
    "Lowercase",
    "Special character",
    "At least 1 number",
    "Min. 8 Character"
  ]

  const checkPasswordError = () => {
    if (
      containNumber &&
      containSpecial &&
      containUppercase &&
      containLowercase &&
      minLengthSat
    ) {
      setPasswordValidationError(false)
    } else {
      setPasswordValidationError(true)
    }
  }
  const checkPassword = (str: string) => {
    checkPasswordError()
    setPassword(str)
    var uppercaseRe = /[A-Z]/
    var lowercaseRe = /[a-z]/
    var specialCharRe = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    var numberRe = /[0-9]/

    if (uppercaseRe.test(str)) {
      setContainUppercase(true)
    } else {
      setContainUppercase(false)
    }

    if (lowercaseRe.test(str)) {
      setContainLowercase(true)
    } else {
      setContainLowercase(false)
    }
    if (specialCharRe.test(str)) {
      setContainSpecial(true)
    } else {
      setContainSpecial(false)
    }

    if (numberRe.test(str)) {
      setContainNumber(true)
    } else {
      setContainNumber(false)
    }

    if (str.length >= 8) {
      setMinLengthSat(true)
    } else {
      setMinLengthSat(false)
    }

    checkPasswordError()
  }

  useEffect(() => {
    checkPasswordError()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    containNumber,
    containSpecial,
    containUppercase,
    containLowercase,
    minLengthSat
  ])

  const register = async () => {
    setLoading(true)
    try {
      // if (!isValidDate(dob)) {
      //   toast.error("Invalid Date Of Birth Format", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "light"
      //   })
      // }
      const res = await axios.post("users/signup", {
        email,
        password,
        phone_number,
        account_type,
        name,
        consentGiven
      })

      console.log(res.data)
      if (res.status === 203) {
        console.log("STATUS CODE 203")
        console.log(res.data, "DATA")
        setBvnUrl(res.data.url)
        warningToastMessage("Verify your BVN!")

        setVerifyBVN(true)
      } else {
        setShowModal(false)

        localStorage.setItem("@token", JSON.stringify(res.data.token))

        successToastMessage("Account Created!")
        setShowOtpPopup(true)
      }
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      errorToastMessage(error)
    }
  }
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        // ltr={true}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "password"
            ? "Let's secure your account"
            : modalType === "account"
            ? "Let's get to know you"
            : ""}
        </h4>
        <button
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img
            alt="close"
            src="/images/close-circle.svg"
            className="sm:h-[21.5px] sm:w-[21.5px] h-[15px] w-[15px]"
          />
        </button>
      </div>

      {modalType === "password" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <label className="sm:text-[20px] text-[16px] text-[#333] relative">
            Password
            <input
              value={password}
              onChange={(e) => {
                checkPassword(e.target.value)
              }}
              placeholder="Enter your password"
              className={`mt-[11px] sm:h-[50px]  h-[40px] sm:text-[16px] text-[14px] w-[100%] border-[1px] ${
                passwordValidationError ? "border-[red]" : "border-[#292D32]"
              } px-[26px] mb-[19px] rounded-[8.43px] outline-none`}
              type={showPassword ? "text" : "password"}
            />
            <button
              onClick={() => setShowPassword(!showPassword)}
              className="absolute top-[55%] right-[2%] flex items-center w-fit h-fit justify-center"
            >
              <img
                src="/images/eye-icon.svg"
                alt="show"
                className="w-[18px] "
              />
              {showPassword && (
                <div className="w-[2.5px] h-[20px] rotate-[30deg] bg-[#00000086]  absolute" />
              )}
            </button>
          </label>

          <p className="mt-[15px] text-gray-500"> Password must contain:</p>

          <ul className="list-disc p-3 px-[30px] text-gray-500 mb-[20px]">
            {VALIDATIONS.map((val, i) => (
              <li
                key={i}
                className={`px-[8px] ${
                  val === "Uppercase" && containUppercase
                    ? "text-[#D09600] font-[600] valid-li"
                    : val === "Lowercase" && containLowercase
                    ? "text-[#D09600] font-[600] valid-li"
                    : val === "Special character" && containSpecial
                    ? "text-[#D09600] font-[600] valid-li"
                    : val === "At least 1 number" && containNumber
                    ? "text-[#D09600] font-[600] valid-li"
                    : val === "Min. 8 Character" && password.length >= 8
                    ? "text-[#D09600] font-[600] valid-li"
                    : ""
                }`}
              >
                {" "}
                {val}
              </li>
            ))}
          </ul>
          <label className="sm:text-[20px] text-[16px] text-[#333] relative">
            Confirm password
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm your password"
              onFocus={() => setIsFocused(true)}
              className={` mt-[11px] sm:h-[50px] h-[40px] sm:text-[16px] text-[14px] w-[100%] border-[1px] ${
                isFocused && password !== confirmPassword
                  ? "border-[red]"
                  : "border-[#292D32]"
              } px-[26px] rounded-[8.43px] outline-none`}
              type={showConfirmPassword ? "text" : "password"}
            />
            <button
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="absolute top-[65%] right-[2%] flex items-center w-fit h-fit justify-center"
            >
              <img
                src="/images/eye-icon.svg"
                alt="show"
                className="w-[18px] "
              />
              {showConfirmPassword && (
                <div className="w-[2.5px] h-[20px] rotate-[30deg] bg-[#00000086]  absolute" />
              )}
            </button>
          </label>

          <button
            disabled={
              passwordValidationError ||
              password !== confirmPassword ||
              !password.length
            }
            onClick={() => setModalType("account")}
            className={`mt-[59px] ${
              passwordValidationError ||
              password !== confirmPassword ||
              !password.length
                ? "opacity-10"
                : "opacity-100"
            } rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[43px]`}
          >
            Continue
          </button>
        </div>
      ) : (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <label className="sm:text-[20px] text-[16px] text-[#333] w-[100%]">
            Account type
            <select
              required
              value={account_type}
              onChange={(e) => {
                setAccountType(e.target.value)
              }}
              className="w-[100%] mt-[11px] block outline-none px-[26px]  sm:h-[50px] h-[40px] border-[#292D32] border-[1px] rounded-[8.43px]"
            >
              <option value={""}>---Select Account Type---</option>
              <option value={"business"}>Loan Request</option>
              <option value={"partner"}>Partner</option>
              <option value={"investor"}> Investor</option>
            </select>
          </label>

          <button
            disabled={!account_type}
            onClick={
              register
              //       () => {
              //     //
            }
            className={`${
              !account_type ? "opacity-10" : "opacity-100"
            } mt-[59px] flex items-center rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[43px]`}
          >
            {loading && (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}

            {/* <img alt="loader" src="/images/loader.svg" />{" "} */}
            {loading ? "Creating..." : "Continue"}
          </button>
        </div>
      )}
    </motion.div>
  )
}

function Signup() {
  var preAction = localStorage.getItem("pre-action")

  if (preAction) {
    if (preAction === "Investment Marketplace") {
      preAction = "investor"
    } else {
      preAction = "business"
    }
  }

  const navigate = useNavigate()

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")

  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [bvn, setBvn] = useState("")
  const [dob, setDob] = useState("")
  const [password, setPassword] = useState("")
  const [phone_number, setPhoneNumber] = useState("")
  const [account_type, setAccountType] = useState(preAction ? preAction : "")
  const [hasAccepted, setHasAccepted] = useState(false)
  const [showOtpPopup, setShowOtpPopup] = useState(false)
  const [verifyBVN, setVerifyBVN] = useState(false)
  const [consentGiven, setConsentGiven] = useState(false)
  const [bvnUrl, setBvnUrl] = useState("https://services.vfdtech.ng/")
  const iframeRef = useRef<HTMLIFrameElement>(null)

  return (
    <div className="h-[100%]">
      <AuthHeader />
      <div className="flex px-[48px] h-[70%] flex-wrap">
        <div className="login-side sm:flex sm:item-center sm:justify-center hidden mr-[100px] rounded-[20px] flex-1 w-[100%] h-[100%]  hidden ">
          <img alt="icon" src="/images/login-image.svg" className="" />

          <div className="absolute top-[20%] left-[40%] rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px] h-[130px]">
            <h6 className="mb-[6px] text-[#292D32] text-[16px]">
              Loan Request
            </h6>

            <div className="px-[9px] mb-[11px] text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
            <div className="px-[9px]  text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
          </div>

          <div className="bottom-[15%] xl:left-[15%] left-[10%] absolute rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px]">
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Tenure</span>
              <span className="">3 months</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Amount</span>
              <span className="">₦50,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Interest</span>
              <span className="">₦1,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Total payback</span>
              <span className="">₦51,000</span>
            </div>

            <button className="mt-[27px] text-[11.6px] outline-none bg-[#D09600] text-[#fff] py-[9px] w-[100%] rounded-[5.8px]">
              Request loan
            </button>
          </div>
        </div>

        <form
          className="flex-1 flex-col flex"
          onSubmit={(e) => {
            e.preventDefault()
            setShowModal(true)
            setModalType("password")
          }}
        >
          <div className="mb-[59px]">
            <h4 className=" md:text-[48px] text-[24px] sm:text-left text-center font-[700]">
              Let’s get started
            </h4>
            <p className="md:text-[20px] text-[14px] sm:text-left text-center mt-[7px]">
              You may be eligible for N100m. Complete your application so we can
              review it.{" "}
            </p>
          </div>

          <label className="sm:text-[20px] text-[16px]">
            Enter full name
            <input
              value={name}
              placeholder="John Doe"
              required
              onChange={(e) => setName(e.target.value)}
              type={"text"}
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Enter email address
            <input
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              type={"email"}
              placeholder="name@mail.com"
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Enter phone number
            <input
              maxLength={11}
              required
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
              type={"tel"}
              placeholder="08100000000"
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>
          {/* <label className="sm:text-[20px] text-[16px]">
            Enter BVN
            <input
              maxLength={11}
              required
              value={bvn}
              placeholder="00000000000"
              onChange={(e) => setBvn(e.target.value)}
              className="sm:mb-[45px] mb-[30px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>

          <label className="sm:text-[20px] text-[16px]">
            Enter Date of Birth
            <input
              maxLength={20}
              required
              value={dob}
              placeholder="This is mandatory for an account Number to be Created e.g (04 October 1960)"
              onChange={(e) => setDob(e.target.value)}
              className="sm:mb-[45px] mb-[30px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label> */}

          <button
            type="button"
            onClick={() => setHasAccepted(!hasAccepted)}
            className="flex items-center w-[100%] sm:mb-[45px] mb-[30px]"
          >
            {hasAccepted ? (
              <img
                alt="check"
                src="/images/check.svg"
                className="mr-[6px] h-[20px] w-[20px]"
              />
            ) : (
              <div
                className={`h-[15px] w-[15px] border-[1px]
                rounded-[2px] border-[#292D32] mr-[6px]`}
              />
            )}
            <span className=" text-[12px] sm:text-[14px] leading-[16px]">
              {" "}
              By Clicking “Continue”, you have agreed to the{" "}
              <a href="/" className="text-[#D09600]">
                Terms and Conditions
              </a>{" "}
              of MonzoneCredit
            </span>
          </button>
          {/* <label className="text-[16px] check-label">
            <input
              onChange={(e) => {
                setHasAccepted(e.target.checked);
              }}
              type={"checkbox"}
              className="outline-none mr-[0px]"
            />
            <span className="mt-[-2px] text-[12px] sm:text-[14px] leading-[16px]">
              {" "}
              By Clicking “Continue”, you have agreed to the{" "}
              <a href="/" className="text-[#D09600]">
                Terms and Conditions
              </a>{" "}
              of MonzoneCredit
            </span>
          </label> */}
          <button
            disabled={
              !hasAccepted ||
              !name.length ||
              !email.length ||
              phone_number.length < 11
            }
            type="submit"
            className={`${
              hasAccepted &&
              email.length &&
              name.length &&
              phone_number.length === 11
                ? "opacity-100"
                : "opacity-10"
            } rounded-[8.43px] h-[62px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] text-[20px] text-[#fff]`}
          >
            Continue
          </button>

          <span className="text-center sm:text-[16px] text-[12px] mt-[19px] mb-[50px]">
            Already have an account?{" "}
            <Link to={"/auth/login"} className="text-[#D09600]">
              Login
            </Link>
          </span>
        </form>
      </div>

      {showOtpPopup && <OtpPopUp setShowPopup={setShowOtpPopup} />}

      {showModal && (
        <Modal>
          <RegModal
            consentGiven={consentGiven}
            setBvnUrl={setBvnUrl}
            setVerifyBVN={setVerifyBVN}
            bvn={bvn}
            dob={dob}
            setShowOtpPopup={setShowOtpPopup}
            name={name}
            modalType={modalType}
            setShowModal={setShowModal}
            setModalType={setModalType}
            navigate={navigate}
            password={password}
            phone_number={phone_number}
            email={email}
            setPassword={setPassword}
            account_type={account_type}
            setAccountType={setAccountType}
          />
        </Modal>
      )}

      {/* {verifyBVN &&
        <div >
          <Modal >

            <div className="bg-[#fff] w-[95%] h-[95%] relative">
              <button
                onClick={() => {
                  setVerifyBVN(false)
                  setConsentGiven(true)
                }}
                className="active:scale-75 transform-gpu absolute right-[10px] top-[10px]"
              >
                <img
                  alt="close"
                  src="/images/close-circle.svg"
                  className="sm:h-[21.5px] sm:w-[21.5px] h-[15px] w-[15px]"
                />
              </button>

              <iframe
                ref={iframeRef}
                title="Otp constent"
                src={bvnUrl}
                width="90%"
                height="90%"
              ></iframe>
            </div>
          </Modal>
        </div>
      } */}
    </div>
  )
}

export default Signup
