/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Sme from "./sme"
import Invoice from "./invoice"
import { getCUrrentUser } from "../../utils/get-current-user"
import { useDispatch, useSelector } from "react-redux"
import { updateUser } from "../../store/user-reducer"
import Lpo from "./lpo"
import axios from "axios"

export interface IUser {
  currentUser: {
    id: string
    name: string
    email: string
    phone_number: string
    account_type: string
    password: string
    profile_image: string
    doc_uploaded: boolean
  }
  credentials: {
    bank_statement: string
    bvn: number
    cac_certificate: string
    utility_bill: string
    company_name: string
    company_email: string
    company_phone: string
    id_card: string
  }
}

function Loan() {
  const currentUser = useSelector((state: any) => state.user.user)
  const navigate = useNavigate()
  const [currentTab, setCurrentTab] = useState("sme")

  const NoAccess = () => (
    <div className="flex flex-col items-center h-[100%]">
      <img
        src="/images/no-access.svg"
        className="mt-[47px]"
        alt="upload docs"
      />
      <p className="mt-[47px] leading-[42px] text-center sm:text-[24px] text-[18px] font-[500]">
        Oops! Nothing to see here, you’ve not completed your identity
        verification
      </p>

      <button
        onClick={() => {
          navigate("/onboarding/sme")
        }}
        className="active:bg-[#d095009c] mt-[34px] bg-[#D09600] sm:min-h-[56px] sm:h-[56px] h-[43px] rounded-[8.43px] w-[40%] text-[#fff] sm:text-[20px] text-[14px] font-[600] "
      >
        Verify
      </button>
    </div>
  )

  console.log(currentTab)

  return (
    <div className="h-[100%] w-[100%]">
      <div className="pt-[80px] px-[31px] sm:pt-[55px] sm:px-[55px] sm:mb-[-90px]">
        <h5 className="font-[500] sm:text-[24px] text-[16px]">Loan</h5>
        <div className=" max-[620px]:max-w-[calc(100vw-62px)] overflow-x-scroll w-[100%]">
          <div className="max-[620px]:w-fit  flex items-center sm:mb-[56px] overflow-x-scroll">
            <div
              onClick={() => setCurrentTab("sme")}
              className=" cursor-pointer sm:rounded-l-[20px] max-[620px]:rounded-[12px] bg flex-1 sm:py-[70px] py-[24px] flex flex-col items-center justify-center max-[620px]:w-[238px] max-[620px]:px-[50px]"
              style={{
                // backgroundColor:
                //   currentTab === "sme" ? "rgb(56 54 35)" : "#d09600",
                borderBottomColor: currentTab === "sme" ? "#553c00" : "none",
                borderBottomWidth: currentTab === "sme" ? "3px" : 0,
                borderBottomStyle: currentTab === "sme" ? "solid" : "none"
                // transition: ".2s ease-in-out"
              }}
            >
              <img
                alt="icon"
                src="/images/coin.svg"
                className="max-[620px]:w-[41.31px] max-[620px]:h-[41.31px]"
              />
              <span className="text-[#fff] sm:text-[20px] text-[14px] text-center">
                SME Loans
              </span>
            </div>
            <div
              onClick={() => setCurrentTab("lpo")}
              className=" cursor-pointer max-[620px]:mx-[15px] bg sm:flex-1 max-[620px]:rounded-[12px] sm:py-[70.5px] py-[24px] flex flex-col items-center justify-center max-[620px]:px-[10px] max-[620px]:w-[238px]"
              style={{
                // backgroundColor:
                //   currentTab === "lpo" ? "rgb(56 54 35)" : "#d09600",
                // transition: ".4s ease-in-out",
                borderBottomColor: currentTab === "lpo" ? "#553c00" : "none",
                borderBottomWidth: currentTab === "lpo" ? "3px" : 0,
                borderBottomStyle: currentTab === "lpo" ? "solid" : "none"
              }}
            >
              <img
                alt="icon"
                src="/images/receipt-minus.svg"
                className="max-[620px]:w-[41.31px] max-[620px]:h-[41.31px]"
              />
              <span className="text-[#fff] sm:text-[20px] text-[14px] text-center">
                Local purchase order
              </span>
            </div>
            <div
              onClick={() => setCurrentTab("invoice")}
              className="cursor-pointer sm:rounded-r-[20px] max-[620px]:rounded-[12px] bg sm:flex-1 sm:py-[70px] py-[24px] flex flex-col items-center justify-center max-[620px]:w-[238px]"
              style={{
                borderBottomColor:
                  currentTab === "invoice" ? "#553c00" : "none",
                borderBottomWidth: currentTab === "invoice" ? "3px" : 0,
                borderBottomStyle: currentTab === "invoice" ? "solid" : "none"
                // transition: ".4s ease-in-out"
              }}
            >
              <img
                alt="icon"
                src="/images/archive-book.svg"
                className="max-[620px]:w-[41.31px] max-[620px]:h-[41.31px]"
              />
              <span className="text-[#fff] sm:text-[20px] text-[14px]">
                Invoices
              </span>
            </div>
          </div>
        </div>
        <div className="sm:hidden flex items-center mb-[15px]">
          <img alt="scroll" src="/images/hand-scroll.svg" />
          <span className="ml-[12px] font-[500] text-[#292D32] text-[12px]">
            Swipe horizontal to see more details
          </span>
        </div>
      </div>

      {currentTab === "sme" && (
        <>{currentUser?.credentials !== null ? <Sme /> : <NoAccess />}</>
      )}
      {currentTab === "lpo" && (
        <>{currentUser?.credentials !== null ? <Lpo /> : <NoAccess />}</>
      )}
      {currentTab === "invoice" && (
        <>{currentUser?.credentials !== null ? <Invoice /> : <NoAccess />}</>
      )}
    </div>
  )
}

export default Loan
