import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import AuthHeader from "./auth-header";
import "./styles.css";
import { useSelector } from "react-redux";
import axios from "axios";
import { convertToBase64 } from "../../utils/base64-converter";
import { toast } from "react-toastify";
import { IUser } from "../business-dashboard/loan";
import { uploadImage } from "../../utils/upload-to-cloudinary";

interface TabsInterface {
  label: string;
  details: string;
}

function SmeSetup() {
  const user: IUser = useSelector((state: any) => state.user.user);

  const [name, setName] = useState(user?.currentUser?.name);
  const [bvn, setBvn] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [bankStatement, setBankStatement] = useState<any>();
  const [cac, setCac] = useState<any>();
  const [idCard, setIdCard] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [sameAsEmail, setSameAsEmail] = useState(false);
  const [sameAsPhone, setSameAsPhone] = useState(false);
  const TABS: TabsInterface[] = [
    {
      label: "Personal Information",
      details: "Please enter your personal details",
    },
    {
      label: "Company Information",
      details: "Please enter your company information",
    },
    {
      label: "Business Document",
      details: "Please upload the loan documents",
    },
  ];
  const navigate = useNavigate();

  const upload = async () => {
    setLoading(true);
    try {
      let cac_certificate = await uploadImage(cac);
      let bank_statement = await uploadImage(bankStatement);
      let id_card = await uploadImage(idCard);

      console.log(
        cac_certificate,
        bank_statement,
        id_card,
        bvn,
        "CLOUDINARY URL"
      );
      if (cac_certificate && bank_statement && id_card && bvn.length === 10) {
        const response = await axios.post("user-loans/upload", {
          bvn,
          company_name: companyName,
          company_phone: companyPhone,
          company_website: companyWebsite,
          company_email: companyEmail,
          name,
          cac_certificate,
          bank_statement,
          id_card,
        });

        toast.success(response.data.message, {
          position: "top-right",

          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        navigate(-1);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      let errorMessage = error?.response?.data?.errors[0];
      console.log(errorMessage);
      toast.error(errorMessage ? errorMessage.message : "An Error Occurred!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const [currentTab, setCurrentTab] = useState<TabsInterface>(TABS[0]);

  function getAllBefore() {
    let current: TabsInterface = TABS[0];

    for (const tab of TABS) {
      if (tab.label.toLowerCase() === currentTab.label.toLowerCase()) {
        current = tab;
      }
    }

    var i = TABS.indexOf(current);
    const result: any = i > -1 ? TABS.slice(0, i) : [];
    return result;
  }

  const MAX_FILE_SIZE = 1048576 * 5;
  return (
    <div className="h-[100%] overflow-y-scroll pt-[70px] sm:px-[94px] px-[24px] flex flex-col text-[#292D32]">
      <div className="flex items-center justify-between">
        {TABS.map((tab, index) => (
          <div
            key={index}
            className={`${index > 0 ? "ml-[73px]" : ""} sm:block hidden`}
          >
            <h5 className="font-[600] text-[24px] leading-[30px] text-center">
              {tab.label}
            </h5>
            <p className="sm:text-[20px] leading-[22px] text-center text-[#333333] mt-[12px]">
              {tab.details}
            </p>
            <div
              className={`w-[100%] h-[7px] rounded-[4px] mt-[30px] ${
                currentTab.label === tab.label || getAllBefore().includes(tab)
                  ? "bg-[#D09600]"
                  : "bg-[#292D32]"
              }`}
            />
          </div>
        ))}
      </div>

      <div className="sm:hidden flex items-center justify-between mt-[34px]">
        {TABS.map((tab, index) => (
          <div
            key={index}
            className={`${
              index > 0 ? "ml-[13px]" : ""
            } w-[30%] h-[5px] rounded-[4px] mt-[30px] ${
              currentTab.label === tab.label || getAllBefore().includes(tab)
                ? "bg-[#D09600]"
                : "bg-[#292D32]"
            }`}
          />
        ))}
      </div>

      {currentTab.label.toLowerCase() === TABS[0].label.toLowerCase() ? (
        <div className="sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] py-[76px] sm:px-[46px] px-[0px] self-center">
          <h4 className="sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]">
            {currentTab.label}{" "}
          </h4>
          <p className="text-center mb-[80px] max-[620px]:text-[14px]">
            {"Kindly fill in the following information to proceed."}
          </p>

          <label className="sm:text-[20px] text-[16px]">
            Name
            <input
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              type={"text"}
              placeholder="What is your personal name?"
              className="mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Email
            <input
              disabled
              type={"email"}
              value={user?.currentUser?.email}
              placeholder="Enter your email address"
              className="mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            BVN
            <input
              value={bvn}
              onChange={(e) => {
                setBvn(e.target.value);
              }}
              required
              maxLength={10}
              type={"text"}
              placeholder="Enter your BVN here"
              className="mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
            />
          </label>

          <div className="flex items-center justify-between mt-[21px]">
            <button
              disabled={true}
              onClick={() => {
                console.log("OK");
              }}
              type="submit"
              className="opacity-10 mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32]"
            >
              Back
            </button>
            <button
              disabled={!bvn || !name}
              onClick={() => {
                setCurrentTab(TABS[1]);
              }}
              type="submit"
              className={`${
                !bvn || !name ? "opacity-10" : "opacity-100"
              } rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff]`}
            >
              Continue
            </button>
          </div>
        </div>
      ) : currentTab.label.toLowerCase() === TABS[1].label.toLowerCase() ? (
        <div className="sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] py-[76px] sm:px-[46px] px-[0px] self-center">
          <h4 className="sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]">
            {currentTab.label}{" "}
          </h4>
          <p className="text-center mb-[80px] max-[620px]:text-[14px]">
            {"Kindly fill in the following information to proceed."}
          </p>

          <label className="sm:text-[20px] text-[16px]">
            Company's name
            <input
              value={companyName}
              type={"text"}
              onChange={(e) => setCompanyName(e.target.value)}
              placeholder="What is your company name?"
              className="mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Company's email
            <input
              value={companyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
              type={"email"}
              placeholder="Enter your email address"
              className="mb-[10px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
            />
            <div className="flex items-center mb-[37px]">
              <button
                onClick={() => {
                  setCompanyEmail(user.currentUser.email);
                  setSameAsEmail(!sameAsEmail);
                }}
                className={`w-[20px] h-[20px] border-[1px] border-[#D09600] ${
                  sameAsEmail ? "bg-[#D09600]" : ""
                } rounded-[3px]`}
              />
              <p className="ml-[13px] max-[620px]:text-[12px] text-[14px]">
                Same as user's email?
              </p>
            </div>
          </label>
          <div className="flex sm:flex-row flex-col sm:items-center">
            <label className="sm:text-[20px] text-[16px] flex-1">
              Company's phone number
              <div className="flex items-center sm:border-[1px] border-[0.5px] border-[#292D32] rounded-[5px] h-[40px] sm:h-[55px] mb-[10px] mt-[9px]">
                <span className="h-[40px] sm:h-[55px] border-r-[1px] border-r-[#292D3280]  flex items-center px-[10px] max-[620px]:text-[14px]">
                  +234
                </span>
                <input
                  value={companyPhone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  type={"tel"}
                  maxLength={10}
                  placeholder="81 0100 0000"
                  className=" outline-none   sm:h-[53px] h-[38px] w-[86%]  pl-[26px] max-[620px]:text-[14px] "
                />
              </div>
              <div className="flex items-center">
                <button
                  onClick={() => {
                    setCompanyPhone(user.currentUser.phone_number);
                    setSameAsPhone(!sameAsPhone);
                  }}
                  className={`w-[20px] h-[20px] border-[1px] border-[#D09600] ${
                    sameAsPhone ? "bg-[#D09600]" : ""
                  } rounded-[3px]`}
                />
                <p className="ml-[13px] max-[620px]:text-[12px] text-[14px]">
                  Same as user's phone number?
                </p>
              </div>
            </label>
            <label className="sm:text-[20px] text-[16px] sm:ml-[24px] flex-1">
              Company's website (optional)
              <input
                value={companyWebsite}
                onChange={(v) => setCompanyWebsite(v.target.value)}
                type={"text"}
                placeholder="Enter Website URL here"
                className="mb-[37px] outline-none mt-[9px] h-[40px] sm:h-[55px] w-[100%] rounded-[5px] px-[26px] sm:border-[1px] border-[0.5px] border-[#292D32] max-[620px]:text-[14px]"
              />
            </label>
          </div>
          <div className="flex items-center justify-between mt-[21px]">
            <button
              onClick={() => {
                setCurrentTab(TABS[0]);
              }}
              type="submit"
              className={`mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32] `}
            >
              Back
            </button>
            <button
              disabled={!companyEmail || !companyName || !companyPhone}
              onClick={() => {
                setCurrentTab(TABS[2]);
              }}
              type="submit"
              className={`rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[42px] active:bg-[#d09500c1] ${
                !companyEmail || !companyName || !companyPhone
                  ? "opacity-10 cursor-error"
                  : "opacity-100"
              } bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff]`}
            >
              Continue
            </button>
          </div>
        </div>
      ) : (
        <div className="sm:w-[891px] sm:w-[90%] sm:mt-[102px] bg-[#fff] w-[100%] py-[76px] sm:px-[46px] px-[0px] self-center">
          <h4 className="sm:text-[36px] text-center text-[24px] font-[600] mb-[9px]">
            {currentTab.label}{" "}
          </h4>
          <p className="text-center mb-[80px] max-[620px]:text-[14px]">
            {"Kindly upload the following documents to proceed."}
          </p>

          <label className="sm:text-[20px] text-[16px] w-[100%]">
            CAC certificate of incorporation
            <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
              <input
                type={"file"}
                className="hidden"
                accept="application/pdf, image/*"
                id="upload"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > MAX_FILE_SIZE) {
                      toast.error("File size cannot be greater than 5mb", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                      return;
                    }
                    setCac(e.target.files[0]);
                  }
                }}
              />
              <label
                className={`${
                  cac?.name ? "" : "text-gray-300"
                } text-[18px] active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]`}
                htmlFor="upload"
              >
                {cac?.name ? cac.name : "Click here to choose file"}
              </label>
            </div>
          </label>
          <label className="sm:text-[20px] text-[16px]">
            A year bank statement
            <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
              <input
                type={"file"}
                className="hidden"
                accept="application/pdf, image/*"
                id="upload-bank"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > MAX_FILE_SIZE) {
                      toast.error("File size cannot be greater than 5mb", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });

                      return;
                    }

                    setBankStatement(e.target.files[0]);
                  }
                }}
              />
              <label
                htmlFor="upload-bank"
                className={`text-[18px] ${
                  bankStatement?.name ? "" : "text-gray-300"
                } active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]`}
              >
                {bankStatement?.name
                  ? bankStatement.name
                  : "Click here to choose a file"}
              </label>
            </div>
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Valid ID card
            <div className="sm:text-[20px] text-[16px] text-[#333] mb-[30px]">
              <input
                type={"file"}
                className="hidden"
                accept="application/pdf, image/*"
                id="upload-id"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > MAX_FILE_SIZE) {
                      toast.error("File size cannot be greater than 5mb", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });

                      return;
                    }

                    setIdCard(e.target.files[0]);
                  }
                }}
              />
              <label
                className={`${
                  idCard?.name ? "" : "text-gray-300"
                } text-[18px] active:bg-[#f5f5f5] flex items-center text-[#333333] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] sm:border-[1px] border-[0.5px] rounded-[5px]`}
                htmlFor="upload-id"
              >
                {idCard?.name ? idCard.name : "Click here to choose a file"}
              </label>
            </div>
          </label>

          <div className="flex items-center justify-between sm:mt-[58px] mt-[30px]">
            <button
              disabled={loading}
              onClick={() => {
                setCurrentTab(TABS[1]);
              }}
              type="submit"
              className="mr-[23px] rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[43px] active:bg-[#ffffffc1] bg-[#FFF] w-[100%] sm:text-[20px] text-[16px] sm:border-[1px] border-[0.5px] border-[#292D32]"
            >
              Back
            </button>
            <button
              disabled={!idCard || !bankStatement || !cac || loading}
              onClick={() => upload()}
              type="submit"
              className={`justify-center flex items-center rounded-[8.43px] sm:min-h-[56px] sm:h-[56px] h-[43px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[16px] text-[#fff] ${
                !idCard || !bankStatement || !cac
                  ? "opacity-10 cursor-error"
                  : "opacity-100"
              }`}
            >
              {loading && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              {loading ? "Processing..." : "Continue"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default SmeSetup;
