import React, { useEffect, useState } from "react";
import Loader from "../../components/Loader";
import { ILoan } from "../business-dashboard/sme";
import axios from "axios";

function Dashboard() {
  const [loans, setLoans] = useState<ILoan[]>([]);
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState<{
    investment_amount: number;
    investment_number: number;
    total_loan: number;
  }>({ investment_amount: 0, investment_number: 0, total_loan: 0 });

  const getInvestments = async () => {
    setLoading(true);
    try {
      const res = await axios.get(`user-loans/investments/all`);
      console.log(res.data);
      setLoans(res.data.loans);
      setInvestment(res.data.investment);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getInvestments();

    return () => {
      controller.abort();
    };
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="h-[100%] w-[100%] pt-[80px] px-[31px] sm:pt-[55px] sm:px-[55px] sm:p-[55px] bg-[#F9F9F9]">
      <h5 className="font-[500] text-[24px] mb-[25px] sm:block hidden">
        Welcome back!
      </h5>

      <div className=" max-[620px]:max-w-[calc(100vw-62px)] overflow-x-scroll w-[100%]">
        <div className="w-[100%] flex items-center mb-[56px]">
          <div className="flex-1 flex items-center justify-center flex-col text-[#292D32] bg-[#FFF] rounded-[10px] sm:h-[150px] h-[119px] max-[620px]:min-w-[250px] sm:px-[44px]">
            <span className="sm:text-[16px] text-[12px] font-[700] mb-[13px] text-center">
              Total loan financed
            </span>
            <h4 className="sm:text-[32px] text-[24px] font-[700] text-center">
              {investment.investment_number}
            </h4>
          </div>
          {/* <div className="flex-1  ml-[20px] flex items-center justify-center flex-col text-[#292D32] bg-[#FFF] rounded-[10px] sm:h-[150px] h-[119px] max-[620px]:min-w-[250px] sm:px-[44px]">
            <span className="sm:text-[16px] text-[12px] font-[700] mb-[13px] text-center">
              Total loan grant
            </span>
            <h4 className="sm:text-[32px] text-[24px] font-[700] text-center">
              500
            </h4>
          </div> */}
          {/* <div className="flex-1  ml-[20px] flex items-center justify-center flex-col text-[#292D32] bg-[#FFF] rounded-[10px] sm:h-[150px] h-[119px] max-[620px]:min-w-[250px] sm:px-[44px]">
            <span className="sm:text-[16px] text-[12px] font-[700] mb-[13px] text-center">
              Total loan request
            </span>
            <h4 className="sm:text-[32px] text-[24px] font-[700] text-center">
              700
            </h4>
          </div> */}
          <div className="flex-1  ml-[20px] flex items-center justify-center flex-col text-[#292D32] bg-[#FFF] rounded-[10px] sm:h-[150px] h-[119px] max-[620px]:min-w-[250px] sm:px-[44px]">
            <span className="sm:text-[16px] text-[12px] font-[700] mb-[13px] text-center">
              Total loan
            </span>
            <h4 className="sm:text-[32px] text-[24px] font-[700] text-center">
              {investment.total_loan}
            </h4>
          </div>
        </div>
      </div>

      <div className="sm:hidden flex items-center mb-[15px] mt-[-35px]">
        <img alt="scroll" src="/images/hand-scroll.svg" />
        <span className="ml-[12px] font-[500] text-[#292D32] text-[12px]">
          Swipe horizontal to see more details
        </span>
      </div>

      <div className=" justify-between flex items-center max-[620px]:max-w-[calc(100vw-62px)]">
        <h4 className="sm:text-[24px] text-[16px] font-[500]">
          Investment history
        </h4>

        <div className="flex items-center">
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            Show
          </span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#D9D9D900] sm:text-[14px] text-[10px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block w-[88.14px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            List
          </span>
        </div>
      </div>
      <div className=" overflow-x-auto mt-[12px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)]">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Transaction Code
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Interest rate
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Option
              </th> */}
            </tr>
          </thead>
          <tbody>
            {loans.map((loan, index) => (
              <tr
                className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                key={index}
              >
                <td className="px-6 py-4">{loan.id}</td>
                <td className="px-6 py-4">{loan.user.name}</td>

                <td className="px-6 py-4">{loan.amount_granted}</td>

                <td className="px-6 py-4">{loan.interest_rate}%</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* <div className='flex items-center justify-between mt-[28px]'>
                    <span className='text-[16px] text-[#000000A1]'>Showing 1-30 of list</span>

                    <div className='flex items-center '>
                        <button className='bg-[#D09600] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                            <img src='/images/caret-back.svg' alt='icon' />
                        </button>
                        {
                            [1, 2, 3, 4].map((val, index) => (
                                <button key={index} className='hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                                    {val}
                                </button>

                            ))
                        }

                        <button className='bg-[#D09600] mr-[10px] w-[31.13px] h-[31.13px] flex items-center justify-center rounded-[3.46px]'>
                            <img src='/images/caret-right.svg' alt='icon' />
                        </button>

                    </div>
                </div> */}
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="sm:text-[16px] text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] sm:text-[14px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default Dashboard;
