import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

// import {
//   errorToastMessage,
//   successToastMessage
// } from "../../utils/toast-message"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

interface MarketProps {
  setSelectedLoan?: (data?: any) => void
  setShowLoanDetails?: (data?: any) => void
}
function Products({ setSelectedLoan, setShowLoanDetails }: MarketProps) {
  const navigate = useNavigate()

  const [current, setCurrent] = useState(0)
  const slides = [
    { id: 1, text: "Card 1" },
    { id: 2, text: "Card 2" },
    { id: 3, text: "Card 3" }
  ]

  const nextSlide = () => {
    setCurrent(current === slides.length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? slides.length - 1 : current - 1)
  }

  useEffect(() => {
    const controller = new AbortController()

    return () => {
      controller.abort()
    }
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <></>,
    prevArrow: <></>,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear"
  }

  return (
    // <div className="box-style sm:mt-[100px] mt-[59px] sm:px-[68px] sm:py-[40px] px-[20px] py-[20px] bg-[#faf5e6] rounded-[15px] mx-[20px]">
    <>
      <p
        className="heading-products"
        style={
          {
            // fontSize: "25px",
            // textAlign: "center",
            // letterSpacing: "1.2px",
            // padding: "20px",
            // paddingBottom: "20px",
            // fontWeight: "bold"
          }
        }
      >
        Products that meet your needs
      </p>

      <div className="container-b">
        <Slider {...settings}>
          {/* <div className="box-b">
          <div className="title-box-text">
            <h2 className="title-b">Investors</h2>
            <p style={{ paddingRight: "10px" }}>
              With our investors account type we give you an opportunity to
              invest your capital wisely in a market place.
            </p>
          </div>
          <div style={{ margin: "auto" }}>
            <img
              src="/images/shake.jpg"
              alt="logo"
              // className="sm:min-w-[152.34px] sm:min-h-[152px] max-[600px]:w-[157.74px] max-[600px]:h-[43.11px]"
              className="img-product"
              style={{
                //   width: "440px",
                //   //   height: "250px",
                borderRadius: "15px"
                // margin: 0
              }}
            />
          </div>
        </div>
        <div className="box-b">
          <div className="title-box-text">
            <h2 className="title-b">Loan Requests</h2>
            <p style={{ paddingRight: "10px" }}>
              Welcome to our loan request acc! <br />
              This account type is tailored for individuals and small businesses
              seeking financial fundings for their businesses.
            </p>
          </div>
          <div style={{ margin: "auto" }}>
            <img
              src="/images/loan.jpg"
              className="img-product"
              alt="logo"
              // className="sm:min-w-[152.34px] sm:min-h-[152px] max-[600px]:w-[157.74px] max-[600px]:h-[43.11px]"
              style={{
                //   width: "440px",
                //   //   height: "250px",
                borderRadius: "15px"
                // margin: 0
              }}
            />
          </div>
        </div>
        <div className="box-b">
          <div className="title-box-text">
            <h2 className="title-b">Partners</h2>
            <p style={{ paddingRight: "10px" }}>
              With the partner account with us, you have access to collaborating
              with us and also access to partnership loans.
            </p>
          </div>
          <div style={{ margin: "auto" }}>
            <img
              src="/images/partner.jpg"
              className="img-product"
              alt="logo"
              // className="sm:min-w-[152.34px] sm:min-h-[152px] max-[600px]:w-[157.74px] max-[600px]:h-[43.11px]"
              style={{
                //   width: "440px",
                //   //   height: "250px",
                borderRadius: "15px"
                // margin: 0
              }}
            />
          </div>
        </div> */}

          <div className="parent-slider">
            <div className="Testimonials-module--Testimonials_main--TbGSH">
              <div className="Testimonials-module--Testimonials_content--rWHU4">
                <h3 className="Testimonials-module--Testimonials_content_title--CSTvX heading">
                  Investors
                </h3>
                <p className="Testimonials-module--Testimonials_content_title--CSTvX">
                  {" "}
                  With our investors account type we give you an opportunity to
                  invest your capital wisely in a market place.
                </p>
                <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
                </div>
              </div>

              <div className="Testimonials-module--Testimonials_image--Vxscn">
                <img src="/images/shake.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="parent-slider">
            <div className="Testimonials-module--Testimonials_main--TbGSH">
              <div className="Testimonials-module--Testimonials_content--rWHU4">
                <h3 className="Testimonials-module--Testimonials_content_title--CSTvX heading">
                  Loan Requests
                </h3>
                <h3 className="Testimonials-module--Testimonials_content_title--CSTvX">
                  {" "}
                  Welcome to our loan request account! <br />
                  This account type is tailored for individuals and small
                  businesses seeking financial fundings for their businesses.
                </h3>
                <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
                </div>
              </div>

              <div className="Testimonials-module--Testimonials_image--Vxscn">
                <img src="/images/loan.jpg" alt="" />
              </div>
            </div>
          </div>

          <div className="parent-slider">
            <div className="Testimonials-module--Testimonials_main--TbGSH">
              <div className="Testimonials-module--Testimonials_content--rWHU4">
                <h3 className="Testimonials-module--Testimonials_content_title--CSTvX heading">
                  Partners
                </h3>
                <h3 className="Testimonials-module--Testimonials_content_title--CSTvX">
                  {" "}
                  With the partner account with us, you have access to
                  collaborating with us and also access to partnership loans.
                </h3>
                <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
                  <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
                </div>
              </div>

              <div className="Testimonials-module--Testimonials_image--Vxscn">
                <img src="/images/partner.jpg" alt="" />
              </div>
            </div>
          </div>

          {/* <div className="parent-slider">
        <div className="Testimonials-module--Testimonials_main--TbGSH">
          <div className="Testimonials-module--Testimonials_content--rWHU4">
            <h3 className="Testimonials-module--Testimonials_content_title--CSTvX">
              Brass, with the sub-accounts feature, makes it easy to segment our
              cash and in turn optimise it for targeted goals
            </h3>
            <p>Feranmi Ajetunmobi, Ni-Fries</p>
            <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
              <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
              <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
              <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
            </div>
          </div>
          <div className="Testimonials-module--Testimonials_image--Vxscn">
            <img src="/images/shake.jpg" alt="Feranmi Ajetunmobi, Ni-Fries" />
          </div>
        </div>
      </div> */}

          {/* <div className="Testimonials-module--Testimonials_main--TbGSH">
        <div className="Testimonials-module--Testimonials_content--rWHU4">
          <h3 className="Testimonials-module--Testimonials_content_title--CSTvX">
            Brass, with the sub-accounts feature, makes it easy to segment our
            cash and in turn optimise it for targeted goals
          </h3>
          <p>Feranmi Ajetunmobi, Ni-Fries</p>
          <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
          </div>
        </div>
        <div className="Testimonials-module--Testimonials_image--Vxscn">
          <img src="/images/shake.jpg" alt="Feranmi Ajetunmobi, Ni-Fries" />
        </div>
      </div> */}
        </Slider>
      </div>
    </>
    // <div className="box-style sm:mt-[100px] mt-[59px] sm:px-[68px] sm:py-[76px] px-[30px] py-[40px] bg-[#faf5e6] rounded-[15px] mx-[20px]">
    //   <p>This is me </p>
    // </div>
  )
}

export default Products
