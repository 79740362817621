import React, { useCallback, useEffect, useState } from "react";
import AuthHeader from "../auth/auth-header";
import "./style.css";
import { Footer } from "./landing-home";
import { Link, useNavigate } from "react-router-dom";
import { errorToastMessage, successToastMessage } from "../../utils/toast-message";
import axios from "axios";
import Moment from "react-moment";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";


interface DeleteModalProps {
  onDelete: () => void;
  onClose: () => void;
  isDeleting: boolean;
}
const DeleteModal = ({ onClose, onDelete, isDeleting }: DeleteModalProps) => (
  <div className="z-[500000000000000000] flex items-center justify-center fixed top-[0] left-[0] right-[0] bottom-[0] h-[100%] w-[100%] bg-[#00000090]">
    <div className=" bg-[#fff] p-[20px] rounded-sm">
      <p className="text-[16px] font-[500]">Are you sure you want to delete this blog?</p>
      <div className="flex items-center mt-[20px] justify-end">
        <button disabled={isDeleting} onClick={onDelete} className="active:opacity-30 mr-[20px] h-[45px] text-[#fff] bg-[#ff6a6a] px-[10px] rounded-[5px]">{isDeleting ? "Deleting..." : "Delete"}</button>
        <button disabled={isDeleting} onClick={onClose} className="active:opacity-30 mr-[20px] h-[45px] text-[#fff] bg-[#D09600] px-[10px] rounded-[5px]">Just Kidding</button>
      </div>
    </div>
  </div>
)

interface IBlogCardProps {
  authorId: string;
  image: string;
  slug: string;
  title: string;
  description: string;
  author: string;
  date: string;
  setShowDeleteModal: (val: boolean) => void;
  setFlaggedBlog: (blog: string) => void;
}

function BlogCard({
  authorId,
  image,
  slug,
  title,
  description,
  author,
  date,
  setShowDeleteModal,
  setFlaggedBlog
}: IBlogCardProps) {
  const user = useSelector((state: any) => state.user?.user?.currentUser)



  const navigate = useNavigate()

  return (
    <div className="w-[100%] bg-[#fff] blog-card rounded-[8px]">
      {(user?.account_type === 'admin' || authorId === user?.id) && <div className="z-[1000] flex items-center justify-end">
        <button
          onClick={() => navigate(`/blog/edit/${slug}`)}
        >
          <img alt="edit" src="/images/edit-loan.svg" />
        </button>
        <button className="ml-[10px]"
          onClick={() => {
            setShowDeleteModal(true)
            setFlaggedBlog(slug)
          }}

        >
          <img alt="del" src="/images/trash.svg" />

        </button>

      </div>}
      <Link
        to={"/blog/" + slug}
        className="w-[100%] bg-[#fff] blog-card rounded-[8px]"
      >
        <img alt={title} src={image} className="w-[100%] rounded-t-[8px]" />
        <div className="mt-[27px] p-[2%] px-[3%]">
          <h4 className="sm:text-[24px] text-[18px] mb-[13px] font-[600] sm:leading-[36px] leading-[30px]">
            {title}
          </h4>
          <p className="blog-desc sm:text-[14px] text-[12px] sm:leading-[28px] leading-[26px] line-clamp-3">
            {description}
          </p>

          <div className="flex items-center mt-[15px]">
            <p className="font-[600] sm:text-[14px] text-[12px] mr-[5px]">
              {author} {"   "}{" "}
            </p>{" "}
            |{" "}
            <p className="ml-[5px] sm:text-[14px] text-[12px]">
              <Moment format="DD MMM, YYYY">{date}</Moment>
            </p>
          </div>
        </div>
      </Link>


    </div>
  );
}

export interface IBlog {
  title: string;
  coverImage: string;
  description: string;
  details: string;
  slug: string;
  author: {
    name: string;
    id: string;
  };
  createdAt: string;
}

function Blog() {
  const CATEGORIES = ["All", "Top", "New"];
  const [currentCategory, setCurrentCategory] = useState(CATEGORIES[0]);
  const [searchQuery, setSearchQuery] = useState("")
  const [blogs, setBlogs] = useState<IBlog[]>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [flaggedBlog, setFlaggedBlog] = useState('')

  const onDelete = async () => {
    setIsDeleting(true)

    try {
      const response = await axios.delete(`blogs/${flaggedBlog}`)

      successToastMessage(response.data.message);
      setShowDeleteModal(false)
      setIsDeleting(false)
      getBlogs()
    } catch (error) {
      setIsDeleting(false)
      console.log(error, "ERROR DELETING")
      errorToastMessage(error)
    }
  }



  const getBlogs = useCallback(async (searchQuery?: string) => {


    setPageLoading(true);
    try {
      const response = await axios.get(`blogs?q=${searchQuery ? searchQuery : ""}&category=${currentCategory !== "All" ? currentCategory : ""}`);

      setBlogs(response.data.blogs);
      setPageLoading(false);
    } catch (error) {
      errorToastMessage(error);
      setPageLoading(false);
    }
  }, [currentCategory]);

  useEffect(() => {
    getBlogs();
  }, [getBlogs]);

  if (pageLoading) {
    return <Loader />;
  }


  return (
    <div className="bg-[#fff] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] ">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
        <div className="sm:px-[78px] overflow-y-hidden overflow-x-hidden px-[20px] pb-[50px] sm:py-[100px] py-[10px]  flex flex-wrap max-[600px]:flex-col ">
          <div className="flex-1 sm:max-w-[40%] w-[95%] z-[100] ">
            <h2 className="sm:leading-[68px] font-[600] sm:text-[64px] text-[40px] text-[#fff]">
              Blog
            </h2>
            <p className="my-[29px]  sm:text-[16px] text-[13px] text-[#fff] ">
              Get the best and latest information about financing and loan.{" "}
            </p>

            <div className=" sm:px-[30px] sm:py-[15px] px-[23px] py-[12px]" />

            <div className=" flex items-center pl-[4px] bg-[#fff] rounded-[7.15px] w-[100%]">
              <input
                className="px-[10px] outline-none h-[40.07px] bg-[#fff] w-[100%]"
                placeholder="Enter email"
              />

              <button className=" bg-[#D09600] text-[#fff] text-[12.2px] h-[40.07px] px-[14px] rounded-r-[7.15px]">
                Subscribe
              </button>
            </div>
          </div>

          {/* <img alt='bg' src='/images/bg-image.svg' className='xl:max-w-[60vw] sm:block flex-1 sm:mt-[0]  min-w-[200px] max-[600px]:w-[100%] max-[600px]:h-[auto]  object-cover ' /> */}
        </div>
      </div>

      <div className="bg-[#fff] py-[10%] px-[3%]">
        <div className="items-center flex border-b-[1px] ">
          {CATEGORIES.map((category, index) => (
            <button
              onClick={() => setCurrentCategory(category)}
              key={index}
              className={`mr-[10px] sm:text-[20px] text-[16px] font-[500] min-w-[60px] sm:min-w-[100px] sm:pb-[21px] pb-[13px]
               ${currentCategory === category
                  ? "font-[600] border-b-[2px] border-b-[#D09600] text-[#D09600]"
                  : ""
                }`}
            >
              {category}
            </button>
          ))}

          <form
            onSubmit={(e) => {
              e.preventDefault();
              getBlogs(searchQuery)


            }}
            className=" sm:pb-[21px] pb-[13px] ml-[auto] flex items-center">
            <input
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search"
              className=" text-[16px] sm:text-[20px]  outline-none border-[0]"
            />
            <img alt="search" src="/images/search-icon.svg" />
          </form>
        </div>
      </div>

      <div className="mb-[250px] grid sm:grid-cols-3 grid-cols-1 gap-[112px] px-[3%]">
        {blogs.map((blog, index) => (
          <BlogCard
            setFlaggedBlog={setFlaggedBlog}
            setShowDeleteModal={setShowDeleteModal}
            key={index}
            slug={blog.slug}
            image={blog.coverImage}
            title={blog.title}
            description={blog.description}
            authorId={blog.author.id}
            author={blog.author.name}
            date={blog.createdAt}
          />
        ))}
      </div>

      {showDeleteModal && <DeleteModal onClose={() => setShowDeleteModal(false)} onDelete={onDelete} isDeleting={isDeleting} />}

      <Footer />
    </div>
  );
}

export default Blog;
