import axios from "axios";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./style.css";
import {
  errorToastMessage,
  successToastMessage,
} from "../../utils/toast-message";
import { uploadImage } from "../../utils/upload-to-cloudinary";
import AuthHeader from "../auth/auth-header";

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],

  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

function CreateBlog() {
  const [details, setDetails] = useState("");
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState("");

  const [image, setImage] = useState("");
  const onSubmit = async () => {
    setLoading(true);

    const coverImage = image
      ? await uploadImage(image)
      : "https://res.cloudinary.com/diils/image/upload/v1688132726/overcomers/llbn47klgpjmqe30cvl9.jpg";
    try {
      const response = await axios.post("blogs", {
        title,
        description,
        details,
        coverImage,
      });
      successToastMessage(response.data.message);
      setLoading(false);

      setTitle("");
      setDescription("");
      setDetails("");
      setTimeout(() => {
        window.location.reload();
      }, 4000);
    } catch (error) {
      console.log(error);
      errorToastMessage(error);
      setLoading(false);
    }
  };

  const onChange = (content: any, delta: any, source: any, editor: any) => {
    setDetails(content);
  };

  return (
    <div className="bg-[#fff] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] ">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
      </div>

      <div className="p-[20px] bg-[white] flex justify-center relative pt-[30px]">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          className="flex flex-col max-w-[600px] sm:min-w-[600px] min-w-full"
        >
          <div className="mt-4">
            <label htmlFor="title" className="block text-[#1a335b]">
              Title <span className="text-[red]">*</span>
            </label>
            <input
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
              type="text"
              id="title"
              className="mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500
          "
            />
          </div>

          <div className="mt-4">
            <label htmlFor="description" className="block text-[#1a335b]">
              Description <span className="text-[red]">*</span>
            </label>
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              required
              id="description"
              className="h-[100px] mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400
              focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-sky-500
              disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none
              focus:invalid:border-pink-500 focus:invalid:ring-pink-500
          "
            />
          </div>

          <div className="mt-4 mb-4">
            <label htmlFor="image" className="text-[#1a335b] block">
              Cover Image
            </label>

            {/* <img id="uploadedimage" src="" className="mb-[10px] max-h-[200px]" /> */}

            {/* {!loading && <UploadWidget />} */}
            <input
              onChange={(e: any) => {
                setImage(e.target.files[0]);
              }}
              id="image"
              type="file"
              className="mt-1 block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:rounded-full file:border-0
              file:text-sm file:font-semibold
              file:bg-violet-50 file:text-[#1a335b]
              hover:file:bg-violet-100
          "
            />
          </div>
          <div>
            <label htmlFor="details" className="text-[#1a335b] mb-1">
              Content
            </label>

            <ReactQuill
              value={details}
              onChange={onChange}
              theme="snow"
              id="details"
              modules={modules}
              formats={formats}
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`sm:mt-[80px] mt-[110px] ${
              !details || !description || !title ? "opacity-30" : ""
            }  self-end text-[#fff] bg-[#D09600] active:bg-[#d09500b1] w-[120px] h-[45px] rounded-md`}
          >
            {loading ? "Uploading..." : "Upload"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateBlog;
