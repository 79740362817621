import React, { useEffect, useState } from "react";
import AuthHeader from "../auth/auth-header";

function Waitlist() {
  const [, setWidth] = useState(1440);

  const maxWidth = 1440;
  const percentage = 100;

  let dimensions = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
  let zoom =
    dimensions.width > 600 ? (dimensions.width * percentage) / maxWidth : 100;

  function handleResize() {
    dimensions.width = window.innerWidth;
    setWidth(window.innerWidth);
    zoom =
      dimensions.width > 600 ? (dimensions.width * percentage) / maxWidth : 100;
    // @ts-ignore
    document.body.style.zoom = zoom + "%";
  }

  // @ts-ignore
  document.body.style.zoom = zoom + "%";

  //change dimension on page resize
  window.addEventListener("resize", handleResize);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div className="bg-[#2A1E00] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] h-[100%]">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
        <div className="h-[100%] sm:px-[78px] overflow-y-hidden overflow-x-hidden px-[20px] pb-[50px] sm:py-[100px] py-[10px] ">
          {/* <img alt='bg' src='/images/bg-image.svg' className='xl:max-w-[60vw] sm:block flex-1 sm:mt-[0]  min-w-[200px] max-[600px]:w-[100%] max-[600px]:h-[auto]  object-cover ' /> */}
        </div>
      </div>
    </div>
  );
}

export default Waitlist;
