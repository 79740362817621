/* eslint-disable no-useless-escape */
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react"
import OtpInput from "react-otp-input"
import { Modal } from "../../components"
import { updateUser } from "../../store/user-reducer"
import { getCUrrentUser } from "../../utils/get-current-user"
import { useSelector, useDispatch } from "react-redux"
import { IUser } from "../business-dashboard/loan"
import { convertToBase64 } from "../../utils/base64-converter"
import {
  errorToastMessage,
  successToastMessage
} from "../../utils/toast-message"
import axios from "axios"
import { uploadImage } from "../../utils/upload-to-cloudinary"

interface SettingsMenuProps {
  setCurrentTab: (d: any) => void
  setShowSettingsMenu: (d: any) => void
  showSettingsMenu: boolean
  currentTab: string
}
const SettingsMenu = ({
  setCurrentTab,
  setShowSettingsMenu,

  showSettingsMenu
}: SettingsMenuProps) => (
  <AnimatePresence>
    {showSettingsMenu ? (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
        className="w-[100%] bg-[#fff] fixed top-[70px] h-[100%] sm:hidden block"
      >
        <motion.div className="flex flex-col">
          <button
            onClick={() => setShowSettingsMenu(false)}
            className="self-end px-[30px] mt-[30px] mb-[50px]"
          >
            <img alt="icon" src="/images/close-circle.svg" />
          </button>
          {[
            { label: "Account setting", icon: "/images/mob-set-icon.svg" },
            { label: "Documents", icon: "/images/doc-icon.svg" },
            { label: "Login and security", icon: "/images/security-icon.svg" },
            { label: "Help", icon: "/images/help-icon.svg" }
          ].map(({ label, icon }, index) => (
            <button
              onClick={() => {
                setShowSettingsMenu(false)
                setCurrentTab(label)
              }}
              key={index}
              className={`block py-[26px] px-[30px] w-[100%] flex items-center justify-between text-[#292D32]`}
            >
              <span className="flex items-center">
                <img alt="icon" src={icon} className="mr-[8px]" /> {label}
              </span>
              <img alt="right" src="/images/caret-right-dark.svg" />
            </button>
          ))}
        </motion.div>
      </motion.div>
    ) : null}
  </AnimatePresence>
)

function Settings() {
  const currentUser: IUser = useSelector((state: any) => state.user.user)
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const [containNumber, setContainNumber] = useState(false)
  const [containSpecial, setContainSpecial] = useState(false)
  const [containUppercase, setContainUppercase] = useState(false)
  const [containLowercase, setContainLowercase] = useState(false)
  const [minLengthSat, setMinLengthSat] = useState(false)
  const [passwordValidationError, setPasswordValidationError] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState("")
  const [updatingPassword, setUpdatingPassword] = useState(false)
  const [oldPassword, setOldPassword] = useState("")
  const [password, setPassword] = useState("")

  const [currentTab, setCurrentTab] = useState("Account setting")
  const [profile_image, setProfileImage] = useState<any>(
    currentUser?.currentUser?.profile_image
  )
  const [name, setName] = useState(currentUser?.currentUser?.name)
  const [company_phone, setCompanyPhone] = useState(
    currentUser?.currentUser?.phone_number
  )
  const [display_image, setDisplayImage] = useState<any>(
    currentUser?.currentUser?.profile_image
  )

  const [display_id, setDisplayId] = useState<any>(
    currentUser?.credentials?.id_card
  )

  const [id_card, setIdCard] = useState<any>(currentUser?.credentials?.id_card)
  const [display_utility, setDisplayUtility] = useState(
    currentUser?.credentials?.utility_bill
  )
  const [utility, setUtility] = useState<any>(
    currentUser?.credentials?.utility_bill
  )
  const [display_cac, setDisplayCac] = useState(
    currentUser?.credentials?.cac_certificate
  )
  const [cac, setCac] = useState<any>(currentUser?.credentials?.cac_certificate)

  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState("")

  const [showSettingsMenu, setShowSettingsMenu] = useState(true)

  const getCurrentUser = async () => {
    try {
      const response = await axios.get("users/currentuser")

      dispatch(updateUser(response.data))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getCUrrentUser()
  }, [])

  const SendMoneyModal = () => {
    const [pin, setPin] = useState("")

    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="rounded-[8.43px] sm:w-[700px] w-[80%] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
      >
        <div className="flex w-[100%] items-center justify-between mb-[60px]">
          <div className="w-[10px]" />
          <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
            {modalType === "enter-email"
              ? "Email address here"
              : modalType === "otp"
              ? "Get your code"
              : modalType === "reset-password"
              ? "Reset Password"
              : modalType === "enter-password"
              ? "Password"
              : ""}
          </h4>
          <button
            onClick={() => setShowModal(false)}
            className="active:scale-75 transform-gpu"
          >
            <img alt="close" src="/images/close-circle.svg" />
          </button>
        </div>

        {modalType === "enter-email" ? (
          <div className="px-[5%] w-[100%] flex flex-col">
            <label className="relative w-[100%]">
              Email
              <input
                type={"email"}
                placeholder="Enter your email"
                className="mb-[49px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px] w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32]"
              />
            </label>

            <button
              onClick={() => setModalType("otp")}
              className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] sm:px-[129px] max-[620px]:w-[150px] sm:h-[64px] h-[42px]"
            >
              Recover password
            </button>
          </div>
        ) : modalType === "otp" ? (
          <div className="px-[5%] w-[100%] flex flex-col  items-center">
            <h5 className="mb-[55px] sm:mt-[-55px] mt-[-40px] sm:text-[20px] max-[620px]:text-center font-[400] text-[#000000C7]">
              Enter the 4-digits code sent to your email
            </h5>
            <div className="sm:w-[100%] w-[80%] h-[66.3px] rounded-[5px]">
              <OtpInput
                containerStyle={{
                  width: "100%",
                  height: "66.3px",
                  justifyContent: "space-between"
                }}
                value={pin}
                onChange={(text) => {
                  setPin(text)
                  console.log(text, pin)
                  if (text.length === 4) {
                    setModalType("processing")
                  }
                }}
                numInputs={4}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    maxLength={1}
                    className="outline-none sm:w-[65px] sm:h-[100%] w-[50px] h-[50px]"
                    placeholder=""
                    style={{
                      borderRadius: "10px",
                      paddingTop: "10px",
                      // height: '100%',
                      borderWidth: "0.5px",
                      borderColor: "#000",
                      textAlign: "center"
                      // width: '65px'
                    }}
                  />
                )}
              />
            </div>
            <button
              onClick={() => setModalType("reset-password")}
              className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] mt-[43px] text-[#fff] sm:text-[20px] font-[400] sm:px-[93px] max-[620px]:w-[80%] sm:h-[62px] h-[42px]"
            >
              Continue
            </button>
          </div>
        ) : modalType === "reset-password" ? (
          <div className="px-[5%] w-[100%] flex flex-col  items-center">
            <h5 className="mb-[55px] sm:mt-[-55px] mt-[-40px] sm:text-[20px] text-[16px] font-[400] text-[#000000C7] text-center">
              Your new password must be different from the previous password
            </h5>

            <label className="relative w-[100%]">
              Password
              <input
                type={"password"}
                placeholder="Enter your password"
                className="mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px] w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32]"
              />
              <button className="absolute right-[3%] sm:top-[40%] top-[44%]">
                <img
                  alt="show"
                  src="/images/eye-icon.svg"
                  className="sm:h-[24px] sm:w-[24px] h-[14px] w-[14px]"
                />
              </button>
            </label>
            <label className="relative w-[100%]">
              Confirm Password
              <input
                type={"password"}
                placeholder="Enter your password"
                className="mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px] w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32]"
              />
              <button className="absolute right-[3%] sm:top-[40%] top-[44%]">
                <img
                  alt="show"
                  src="/images/eye-icon.svg"
                  className="sm:h-[24px] sm:w-[24px] h-[14px] w-[14px]"
                />
              </button>
            </label>

            <button
              onClick={() => setShowModal(false)}
              className="mt-[33px] rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] font-[600] sm:px-[129px] max-[620px]:w-[80%] sm:h-[64px] h-[42px]"
            >
              Update
            </button>
          </div>
        ) : (
          <div />
        )}
      </motion.div>
    )
  }

  const MAX_FILE_SIZE = 1048576

  const updateProfile = async () => {
    setLoading(true)
    let utility_bill =
      utility && utility?.name
        ? await uploadImage(utility)
        : utility
        ? utility
        : ""
    let id_doc =
      id_card && id_card?.name
        ? await uploadImage(id_card)
        : id_card
        ? id_card
        : ""
    let cac_certificate =
      cac && cac?.name ? await uploadImage(cac) : cac ? cac : ""

    let profile_images =
      profile_image && profile_image?.name
        ? await uploadImage(profile_image)
        : profile_image
        ? profile_image
        : ""

    try {
      const response = await axios.patch("users/currentuser", {
        name,
        company_phone,
        cac_certificate,
        id_card: id_doc,
        profile_image: profile_images,
        utility_bill
      })

      console.log(response.data)

      successToastMessage("Profile Updated!")
      await getCurrentUser()

      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      errorToastMessage(error)
    }
  }

  const checkPassword = (str: string) => {
    setPassword(str)
    var uppercaseRe = /[A-Z]/
    var lowercaseRe = /[a-z]/
    var specialCharRe = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    var numberRe = /[0-9]/

    if (uppercaseRe.test(str)) {
      setContainUppercase(true)
    } else {
      setContainUppercase(false)
    }

    if (lowercaseRe.test(str)) {
      setContainLowercase(true)
    } else {
      setContainLowercase(false)
    }
    if (specialCharRe.test(str)) {
      setContainSpecial(true)
    } else {
      setContainSpecial(false)
    }

    if (numberRe.test(str)) {
      setContainNumber(true)
    } else {
      setContainNumber(false)
    }

    if (str.length >= 8) {
      setMinLengthSat(true)
    } else {
      setMinLengthSat(false)
    }

    if (
      containNumber &&
      containSpecial &&
      containUppercase &&
      containLowercase &&
      minLengthSat
    ) {
      setPasswordValidationError(false)
    } else {
      setPasswordValidationError(true)
    }
  }

  const resetPassword = async () => {
    setUpdatingPassword(true)
    try {
      const response = await axios.patch("users/reset-password", {
        oldPassword,
        password
      })
      console.log(response.data)
      successToastMessage(response.data.message)
      setUpdatingPassword(false)
    } catch (error) {
      errorToastMessage(error)
      setUpdatingPassword(false)
    }
  }

  return (
    <div className="h-[100%] w-[100%]  sm:pt-[55px] sm:pb-[55px] sm:px-[55px] pt-[95px]  overflow-y-scroll">
      <h5 className="font-[500] text-[24px] sm:block hidden">Setting</h5>

      <button
        onClick={() => setShowSettingsMenu(true)}
        className="block sm:hidden self-end px-[30px] mb-[50px]"
      >
        <img alt="icon" src="/images/menu.svg" />
      </button>

      <div className="mt-[11px] w-[100%] bg-[#fff]   rounded-[8px] flex-col items-center">
        <div className="mb-[38px] border-b-[#E0E4EC] border-b-[1.85px] w-[100%]  px-[60px] sm:flex hidden items-center justify-between">
          {["Account setting", "Documents", "Login and security", "Help"].map(
            (tab, index) => (
              <button
                onClick={() => setCurrentTab(tab)}
                key={index}
                className={`py-[26px] px-[30px] w-fit ${
                  currentTab === tab
                    ? "text-[#D09600] border-b-[2px] border-b-[#D09600] "
                    : "text-[#292D32]"
                }`}
              >
                {tab}
              </button>
            )
          )}
        </div>

        {currentTab === "Account setting" ? (
          <div className=" flex sm:flex-row flex-col sm:px-[26px] px-[14px] sm:px-[60px]">
            <div className="sm:mr-[200px] max-[620px]:mb-[53px] max-[620px]:self-center">
              <h5 className="font-[600] text-[24px] text-[#4C535F] mb-[29px] sm:block hidden">
                Your Profile Picture
              </h5>

              <input
                type={"file"}
                className="hidden"
                id="upload-profile"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > MAX_FILE_SIZE) {
                      errorToastMessage("File size cannot be greater than 1mb")
                      return
                    }
                    setDisplayImage(URL.createObjectURL(e.target.files[0]))
                    setProfileImage(e.target.files[0])
                  }
                }}
              />
              <label
                htmlFor="upload-profile"
                className="cursor-pointer sm:border-[2px] border-[1px] border-[#4C535F] border-dashed sm:w-[271px] sm:h-[276px] w-[148.71px] h-[151px] rounded-[37.64px] overflow-hidden flex flex-col items-center justify-center"
              >
                {!profile_image ? (
                  <>
                    <img
                      alt="upload"
                      src="/images/upload-photo.svg"
                      className="object-cover max-[620px]:w-[41.18px] max-[620px]:h-[41.18px]"
                    />
                    <span className="block text-center sm:text-[20px] text-[10.94px] text-[#4C535F] mt-[31px]">
                      Upload your photo
                    </span>
                  </>
                ) : (
                  <img
                    className="object-cover  h-[100%] w-[100%] "
                    alt="profile"
                    src={display_image}
                  />
                )}
              </label>
            </div>

            <div className="flex flex-col w-[100%] pb-[58px]">
              <label className="text-[16px] ">
                Full name
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type={"text"}
                  placeholder="Please enter your full name"
                  className="mb-[35.5px] outline-none mt-[9px] h-[55px] w-[100%] rounded-[8.41px] sm:px-[26px] px-[14px] border-[0.92px] border-[#0000004A] sm:text-[14px] text-[12px]"
                />
              </label>
              <label className="text-[16px]">
                Email
                <input
                  value={currentUser?.currentUser?.email}
                  type={"email"}
                  disabled
                  placeholder="Please enter your email"
                  className="text-gray-400 mb-[35.5px] outline-none mt-[9px] h-[55px] w-[100%] rounded-[8.41px] sm:px-[26px] px-[14px] border-[0.92px] border-[#0000004A] sm:text-[14px] text-[12px]"
                />
              </label>

              <label className="text-[16px] relative">
                Phone Number
                <span className="text-[#8D98AA] absolute sm:top-[43%] top-[43%] left-[25px] border-r-[1px] pr-[10px] sm:text-[14px] text-[12px]">
                  +234
                </span>
                <input
                  value={company_phone}
                  onChange={(e) => setCompanyPhone(e.target.value)}
                  type={"tel"}
                  maxLength={10}
                  placeholder="81 000 0000"
                  className="mb-[35.5px] outline-none mt-[9px] h-[55px] w-[100%] rounded-[8.41px] pl-[100px] sm:pr-[26px] pr-[14px]  border-[0.92px] border-[#0000004A] sm:text-[14px] text-[12px]"
                />
              </label>

              <button
                disabled={
                  !company_phone ||
                  company_phone?.length < 10 ||
                  !name ||
                  loading
                }
                onClick={updateProfile}
                className={`${
                  !company_phone || company_phone?.length < 10 || !name
                    ? "opacity-10"
                    : "opacity-100"
                } flex items-center justify-center self-end w-[80%] sm:h-[62px] h-[40px] bg-[#D09600] text-[#fff] rounded-[8.43px] sm:text-[20px] text-[14px]`}
              >
                {loading && (
                  <svg
                    aria-hidden="true"
                    className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="#fff"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                )}

                {loading ? "Processing" : "Update Profile"}
              </button>
            </div>
          </div>
        ) : currentTab === "Documents" ? (
          <div className="flex flex-col px-[60px] min-h-[50vh]">
            <div className="sm:text-[20px] text-[16px] mb-[33px]">
              Upload valid identification
              <input
                type={"file"}
                className="hidden"
                id="upload-id"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    if (e.target.files[0].size > MAX_FILE_SIZE) {
                      errorToastMessage("File size cannot be greater than 1mb")
                      return
                    }
                    setDisplayId(URL.createObjectURL(e.target.files[0]))
                    setIdCard(e.target.files[0])
                  }
                }}
              />
              <div className=" mt-[14px] flex items-center w-[50%]">
                {id_card && (
                  <img
                    src={display_id}
                    alt="id card"
                    className="mr-[10px] sm:w-[55px] sm:h-[55px] object-cover w-[40px] h-[40px] rounded-[8px]"
                  />
                )}

                <label
                  className="flex-1 active:bg-[#f5f5f5] flex items-center text-[#8D98AA] sm:text-[14px] text-[12px] block sm:px-[26px] px-[14px] sm:h-[50px] h-[40px] border-[0.92px] border-[#0000004A] rounded-[8.43px]"
                  htmlFor="upload-id"
                >
                  Choose file
                </label>
              </div>
              <span className="block text-[#8D98AA] font-[200] text-[11px]">
                maximum file size: 1mb
              </span>
            </div>

            {currentUser?.currentUser?.account_type === "business" && (
              <div className="sm:text-[20px] text-[16px] mb-[33px] sm:w-[50%] w-[100%]">
                Utility bill
                <input
                  type={"file"}
                  className="hidden"
                  id="upload-bill"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      if (e.target.files[0].size > MAX_FILE_SIZE) {
                        errorToastMessage(
                          "File size cannot be greater than 1mb"
                        )
                        return
                      }
                      setDisplayUtility(URL.createObjectURL(e.target.files[0]))
                      setUtility(e.target.files[0])
                    }
                  }}
                />
                <div className="flex items-center">
                  {utility && (
                    <img
                      src={display_utility}
                      alt="id card"
                      className="mr-[10px] sm:w-[55px] sm:h-[55px] object-cover w-[40px] h-[40px] rounded-[8px]"
                    />
                  )}

                  <label
                    className="flex-1 active:bg-[#f5f5f5] flex items-center  text-[#8D98AA] sm:text-[14px] text-[12px] block sm:px-[26px] px-[14px] sm:h-[50px] h-[40px] border-[0.92px] border-[#0000004A] rounded-[8.43px]"
                    htmlFor="upload-bill"
                  >
                    Choose file
                  </label>
                </div>
                <span className="block text-[#8D98AA] font-[200] text-[11px]">
                  maximum file size: 1mb
                </span>
              </div>
            )}

            {currentUser?.currentUser?.account_type === "business" && (
              <div className="sm:text-[20px] text-[16px] mb-[33px] sm:w-[50%]">
                CAC document
                <input
                  type={"file"}
                  className="hidden"
                  id="upload-cac"
                  accept="image/*"
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      if (e.target.files[0].size > MAX_FILE_SIZE) {
                        errorToastMessage(
                          "File size cannot be greater than 1mb"
                        )
                        return
                      }
                      setDisplayCac(URL.createObjectURL(e.target.files[0]))
                      setCac(e.target.files[0])
                    }
                  }}
                />
                <div className="flex items-center">
                  {cac && (
                    <img
                      src={display_cac}
                      alt="id card"
                      className="mr-[10px] sm:w-[55px] sm:h-[55px] object-cover w-[40px] h-[40px] rounded-[8px]"
                    />
                  )}

                  <label
                    className="flex-1 active:bg-[#f5f5f5] flex items-center text-[#8D98AA] sm:text-[14px] text-[12px] block sm:px-[26px] px-[14px] sm:h-[50px] h-[40px] border-[0.92px] border-[#0000004A] rounded-[8.43px]"
                    htmlFor="upload-cac"
                  >
                    Choose file
                  </label>
                </div>
                <span className="block text-[#8D98AA] font-[200] text-[11px]">
                  maximum file size: 1mb
                </span>
              </div>
            )}
            <button
              disabled={loading}
              onClick={updateProfile}
              className="flex items-center justify-center w-[40%] max-[620px]:self-center sm:h-[54px] h-[40px] bg-[#D09600] text-[#fff] rounded-[8.43px] sm:text-[20px] mb-[5%]"
            >
              {loading && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}

              {loading ? "Processing..." : "Update Profile"}
            </button>
          </div>
        ) : currentTab === "Login and security" ? (
          <div className="flex flex-col px-[60px] min-h-[50vh] sm:w-[50%]">
            <label className="relative w-[100%]">
              Password
              <input
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                type={"password"}
                placeholder="Enter your password"
                className="mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px]  w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32]"
              />
              <button className="absolute right-[3%] sm:top-[40%] top-[44%]">
                <img
                  alt="show"
                  src="/images/eye-icon.svg"
                  className="sm:h-[24px] sm:w-[24px] h-[14px] w-[14px]"
                />
              </button>
            </label>
            <label className="relative w-[100%]">
              New Password
              <input
                value={password}
                onChange={(e) => checkPassword(e.target.value)}
                type={"password"}
                placeholder="Enter your password"
                className={`mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px] w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32]
                    ${
                      passwordValidationError
                        ? "border-[red]"
                        : "border-[#292D32]"
                    }
                    `}
              />
              <button className="absolute right-[3%] sm:top-[40%] top-[44%]">
                <img
                  alt="show"
                  src="/images/eye-icon.svg"
                  className="sm:h-[24px] sm:w-[24px] h-[14px] w-[14px]"
                />
              </button>
            </label>
            <label className="relative w-[100%]">
              Confirm Password
              <input
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={"password"}
                placeholder="Enter your password"
                className={`mb-[32px] outline-none mt-[9px] sm:h-[50px] h-[40px] sm:text-[14px] text-[12px] w-[100%] rounded-[8.43px] sm:px-[26px] px-[14px] border-[0.5px] border-[#292D32] ${
                  confirmPassword !== password
                    ? "border-[red]"
                    : "border-[#292D32]"
                }
                    `}
              />
              <button className="absolute right-[3%] sm:top-[40%] top-[44%]">
                <img
                  alt="show"
                  src="/images/eye-icon.svg"
                  className="sm:h-[24px] sm:w-[24px] h-[14px] w-[14px]"
                />
              </button>
            </label>

            <button
              onClick={resetPassword}
              disabled={
                passwordValidationError ||
                password !== confirmPassword ||
                !password.length ||
                !oldPassword
              }
              className={`flex items-center justify-center w-[40%] max-[620px]:self-center sm:h-[54px] h-[40px] bg-[#D09600] text-[#fff] rounded-[8.43px] sm:text-[20px] ${
                passwordValidationError ||
                password !== confirmPassword ||
                !password.length ||
                !oldPassword
                  ? "opacity-30"
                  : ""
              }`}
            >
              {updatingPassword && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              {updatingPassword ? "Updating..." : "Update"}
            </button>
            <span className="sm:text-[16px] text-[12px] mt-[14px]">
              Forget password?{" "}
              <button
                className="text-[#D09600]"
                onClick={() => {
                  setShowModal(true)
                  setModalType("enter-email")
                }}
              >
                click here to recover
              </button>
            </span>
          </div>
        ) : (
          <div className="px-[37px] py-[21px] min-h-[50vh] flex flex-col">
            <div className="relative text-[#fff] border-[10px] mb-[17px] border-[#FAF5E6] rounded-[5px] bg-[#D09600] px-[63px] py-[61px]">
              <h3 className="font-[700] text-[48px] leading-[55px]">
                Help Center
              </h3>
              {/* <p className="max-w-[45%] mt-[18px]">
                Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet{" "}
              </p> */}

              <img
                alt="call-agent"
                src="/images/help-center.png"
                className="h-[200px] absolute right-[10%] bottom-0"
              />
            </div>

            <h4 className="text-center sm:text-[24px] text-[18px] font-[600] mb-[13px]">
              How can we help you?
            </h4>

            <div className="flex flex-col">
              <div className="sm:flex items-center mb-[29px]">
                <label htmlFor="email" className="max-[620px]:block">
                  Email Address
                </label>
                <input
                  id="email"
                  type={"email"}
                  className="px-[20px] border-[0.92px] border-[#0000004A] rounded-[8.41px] outline-none sm:h-[55px] h-[40px] sm:text-[14px] text-[12px] sm:min-w-[300px] sm:w-[60%] w-[100%] sm:ml-[95px]"
                />
              </div>
              <div className="sm:flex  items-center mb-[29px]">
                <label htmlFor="issue" className="max-[620px]:block">
                  Describe Issue
                </label>
                <input
                  id="issue"
                  type={"text"}
                  className="px-[20px] border-[0.92px] border-[#0000004A] rounded-[8.41px] outline-none sm:h-[55px] h-[40px] sm:text-[14px] text-[12px] sm:min-w-[300px] sm:w-[60%] w-[100%] sm:ml-[95px]"
                />
              </div>
              <div className="sm:flex items-center mb-[29px]">
                <label htmlFor="message" className="max-[620px]:block">
                  Enter Message
                </label>
                <textarea
                  id="message"
                  className="p-[20px] border-[0.92px] border-[#0000004A] rounded-[8.41px] outline-none h-[123px]  sm:text-[14px] text-[12px] sm:min-w-[300px] sm:w-[60%] w-[100%] sm:ml-[95px]"
                />
              </div>

              <button className="w-[232px] self-end sm:h-[54px] h-[42px] bg-[#D09600] text-[#fff] rounded-[8.43px] text-[14px] sm:text-[18px] mb-[5%] sm:mr-[20%]">
                Update Profile
              </button>
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <Modal>
          <SendMoneyModal />
        </Modal>
      )}

      {showSettingsMenu && (
        <SettingsMenu
          showSettingsMenu={showSettingsMenu}
          setShowSettingsMenu={setShowSettingsMenu}
          setCurrentTab={setCurrentTab}
          currentTab={currentTab}
        />
      )}
    </div>
  )
}

export default Settings
