import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Modal } from "../../components";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { ILoan } from "../business-dashboard/sme";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IUser } from "../business-dashboard/loan";
import Loader from "../../components/Loader";

export interface ICredentials {
  bank_statement: string;
  bvn: number;
  cac_certificate: string;
  id_card: string;
  utility_bill: string;
  user: any;
}

interface DetailsProps {
  modalType: string;
  loanUser: any;
  setShowMenu: (s: any) => void;
  setShowModal: (s: any) => void;
  credentials: ICredentials;
  loan: ILoan | undefined;
  credentialsLoading: boolean;
}
const DetailsModal = ({
  modalType,
  setShowMenu,
  setShowModal,
  loanUser,
  credentials,
  credentialsLoading,
  loan,
}: DetailsProps) => {
  const [expiryDate, setExpiryDate] = useState<any>(
    new Date(
      new Date().setMonth(new Date().getMonth() + loan!.preferred_tenure)
    )
  );
  const [amount, setAmount] = useState(loan?.amount_requested);
  const [interest, setInterest] = useState(loan?.interest_rate);
  const [showBvn, setShowBvn] = useState(false);
  const [fundLoading, setFundLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const user: IUser = useSelector((state: any) => state.user.user);
  const fundLoan = async () => {
    setFundLoading(true);
    try {
      const res = await axios.patch(`user-loans/${loan?.id}`, {
        amount_granted: amount,
        expiry_date: expiryDate,
        interest_rate: interest,
        loan_status: "granted",
      });
      setFundLoading(false);
      setShowMenu(false);
      setShowModal(false);

      toast.success(res.data?.message, {
        position: "top-right",

        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error: any) {
      console.log(error);
      setFundLoading(false);
      toast.error(error?.data ? error?.data?.message : "An Error Occurred!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const publishLoan = async () => {
    setPublishLoading(true);
    try {
      const response = await axios.patch(`user-loans/${loan?.id}`, {
        amount_granted: amount,
        expiry_date: expiryDate,
        interest_rate: interest,
        loan_status: "published",
        partner: loan?.partner ? loan?.partner : user?.currentUser?.id,
      });

      setPublishLoading(false);
      setShowMenu(false);
      setShowModal(false);

      toast.success("Loan Published!", {
        position: "top-right",

        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.log(response);
    } catch (error: any) {
      console.log(error);
      toast.error(error?.data ? error?.data?.message : "An Error Occurred!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setPublishLoading(false);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className={`overflow-y-scroll rounded-sm sm:w-[700px] w-[90%] bg-[#fff] px-[5%] pb-[5%] pt-[55px] ${modalType === "view-user-details" ? "h-[100%] w-[50%] self-end" : ""
        }`}
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        {modalType !== "view-user-details" && <div className="w-[10px]" />}
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "edit-loan"
            ? "Edit loan request"
            : modalType === "share"
              ? "Share with partner"
              : modalType === "uploaded-doc"
                ? "Uploaded document"
                : modalType === "view-user-details"
                  ? "Customer details"
                  : ""}
        </h4>
        <button
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img alt="close" src="/images/close-circle.svg" />
        </button>
      </div>

      {modalType === "edit-loan" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <label className="sm:text-[20px] text-[14px]">
            Amount (&#8358;)
            <input
              value={amount}
              onChange={(e) => setAmount(parseFloat(e.target.value))}
              type={"number"}
              className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>

          <label className="sm:text-[20px] text-[14px]">
            Due date
            <button className="text-left mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]">
              <DatePicker
                selected={expiryDate}
                onChange={(date) => setExpiryDate(date)}
                className="outline-none cursor-pointer"
              />
            </button>
          </label>

          <label className="sm:text-[20px] text-[14px]">
            Interest rate (%)
            <input
              value={interest}
              onChange={(e) => setInterest(parseFloat(e.target.value))}
              type={"number"}
              className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px]  w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>

          <div className="flex justify-center items-center mt-[42px]">
            <button
              disabled={fundLoading || publishLoading}
              onClick={fundLoan}
              className="flex items-center justify-center active:opacity-30 rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
            >
              {fundLoading && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}

              {fundLoading ? "Funding..." : "Fund"}
            </button>
            <button
              disabled={publishLoading || fundLoading}
              onClick={publishLoan}
              className="ml-[10px] flex items-center justify-center active:opacity-30 rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
            >
              {publishLoading && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              {publishLoading ? "Publishing..." : "Publish"}
            </button>
          </div>
        </div>
      ) : // : modalType === "share" ? (
        // <div className="px-[5%] w-[100%] flex flex-col">
        //   <label className="sm:text-[20px] text-[14px]">
        //     Enter partner name
        //     <input
        //       type={"text"}
        //       className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
        //     />
        //   </label>

        //   <label className="sm:text-[20px] text-[14px]">
        //     Enter partner email
        //     <input
        //       type={"email"}
        //       className="mb-[13px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
        //     />
        //   </label>

        //   <div className="flex flex-col items-center mt-[42px]">
        //     <button
        //       onClick={() => {
        //         setShowMenu(false);
        //         setShowModal(false);
        //       }}
        //       className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
        //     >
        //       Share
        //     </button>
        //   </div>
        // </div>
        // )
        modalType === "uploaded-doc" ? (
          <div className="px-[5%] w-[100%] flex flex-col">
            <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
              <span>{showBvn ? credentials?.bvn : "BVN"}</span>

              <button
                onClick={() => {
                  if (!credentialsLoading) {
                    setShowBvn(!showBvn);
                  }
                }}
                className="active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
              >
                {credentialsLoading ? "Please wait" : "View"}
              </button>
            </div>
            <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
              <span>Certicate of incorporation</span>

              <a
                href={credentials?.cac_certificate}
                download={`${credentials?.user?.name}-cac-doc`}
                className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
              >
                {credentialsLoading ? "Please wait" : "View"}
              </a>
            </div>
            <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
              <span>One year bank statement</span>

              <a
                href={credentials?.bank_statement}
                download={`${credentials?.user?.name}-bank-statement`}
                className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
              >
                {credentialsLoading ? "Please wait" : "View"}
              </a>
            </div>
            <div className="sm:text-[20px] text-[14px] cursor-pointer flex items-center justify-between mb-[28px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] sm:pl-[26px] pl-[15px] pr-[10px] border-[1px] border-[#292D32]">
              <span>Utility bill</span>

              <a
                href={credentials?.utility_bill}
                download={`${credentials?.user?.name}-utility-bill`}
                className="flex items-center  justify-center active:opacity-30 w-[80px] sm:h-[42px] h-[30px] bg-[#292D32] rounded-[5px] text-[#fff]"
              >
                {credentialsLoading ? "Please wait" : "View"}
              </a>
            </div>

            <div className="flex flex-col items-center mt-[42px]">
              <button
                onClick={() => {
                  //   setShowMenu(false);
                  setShowModal(false);
                }}
                className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[400] w-[60%] sm:h-[52px] h-[40px]"
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          modalType === "view-user-details" && (
            <div className="px-[5%] w-[100%] flex flex-col items-center">
              <div className="overflow-hidden sm:w-[134px] sm:h-[134px] w-[100px] h-[100px] rounded-full bg-[#533C00] flex items-center justify-center mb-[74px]">
                <img
                  alt="dp"
                  src={
                    loanUser?.profile_image
                      ? loanUser?.profile_image
                      : "/images/camera-icon.svg"
                  }
                  className={`${loanUser?.profile_image
                    ? "sm:w-[134px] sm:h-[134px] w-[100px] h-[100px]"
                    : "max-w-[100px] max-h-[100px]"
                    }
                     object-cover`}
                />
              </div>

              <label className="sm:text-[18px] text-[14px] w-[100%]">
                Name
                <input
                  type={"text"}
                  value={loanUser?.name}
                  disabled
                  className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
                />
              </label>

              <label className="sm:text-[18px] text-[14px] w-[100%]">
                Enter partner email
                <input
                  disabled
                  type={"email"}
                  value={loanUser?.email}
                  className="mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
                />
              </label>

              <label className="sm:text-[18px] text-[14px] w-[100%]">
                Phone number
                <input
                  type={"tel"}
                  value={loanUser?.phone_number}
                  disabled
                  className="w-[100%] mb-[29px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
                />
              </label>

              <label className="sm:text-[18px] text-[14px] w-[100%]">
                Account Type
                <input
                  type={"text"}
                  disabled
                  value={loanUser?.account_type}
                  className="mb-[20px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
                />
              </label>
              {/* <label className="sm:text-[18px] text-[14px] w-[100%]">
              Reason
              <input
                type={"text"}
                value="Some needs here and there"
                className="mb-[39px] bg-[#F9F9F9] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
              />
            </label> */}

              <button
                onClick={() => setShowModal(false)}
                className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] w-[80%] sm:h-[54px] h-[40px] text-[#fff] sm:text-[20px] text-[14px] font-[600] "
              >
                Back
              </button>
            </div>
          )
        )}
    </motion.div>
  );
};
function SmeRequests() {
  const [selectedRequest, setSelectedRequest] = useState<ILoan>();
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [smeLoans, setSmeLoans] = useState<ILoan[]>([]);
  const [credentials, setCredentials] = useState<any>();
  const [credentialLoading, setCredentialLoading] = useState<boolean>(false);
  const [user, setUser] = useState<any>();
  const [pageLoading, setPageLoading] = useState<boolean>(false);

  const getLoans = async () => {
    setPageLoading(true);
    try {
      const response = await axios.get("user-loans/all?loan_type=sme");
      setSmeLoans([...response.data.loans]);
      setPageLoading(false);
    } catch (error: any) {
      console.log(error);
      setPageLoading(false);
    }
  };

  const getCredentials = async (user_id: string) => {
    setCredentialLoading(true);
    try {
      const response = await axios.get(`admin/user/${user_id}`);
      console.log(response.data, "CREDEN");
      setCredentials(response.data.credentials);
      setUser(response.data.user);
      setCredentialLoading(false);
    } catch (error) {
      setCredentialLoading(false);
      console.log(error, "ERROR");
    }
  };
  useEffect(() => {
    const controller = new AbortController();
    const getAllLoans = async () => {
      setPageLoading(true);
      await getLoans();
      setPageLoading(false);
    };

    getAllLoans();
    return () => {
      controller.abort();
    };
  }, []);

  if (pageLoading) {
    return <Loader />;
  }

  console.log(smeLoans, "LOANS")
  return (
    <div className="h-[100%] w-[100%] pt-[80px] px-[31px] sm:pt-[55px] sm:px-[55px] sm:p-[55px] bg-[#F9F9F9]">
      <div className="flex items-center justify-between">
        <h5 className="font-[500] sm:text-[24px] text-[18px]">
          SME Request List
        </h5>

        <div className="ml-[65px] flex-1 sm:flex hidden items-center bg-[#fff] rounded-[25px] px-[15px] text-[16px] h-[37px]">
          <img alt="search" src="/images/search-icon.svg" />
          <input
            className="outline-none px-[10px] h-[37px] w-[100%]"
            placeholder="Search"
          />
        </div>

        <div className="sm:flex hidden items-center ml-[200px]">
          <span className="text-[#000000A1] font-[600]">Show</span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#fff] text-[14px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block w-[88.14px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600]">List</span>
        </div>
      </div>

      <div className=" overflow-x-auto mt-[15px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)] ">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-4 py-3">
                Name
              </th>
              <th scope="col" className="px-4 py-3 min-w-[120px]">
                Date
              </th>
              <th scope="col" className="px-4 py-3">
                Amount
              </th>
              <th scope="col" className="px-4 py-3 min-w-[120px]">
                Due date
              </th>
              <th scope="col" className="px-4 py-3 min-w-[120px]">
                Status
              </th>
              <th scope="col" className="px-4 py-3">
                Interest rate
              </th>
              <th scope="col" className="px-0 py-3"></th>
            </tr>
          </thead>
          {smeLoans.length ? (
            <tbody>
              {smeLoans.map((loan, index) => (
                <tr
                  className="sm:text-[16px] cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[500] "
                  key={index}
                >
                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    {loan?.user?.name}
                  </td>

                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    {loan?.createdAt?.split("T")[0]}
                  </td>

                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    &#8358;{loan.amount_requested.toLocaleString()}
                  </td>
                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    {loan?.expiry_date
                      ? loan.expiry_date?.split("T")[0]
                      : loan.createdAt?.split("T")[0]}
                  </td>

                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    <span
                      className={`${loan.loan_status === "invested" ||
                        loan.loan_status === "granted" ||
                        loan.loan_status === "repaid" ||
                        loan.loan_status === "repaying"
                        ? "bg-[#059e33]"
                        : loan.loan_status === "pending" ||
                          loan.loan_status === "published"
                          ? "bg-[#fcba03]"
                          : "bg-[#fc2403]"
                        } text-[#fff] px-[5px] rounded-[5px] py-[3px] capitalize`}
                    >
                      {" "}
                      {loan.loan_status}
                    </span>
                  </td>

                  <td onClick={() => setShowMenu(false)} className="px-4 py-3">
                    {loan.interest_rate}%
                  </td>

                  <td className="px-2 py-4 relative">
                    <button
                      onClick={() => {
                        setShowMenu(!showMenu);
                        setSelectedRequest(loan);
                      }}
                      className="min-w-[20px] rounded-[5.38px] flex justify-center items-center "
                    >
                      <img
                        src="/images/more.svg"
                        className="min-w-[20px] min-h-[20px]"
                        alt="more"
                      />
                    </button>

                    {showMenu && selectedRequest === loan && (
                      <div
                        className={`${smeLoans.length > 5 &&
                          (index >= smeLoans.length - 2
                            ? "top-[-200px]"
                            : index >= smeLoans.length - 5
                              ? "top-[-100px]"
                              : "")
                          }  absolute overflow-y-scroll z-[10] top-0 sm:left-[-241px] left-[-170px] pt-[16px] pb-[8px] text-[#292D32]  rounded-[2px] sm:w-[241px] w-[180px] bg-[#fff] drop-shadow-md`}
                      >
                        <h2 className="px-[22px] sm:mb-[28px] mb-[15px] sm:text-[20px] text-[14px] font-[600]">
                          Loan options
                        </h2>

                        {[
                          {
                            label: "Edit loan request",
                            icon: "/images/edit-loan.svg",
                            onClick: () => {
                              setShowModal(true);
                              setModalType("edit-loan");
                            },
                          },
                          // {
                          //   label: "Share with partner",
                          //   icon: "/images/share-icon.svg",
                          //   onClick: () => {
                          //     setShowModal(true);
                          //     setModalType("share");
                          //   },
                          // },
                          {
                            label: "View user details",
                            icon: "/images/eye-icon.svg",
                            onClick: () => {
                              setShowModal(true);
                              getCredentials(loan.user?.id);
                              setModalType("view-user-details");
                            },
                          },
                          // {
                          //   label: "Uploaded document",
                          //   icon: "/images/uploaded-doc-icon.svg",
                          //   onClick: () => {
                          //     setShowModal(true);
                          //     getCredentials(loan.user.id);
                          //     setModalType("uploaded-doc");
                          //   },
                          // },
                        ].map(({ label, icon, onClick }, index) => (
                          <button
                            disabled={
                              label === "Edit loan request" &&
                              loan?.loan_status !== "pending" &&
                              loan?.loan_status !== "awaiting-approval"
                            }
                            onClick={onClick}
                            key={index}
                            className={`w-[100%] ${index < 3 ? "border-b-[0.5px]" : ""
                              } ${loan.user?.account_type === "partner" &&
                                label.toLocaleLowerCase() === "uploaded document"
                                ? "hidden"
                                : ""
                              } border-b-[#292D324D] active:bg-[#e8e8e8ba] py-[11.5px] sm:px-[22px] px-[18px] flex items-center sm:text-[14px] text-[12px]`}
                          >
                            <img
                              alt={label}
                              src={icon}
                              className="mr-[14px] sm:min-w-[16px] sm:min-h-[16px] sm:h-[16px] sm:w-[16px]"
                            />
                            {label}
                          </button>
                        ))}
                      </div>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="px-5 py-4 text-gray-700   text-[16px]">
                  No Data
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="sm:text-[16px] text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] sm:text-[14px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
        </div>
      </div> */}

      {showModal && (
        <Modal>
          <DetailsModal
            loan={selectedRequest}
            credentials={credentials}
            loanUser={user}
            credentialsLoading={credentialLoading}
            setShowModal={setShowModal}
            setShowMenu={setShowMenu}
            modalType={modalType}
          />
        </Modal>
      )}
    </div>
  );
}

export default SmeRequests;
