import { Navigate, useLocation } from "react-router-dom";

const GuardedRoute = ({ children }: { children: JSX.Element }) => {
  const isLoggedIn = localStorage.getItem("@user");

  let location = useLocation();

  if (
    isLoggedIn === null ||
    (isLoggedIn && JSON.parse(isLoggedIn).currentUser === null)
  ) {
    return <Navigate to={"/auth/login"} state={{ from: location }} />;
  }
  return children;
};

export default GuardedRoute;
