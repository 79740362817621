import { motion } from "framer-motion";
import { errorToastMessage } from "../../utils/toast-message";
import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "../../components";
import { ILoan } from "../business-dashboard/sme";
import Loader from "../../components/Loader";

interface ModalProps {
  setModalType: (value?: any) => void;
  setShowModal: (value?: any) => void;
  modalType: string;
  showModal: boolean;
}

const ApplyForLoanModal = ({
  setModalType,
  setShowModal,
  modalType,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);

  const [payback_mode, setPaybackMode] = useState("");
  const [loan_amount, setLoanAmount] = useState("");
  const [loan_term, setLoanTerm] = useState("");

  const FLATINTEREST = 0.5;
  const apply = async () => {
    const amount_requested = parseFloat(loan_amount);

    setLoading(true);
    let body = {
      amount_requested,
      loan_type: "sme",
      payback_mode,
      interest_rate: FLATINTEREST * parseInt(loan_term),
      preferred_tenure: parseInt(loan_term),
    };

    try {
      const response = await axios.post("user-loans/apply", body);

      console.log(response.data);
      setLoading(false);
      setModalType("processing");
    } catch (error: any) {
      setLoading(false);
      errorToastMessage(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "apply"
            ? "Apply for an SME loan"
            : modalType === "review"
            ? "Review and apply"
            : ""}
        </h4>
        <button
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img
            alt="close"
            src="/images/close-circle.svg"
            className="sm:h-[21.5px] sm:w-[21.5px] h-[15px] w-[15px]"
          />
        </button>
      </div>

      {modalType === "apply" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <h4 className="sm:text-[25px] text-[20px] mb-[20px] font-[600]">
            Choose A Tenure
          </h4>
          {[3, 6, 9, 12, 15, 18, 21, 24].map((v, i) => (
            <label key={i} className="form-control max-[620px]:text-[16px] ">
              <input
                onChange={() => setLoanTerm(`${v}`)}
                value={v}
                checked={loan_term === `${v}`}
                type="radio"
                name="radio"
              />
              {v} Months
            </label>
          ))}

          {/* <label className="form-control max-[620px]:text-[16px]">
            <input
              type="radio"
              name="radio"
              onChange={() => setLoanTerm("6")}
              value={6}
              checked={parseInt(loan_term) === 6}
            />
            6 Months
          </label>
          <label className="form-control max-[620px]:text-[16px]">
            <input
              type="radio"
              name="radio"
              onChange={() => setLoanTerm("10")}
              value={10}
              checked={parseInt(loan_term) === 10}
            />
            9 Months
          </label>
          <label className="form-control max-[620px]:text-[16px]">
            <input
              type="radio"
              name="radio"
              onChange={() => setLoanTerm("10")}
              value={10}
              checked={parseInt(loan_term) === 10}
            />
            9 Months
          </label> */}

          {/* <div className="w-[100%] mt-[22px]">
            <p className="text-[#292D32] sm:text-[20px] text-[16px] font-[600]">
              Other (in months)
            </p>

            <input
              className="outline-none border-0 border-b-[1px] w-[100%] border-b-[#000000]"
              onChange={(e) => setLoanTerm(e.target.value)}
            />
          </div> */}
          <div className="mt-[45px] max-[620px]:text-[16px] text-[18px] w-[100%] border-[1px] w-[100%] border-[#000000] rounded-[8.43px] px-2 p-1 pb-2 flex flex-col items-center">
            <select
              value={payback_mode}
              className="mt-3 w-[100%] outline-none"
              onChange={(e) => setPaybackMode(e.target.value)}
            >
              <option value={""}>---Investor Payment Scheme---</option>
              <option value={"at-tenure-end"}>At Tenure End </option>
              <option value={"interest-monthly"}>Interest Monthly </option>
            </select>
          </div>
          <div className="w-[100%] mt-[45px] mb-[33px]">
            <p className="text-[#292D32] sm:text-[20px] text-[16px] font-[600]">
              Amount
            </p>

            <input
              value={loan_amount}
              type="number"
              onChange={(e) => setLoanAmount(e.target.value)}
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] max-[620px]:text-[16px]"
            />
            {loan_amount && (
              <span className="mt-[9px] block sm:text-[16px] text-[12px]">
                You'll pay the sum of{" "}
                <span className="text-[#D09600]">
                  ₦
                  {(
                    parseFloat(loan_amount) +
                    ((parseFloat(loan_amount) * FLATINTEREST) / 100) *
                      parseInt(loan_term)
                  ).toLocaleString()}{" "}
                </span>
                back
              </span>
            )}
          </div>

          <button
            disabled={!loan_amount || !loan_term || !payback_mode}
            onClick={() => setModalType("review")}
            className={`${
              !loan_amount || !loan_term || !payback_mode
                ? "opacity-10"
                : "opacity-100"
            } rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[16px] font-[600] px-[129px] sm:h-[64px] h-[45px]`}
          >
            Apply
          </button>
        </div>
      ) : modalType === "review" ? (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <p className="w-[90%] sm:text-[20px] text-[14px] mb-[42px] text-center">
            You're applying for the sum of ₦
            {(
              parseFloat(loan_amount) +
              ((parseFloat(loan_amount) * FLATINTEREST) / 100) *
                parseInt(loan_term)
            ).toLocaleString()}{" "}
            for {loan_term} months
          </p>

          <div className="flex items-center w-[100%]">
            <button
              disabled={loading}
              onClick={() => setModalType("apply")}
              className="border-[#000000] border-[1px] flex-1 rounded-[8.43px] self-center active:bg-[#f4f4f4b3] bg-[#FFF] sm:text-[20px] text-[14px] font-[600] sm:px-[76px] max-[620px]:flex-1 sm:py-[15px] py-[10px]"
            >
              Back
            </button>
            <button
              disabled={loading}
              onClick={apply}
              className="flex-1 flex items-center justify-center rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] ml-[37px] text-[#fff] sm:text-[20px] text-[14px] font-[600] sm:px-[76px] max-[620px]:flex-1 sm:py-[15px] py-[10px]"
            >
              {loading && (
                <svg
                  aria-hidden="true"
                  className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#fff"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              )}
              {loading ? "Processing..." : "Confirm"}
            </button>
          </div>
        </div>
      ) : (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <img
            alt="processing"
            src="/images/processing-icon.svg"
            className="mb-[50px]"
          />
          <h5 className="text-center font-[600] sm:text-[24px] text-[18px] mb-[15px]">
            Application processing
          </h5>
          <p className="text-center sm:text-[20px] text-[14px] mb-[60px]">
            you will be notified on the status of your application
          </p>
          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] sm:ml-[37px] text-[#fff] sm:text-[24px] text-[16px] font-[600] px-[76px] py-[15px]"
          >
            Confirm
          </button>
        </div>
      )}
    </motion.div>
  );
};

function PartnerDashboard() {
  const [modalType, setModalType] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [due_balance, setDueBalance] = useState(0);
  const [payback_date, setPaybackDate] = useState("");
  const [outstanding_payment, setOutstandingPayment] = useState(0);
  const [hasActiveLoan, setHasActiveLoan] = useState(false);
  const [myLoans, setMyLoans] = useState<ILoan[]>([]);

  const getLoans = async () => {
    setLoading(true);
    try {
      const response = await axios.get("user-loans/?loan_type=sme");
      setMyLoans(response.data.loans);
      setHasActiveLoan(response.data.hasActiveLoan);

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };
  const getDueLoan = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "user-loans/current-month/outstanding-amount"
      );
      setDueBalance(response.data.monthly_payment);
      setOutstandingPayment(response.data.outstanding_payment);
      setPaybackDate(response.data.payback_date);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getLoans();
    getDueLoan();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="h-[100%] w-[100%] sm:pt-[55px] overflow-x-scroll sm:pb-[55px] sm:px-[55px] pt-[95px] px-[31px] bg-[#F9F9F9]">
      <h5 className="font-[500] text-[24px] mb-[25px] sm:block hidden">
        Welcome back!
      </h5>
      <h5 className="font-[700] text-[16px] sm:hidden block mb-[18px]">
        Investment
      </h5>

      <div className="max-[620px]:max-w-[calc(100vw-62px)] overflow-x-scroll w-[100%]">
        <div className="w-[100%] flex items-center mb-[56px]">
          {/* <div className="flex text-[#fff] bg-[#533C00C4] rounded-[10px] sm:py-[45px] max-[620px]:min-w-[230px] max-[620px]:h-[119px] max-[620px]:items-center  sm:pl-[44px] pl-[15px] sm:pr-[38px]">
            <img
              alt="icon"
              src="/images/investment-icon.svg"
              className="sm:min-h-[90px] sm:min-w-[90px] max-[620px]:w-[48px] max-[620px]:h-[48px]"
            />

            <div className="sm:ml-[55px] ml-[13px]">
              <span className="sm:text-[24px] text-[14px] font-[600] mb-[13px]">
                Loan Amount
              </span>
              <h4 className="sm:text-[48px] text-[24px] font-[700]">
                &#8358;20,000
              </h4>
            </div>
          </div> */}
          <div className=" sm:w-[50%] bg-[#533C00] py-[42px] rounded-[10px] flex items-center text-center justify-center items-center text-[#fff] flex-col">
            <p className="text-[24px] font-[600]">Apply for Loan</p>

            <button
              onClick={() => {
                if (hasActiveLoan) {
                  errorToastMessage({
                    response: {
                      data: { errors: [{ message: "You have unrepaid loan" }] },
                    },
                  });
                } else {
                  setShowModal(true);
                  setModalType("apply");
                }
              }}
              className="active:opacity-40 mt-[21px] bg-[#fff] w-[134px] h-[46px] text-[#292D32C7] rounded-[10px]"
            >
              Apply
            </button>
          </div>
          <div className="sm:w-[50%] ml-[53px] sm:min-w-[400px] !w-fit flex text-[#292D32] bg-[#FFF] rounded-[10px] sm:py-[45px] max-[620px]:min-w-[230px] max-[620px]:h-[119px] max-[620px]:items-center sm:pl-[44px] pl-[15px] sm:pr-[38px]">
            {/* <img
              alt="icon"
              src="/images/investment-number-icon.svg"
              className="sm:min-h-[90px] sm:min-w-[90px] max-[620px]:w-[48px] max-[620px]:h-[48px]"
            /> */}

            <div className="sm:ml-[55px] ml-[13px]">
              <span className="sm:text-[24px] text-[14px] font-[600] mb-[13px]">
                Due Balance
              </span>
              <h4 className="sm:text-[48px] text-[24px] font-[700]">
                ₦{parseFloat(due_balance.toFixed(1)).toLocaleString()}
              </h4>
              {due_balance > 0 && (
                <span className="text-gray-400 text-[14px]">
                  Due by: {new Date(payback_date).toLocaleDateString()}
                </span>
              )}
              {outstanding_payment > 0 && (
                <span className="text-red-400 text-[14px] block mt-[5px] font-[700]">
                  Outstanding payment: ₦{outstanding_payment.toLocaleString()}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex items-center mb-[15px] mt-[-35px]">
        <img alt="scroll" src="/images/hand-scroll.svg" />
        <span className="ml-[12px] font-[500] text-[#292D32] text-[12px]">
          Swipe horizontal to see more details
        </span>
      </div>
      <div className=" justify-between flex items-center">
        <h4 className="sm:text-[24px] text-[16px] font-[500]">
          Request history
        </h4>

        <div className="flex items-center">
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            Show
          </span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#D9D9D900] sm:text-[14px] text-[10px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block sm:w-[88.14px] w-[50px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            List
          </span>
        </div>
      </div>
      <div className=" overflow-x-auto mt-[12px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)]">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Transaction ID
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Interest rate
              </th>
              <th scope="col" className="px-6 py-3">
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {myLoans.map((loan, index) => (
              <tr
                className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                key={index}
              >
                <td className="px-6 py-4">{loan?.createdAt?.split(":")[0]}</td>
                <td className="px-6 py-4">{loan?.user?.name}</td>

                <td className="px-6 py-4">
                  ₦
                  {loan?.amount_granted
                    ? loan?.amount_granted
                    : loan?.amount_requested.toLocaleString()}
                </td>

                <td className="px-6 py-4">{loan?.interest_rate}%</td>

                <td className="px-6 py-4">
                  <span
                    className={`${
                      loan?.loan_status === "invested" ||
                      loan?.loan_status === "granted" ||
                      loan?.loan_status === "repaid" ||
                      loan?.loan_status === "disbursed" ||
                      loan.loan_status === "funded"
                        ? "bg-[#059e33]"
                        : loan.loan_status === "pending" ||
                          loan?.loan_status === "published" ||
                          loan?.loan_status === "repaying"
                        ? "bg-[#fcba03]"
                        : "bg-[#fc2403]"
                    } text-[#fff] px-[5px] rounded-[5px] py-[3px]`}
                  >
                    {" "}
                    {loan.loan_status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="sm:text-[16px] text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] sm:text-[14px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
        </div>
      </div> */}

      {showModal && (
        <Modal>
          <ApplyForLoanModal
            setModalType={setModalType}
            setShowModal={setShowModal}
            modalType={modalType}
            showModal={showModal}
          />
        </Modal>
      )}
    </div>
  );
}

export default PartnerDashboard;
