import { toast } from "react-toastify";

export const successToastMessage = (message: string) => {
  toast.success(message, {
    position: "top-right",

    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export const warningToastMessage = (message: string) => {
  toast.warning(message, {
    position: "top-right",

    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const errorToastMessage = (error: any) => {
  let errorMessage = error?.response?.data?.errors[0];

  toast.error(errorMessage ? errorMessage.message : "An Error Occurred!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
export { };
