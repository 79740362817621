import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useParams } from "react-router-dom";
import { ILoan } from "../business-dashboard/sme";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

function LoanDetails() {
  const [loan, setLoan] = useState<ILoan>();

  console.log(loan?.amount_granted, loan?.amount_left, "THENNNN");
  const data = {
    labels: ["Available", "Invested"],
    datasets: [
      {
        label: "Investment",
        data: [
          loan?.amount_left,
          loan ? loan!.amount_granted - loan!.amount_left : 0,
        ],
        backgroundColor: ["#D09600", "#1C21B2"],
        borderColor: ["#D09600", "#1C21B2"],
        borderWidth: 1,
      },
    ],
  };

  let chartState: any = {
    series: [34, 60, 6],
    options: {
      chart: {
        type: "donut",
      },
      fill: {
        colors: ["#1C21B2", "#D09600", "#494949"],
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const { id } = useParams();

  console.log(id, "LOAN ID");
  const getLoanDetails = async () => {
    try {
      const response = await axios.get(`user-loans/details/${id}`);

      console.log(response.data);
      setLoan(response.data.loan);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getLoanDetails();
  }, [id]);
  return (
    <div className="h-[100%] w-[100%] sm:pt-[55px] sm:pb-[55px] sm:px-[55px] pt-[95px] px-[31px] bg-[#F9F9F9]">
      <div className="flex sm:flex-row flex-col sm:items-center justify-between mb-[25px]">
        <h5 className="font-[600] sm:text-[24px] text-[18px]  text-[#292D32]">
          Loan : <span className="uppercase">{id}</span>
        </h5>
        <span className="font-[700] sm:text-[16px] text-[14px] text-[#292D32]">
          Available for investment:{" "}
          <span className="sm:text-[24px] text-[18px] font-[600]">
            &#8358;{loan?.amount_left.toLocaleString()}
          </span>
        </span>
      </div>

      <div className="max-[620px]:max-w-[calc(100vw-62px)]">
        <div className="h-[50px] bg-[#D09600] w-[100%] rounded-t-[5px]"></div>
      </div>

      <div className="flex sm:flex-row flex-col py-[42px] bg-[#fff] sm:px-[64px] px-[20px] max-[620px]:max-w-[calc(100vw-62px)]">
        <div className="flex-1 sm:mr-[61px]">
          <div className="mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Loan amount
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600]">
              &#8358;{loan?.amount_granted.toLocaleString()}
            </h6>
          </div>
          <div className="mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Term months
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600]">
              {loan?.approved_tenure}
            </h6>
          </div>
          <div className="sm:mb-[115px] mb-[50px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Date of Issue
            </span>
            <h6 className="text-[#292D32] sm:text-[30px] text-[18px] font-[600]">
              {loan && new Date(loan?.createdAt).toDateString()}
            </h6>
          </div>

          <span className="block sm:text-[16px] text-[12px]  font-[400] text-[#000000E5] sm:block hidden">
            This loan has a buy Guarantee. The loan originator will buy back the
            loans from investors that are more than 60 days
          </span>
        </div>
        <div className="flex-1">
          <div className="mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Interest rate
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600]">
              {loan?.interest_rate}%
            </h6>
          </div>
          <div className="mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Loan status
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600] capitalize">
              {loan?.loan_status}
            </h6>
          </div>
          <div className="sm:mb-[115px] mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Final payment date
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600]">
              {loan &&
                new Date(
                  new Date().setMonth(
                    new Date().getMonth() + loan.approved_tenure
                  )
                ).toDateString()}
            </h6>
          </div>
          <div className="sm:mb-[115px] mb-[31px]">
            <span className="text-[#D09600] block sm:text-[14px] text-[12px] font-[700] mb-[14px]">
              Loan lender
            </span>
            <h6 className="text-[#292D32] sm:text-[32px] text-[18px] font-[600]">
              {loan?.user.account_type === "partner"
                ? loan?.user.name
                : "Monzone"}
            </h6>
          </div>
        </div>

        <div className="sm:ml-[50px] flex-1">
          <Doughnut data={data} />
          {/* <ReactApexChart
            options={chartState.options}
            series={chartState.series}
            type="donut"
            width={251.82}
            height={251.82}
          /> */}

          <div className="flex mb-[32px]">
            <div className="bg-[#D09600] sm:h-[14.29px] sm:w-[14.29px] h-[10px] w-[10px] rounded-full mt-[5px]" />
            <div className="ml-[13.7px]">
              <span className="block font-[700] sm:text-[16px] text-[13px] mb-[11px]">
                Available for Investment
              </span>
              <h6 className="text-[#292D32] sm:text-[24px] text-[18px] font-[600]">
                &#8358;{loan?.amount_left.toLocaleString()}/
                {loan?.interest_rate}%
              </h6>
            </div>
          </div>
          {/* <div className="flex mb-[32px]">
            <div className="bg-[#D9D9D9] sm:h-[14.29px] sm:w-[14.29px] h-[10px] w-[10px] rounded-full mt-[5px]" />
            <div className="ml-[13.7px]">
              <span className="block font-[700] sm:text-[16px] text-[13px] mb-[11px]">
                Skin in the game
              </span>
              <h6 className="text-[#292D32] sm:text-[24px] text-[18px] font-[600]">
                &#8358;1,000/0.5%
              </h6>
            </div>
          </div> */}
        </div>

        <span className="block sm:text-[16px] text-[12px]  font-[400] text-[#000000E5] sm:hidden block">
          This loan has a buy Guarantee. The loan originator will buy back the
          loans from investors that are more than 60 days
        </span>
      </div>

      {/* <h5 className="font-[600] sm:text-[24px] text-[16px] mt-[68px] mb-[30px]  text-[#292D32]">
        Payment schedule
      </h5>

      <div className=" overflow-x-auto mt-[12px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)]">
        <table className="w-full text-[12px] text-left text-[#292D32] ">
          <thead className="sm:text-[16px] text-[#fff] font-[700] bg-[#D09600] ">
            <tr>
              <th scope="col" className="px-6 py-3">
                Due date
              </th>
              <th scope="col" className="px-6 py-3">
                Principal
              </th>
              <th scope="col" className="px-6 py-3">
                Interest
              </th>
              <th scope="col" className="px-6 py-3">
                Total
              </th>
              <th scope="col" className="px-6 py-3">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {[0, 0, 0, 0, 0].map((_, index) => (
              <tr
                className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                key={index}
              >
                <td className="px-6 py-4">24-45-750</td>
                <td className="px-6 py-4">11.22</td>

                <td className="px-6 py-4">1.5</td>

                <td className="px-6 py-4">40.32</td>
                <td className="px-6 py-4 text-[#189B62] ">Active</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="h-[50px] bg-[#fff] w-[100%] rounded-b-[10px] mb-[100px]" />
      </div> */}
    </div>
  );
}

export default LoanDetails;
