import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { IUser } from "../business-dashboard/loan";
import { logout } from "../../utils/logout";
interface HeaderProps {
  background_color?: string;
  logo_image?: string;
  text_color?: string;
}
function AuthHeader({ background_color, text_color, logo_image }: HeaderProps) {
  const [showMenu, setShowMenu] = useState(false);
  const [dashboard, setDashboard] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const currentUser: IUser = useSelector((state: any) => state.user.user);
  const [showProducts, setShowProducts] = useState(false);

  useEffect(() => {
    if (currentUser?.currentUser != null) {
      setIsLoggedIn(true);
      // const db =  === "Admin" ? "/admin/dashboard"

      setDashboard(
        `/${currentUser.currentUser?.account_type?.toLowerCase() === "business"
          ? "loan"
          : currentUser?.currentUser?.account_type.toLowerCase()
        }/dashboard`
      );
    } else {
      setIsLoggedIn(false);
    }
  }, [currentUser]);

  const navigate = useNavigate();
  return (
    <>
      <div
        className={`px-[48px] w-[100%]  h-[100px] ${background_color ? background_color : "bg-[#fff]"
          } flex items-center justify-between mb-[50px]`}
      >
        <Link to={"/"}>
          <img
            alt="logo"
            src={logo_image ? logo_image : "/images/logo.svg"}
            className="w-[171.9px] h-[47.1px]"
          />
        </Link>
        <div className="sm:flex items-center hidden">
          {[
            { title: "Home", href: "/" },
            { title: "About", href: "/about" },
            // {
            //   title: isLoggedIn ? "" : "Invest",
            //   href: isLoggedIn ? "/auth/register" : "",
            // },
            { title: "Contact", href: "/about#contact" },
            { title: "Blog", href: "/blog" },
            {
              title:
                isLoggedIn &&
                  currentUser?.currentUser?.account_type?.toLowerCase() === "admin"
                  ? "Create Blog"
                  : "",
              href: isLoggedIn ? "/blog/create" : "",
            },
          ].map(
            ({ title, href }, index) =>
              title.length > 1 && (
                <Link
                  to={href}
                  key={index}
                  className={`sm:text-[20px] text-[14px] ${text_color ? text_color : ""
                    } font-[500] sm:mr-[72px] mr-[20px]`}
                >
                  {title}
                </Link>
              )
          )}

          <ul className="relative sm:text-[20px] text-[14px] font-[500] text-[#fff]">
            <li
              className="flex items-center "
              onClick={() => setShowProducts(!showProducts)}
            // onMouseEnter={() => setShowProducts(true)}
            // onMouseLeave={() => {
            //   setTimeout(() => {

            //   })
            //   setShowProducts(false)
            // }
            // }
            >
              <a href="/#" className="mr-[10px]">
                Products
              </a>
              <img
                alt="show"
                src="/images/caret-down-white.svg"
                className="sm:w-[15px] sm:h-[15px]"
              />
            </li>

            {showProducts && (
              <div className="mt-[20px] z-[100000000000000] w-[200px] absolute sm:text-[16px] text-[10px] flex flex-col justify-between h-[100px]">
                {[
                  {
                    title: isLoggedIn ? "" : "SME Loan",
                    href: isLoggedIn ? dashboard : "/auth/register",
                  },
                  {
                    title: isLoggedIn ? "" : "LPO Finance",
                    href: isLoggedIn ? dashboard : "/auth/register",
                  },
                  {
                    title: isLoggedIn ? "" : "Invoice Sale",
                    href: isLoggedIn ? dashboard : "/auth/register",
                  },
                  {
                    title: isLoggedIn ? "" : "Investment Marketplace",
                    href: isLoggedIn ? dashboard : "/auth/register",
                  },
                ].map(({ href, title }, index) => (
                  <Link
                    to={href}
                    className="mb-[10px]"
                    key={index}
                    onClick={() => {
                      localStorage.setItem("pre-action", title);
                    }}
                  >
                    {title}
                  </Link>
                ))}

                {/* <Link to={"/"} className="mb-[10px]">
                  LPO Finance
                </Link>
                <Link to={"/"} className="mb-[10px]">
                  Invoice Sale
                </Link>
                <Link to={"/"} className="mb-[10px]">
                  Investment Marketplace
                </Link> */}
              </div>
            )}
          </ul>
        </div>

        <div className="sm:flex items-center hidden">
          {[
            {
              title: isLoggedIn ? "Dashboard" : "Login",
              href: isLoggedIn ? dashboard : "/auth/login",
            },
            {
              title: isLoggedIn ? "Logout" : "Sign up",
              href: isLoggedIn ? "/auth/login" : "/auth/register",
            },
          ].map(({ title, href }, index) => (
            <Link
              onClick={async () => {
                if (isLoggedIn && title === "Logout") {
                  await logout();
                  window.location.reload();
                  navigate(href);
                }
              }}
              // @ts-ignore
              to={isLoggedIn && title === "Logout" ? null : href}
              key={index}
              className={`${text_color ? text_color : ""} ${index === 1
                  ? "text-[#fff]  bg-[#D09600] py-[12px] px-[25px] rounded-[8px] "
                  : "sm:mr-[72px] mr-[20px]"
                } sm:text-[20px] text-[14px] font-[500] `}
            >
              {title}
            </Link>
          ))}
        </div>
        <div className="sm:hidden">
          <button
            className={`sm:hidden ${showMenu ? "" : ""}`}
            onClick={() => setShowMenu(!showMenu)}
          >
            <img
              src={
                showMenu ? "/images/close-circle-white.svg" : "/images/menu.png"
              }
              className="sm:hidden block w-[30px] h-[30px] object-contain"
              alt="menu"
            />
          </button>
        </div>
      </div>

      {showMenu && (
        <motion.ul
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className={`flex flex-col items-center pt-[75px] z-[1000] top-0 w-[100%] min-h-[calc(100vh+75px)] fixed mt-[-60px] text-[#fff] block sm:hidden font-[600] text-[14px] pb-[20px] ${background_color ? "bg-[#150F00]" : "bg-[#f7c677af]"
            }`}
        >
          <div className="w-[100%] flex justify-end pr-[40px] mb-[100px]  mt-[32px]">
            <button
              className={`sm:hidden ${showMenu ? "" : ""}`}
              onClick={() => setShowMenu(!showMenu)}
            >
              <img
                src={
                  showMenu
                    ? "/images/close-circle-white.svg"
                    : "/images/menu.png"
                }
                className="sm:hidden block w-[21.5px] h-[21.5px] object-contain"
                alt="menu"
              />
            </button>
          </div>
          <li
            onClick={() => {
              setShowMenu(false);
            }}
            className={` px-[15px] rounded-[5px] py-2.5 header-item mb-[46px]`}
          >
            <a href="/about">About</a>
          </li>
          {!isLoggedIn && (
            <li
              onClick={() => {
                setShowMenu(false);
              }}
              className={` px-[15px] rounded-[5px] py-2.5 header-item mb-[46px]`}
            >
              <a href="/auth/register">Invest</a>
            </li>
          )}
          <li
            onClick={() => {
              setShowMenu(false);
            }}
            className={` px-[15px] rounded-[5px] py-2.5 header-item mb-[46px]`}
          >
            <a href="/about/#contact">Contact</a>
          </li>
          <li
            onClick={() => {
              setShowMenu(false);
            }}
            className={` px-[15px] rounded-[5px] py-2.5 header-item mb-[46px]`}
          >
            <a href="/blog">Blog</a>
          </li>

          <li
            onClick={() => {
              setShowMenu(false);
            }}
            className={` px-[15px] rounded-[5px] py-2.5 header-item mb-[46px]`}
          >
            <Link
              to={"/auth/login"}
              className={`text-[14px] font-[500] mb-[46px]`}
            >
              Login
            </Link>
          </li>
          <li
            onClick={() => {
              setShowMenu(false);
            }}
            className={` px-[15px] rounded-[5px] py-2.5 header-item`}
          >
            <Link
              to={"/auth/register"}
              className={
                "text-[#fff]  bg-[#D09600] py-[12px] px-[25px] rounded-[8px]  text-[14px] font-[500] "
              }
            >
              Sign up
            </Link>
          </li>
        </motion.ul>
      )}
    </>
  );
}

export default AuthHeader;
