import React from "react";
import { Modal } from "./Modal";

function Loader() {
  return (
    <Modal>
      <div className="loading bg-[#fff] drop-shadow-xl p-3 rounded-full min-w-[130px] min-h-[130px] flex items-center justify-center">
        <img alt="logo" src="/images/logo-mini.svg" />
      </div>
    </Modal>
  );
}

export default Loader;
