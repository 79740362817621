import { useEffect, useState } from "react";
import { ILoan } from "../business-dashboard/sme";
import Loader from "../../components/Loader";
import axios from "axios";

function InvestorDashboard() {
  const [loans, setLoans] = useState<ILoan[]>([]);
  const [loading, setLoading] = useState(false);
  const [investment, setInvestment] = useState<{
    investment_amount: number;
    investment_number: number;
  }>({ investment_amount: 0, investment_number: 0 });

  const getInvestments = async () => {
    setLoading(true);
    try {
      const res = await axios.get("user-loans/investments/all");

      console.log(res.data);
      setInvestment(res.data.investment);

      setLoans(res.data.loans);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    getInvestments();

    return () => {
      controller.abort();
    };
  }, []);

  if (loading) {
    return <Loader />;
  }
  return (
    <div className="h-[100%] w-[100%] sm:pt-[55px] sm:pb-[55px] sm:px-[55px] pt-[95px] px-[31px] bg-[#F9F9F9]">
      <h5 className="font-[500] text-[24px] mb-[25px] sm:block hidden">
        Welcome back!
      </h5>
      <h5 className="font-[700] text-[16px] sm:hidden block mb-[18px]">
        Investment
      </h5>

      <div className="max-[620px]:max-w-[calc(100vw-62px)] overflow-x-scroll w-[100%]">
        <div className="w-[100%] flex items-center mb-[56px]">
          <div className="flex text-[#fff] bg-[#533C00C4] rounded-[10px] sm:py-[45px] max-[620px]:min-w-[230px] max-[620px]:h-[119px] max-[620px]:items-center  sm:pl-[44px] pl-[15px] sm:pr-[38px]">
            <img
              alt="icon"
              src="/images/investment-icon.svg"
              className="sm:min-h-[90px] sm:min-w-[90px] max-[620px]:w-[48px] max-[620px]:h-[48px]"
            />

            <div className="sm:ml-[55px] ml-[13px]">
              <span className="sm:text-[24px] text-[14px] font-[600] mb-[13px]">
                Investment amount
              </span>
              <h4 className="sm:text-[48px] text-[24px] font-[700]">
                &#8358;{investment?.investment_amount?.toLocaleString()}
              </h4>
            </div>
          </div>

          <div className="ml-[53px] flex text-[#292D32] bg-[#FFF] rounded-[10px] sm:py-[45px] max-[620px]:min-w-[230px] max-[620px]:h-[119px] max-[620px]:items-center sm:pl-[44px] pl-[15px] sm:pr-[38px]">
            <img
              alt="icon"
              src="/images/investment-number-icon.svg"
              className="sm:min-h-[90px] sm:min-w-[90px] max-[620px]:w-[48px] max-[620px]:h-[48px]"
            />

            <div className="sm:ml-[55px] ml-[13px]">
              <span className="sm:text-[24px] text-[14px] font-[600] mb-[13px]">
                Investment number
              </span>
              <h4 className="sm:text-[48px] text-[24px] font-[700]">
                {investment.investment_number.toLocaleString()}
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="sm:hidden flex items-center mb-[15px] mt-[-35px]">
        <img alt="scroll" src="/images/hand-scroll.svg" />
        <span className="ml-[12px] font-[500] text-[#292D32] text-[12px]">
          Swipe horizontal to see more details
        </span>
      </div>
      <div className=" justify-between flex items-center">
        <h4 className="sm:text-[24px] text-[16px] font-[500]">
          Investment history
        </h4>

        <div className="flex items-center">
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            Show
          </span>
          <select
            id="months"
            className="mx-[9.65px] bg-[#D9D9D900] sm:text-[14px] text-[10px] border-[0.97px] border-[#0000003B] text-[#000000A1]  outline-none text-[12.7px] font-[600] rounded-[5.38px] block sm:w-[88.14px] w-[50px] px-[2.3px] py-[7.8px]"
          >
            {[10, 15, 20, 30, 50].map((v, i) => (
              <option key={i}> {v}</option>
            ))}
          </select>
          <span className="text-[#000000A1] font-[600] sm:text-[14px] text-[12px]">
            List
          </span>
        </div>
      </div>
      <div className=" overflow-x-auto mt-[12px] rounded-t-[10px] max-[620px]:max-w-[calc(100vw-62px)]">
        <table className="w-full text-[12px] text-left text-[#292D32]">
          <thead className="sm:text-[16px] font-[700] bg-[#F6EACC] ">
            <tr>
              <th scope="col" className="px-6 py-3 min-w-[150px]">
                Transaction Code
              </th>
              <th scope="col" className="px-6 py-3">
                Name
              </th>
              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Interest rate
              </th>
              {/* <th scope="col" className="px-6 py-3">
                Option
              </th> */}
            </tr>
          </thead>
          {loans.length ? (
            <tbody>
              {loans.map((loan, index) => (
                <tr
                  className="cursor-pointer text-[#292D32] bg-[#fff] border-b-[0.5px] border-b-[#292D3280] font-[400] sm:text-[16px] "
                  key={index}
                >
                  <td className="px-6 py-4">{loan.id}</td>
                  <td className="px-6 py-4">{loan.user.name}</td>

                  <td className="px-6 py-4">{loan.amount_granted}</td>

                  <td className="px-6 py-4">{loan.interest_rate}%</td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <h4 className="font-[600] text-gray-700 px-4 py-4">
                No Investments
              </h4>
            </tbody>
          )}
        </table>
      </div>
      {/* <div className="flex items-center justify-between mt-[28px]">
        <span className="sm:text-[16px] text-[12px] text-[#000000A1]">
          Showing 1-30 of list
        </span>

        <div className="flex items-center ">
          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-back.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
          {[1, 2, 3, 4].map((val, index) => (
            <button
              key={index}
              className="hover:bg-[#F5F5F5] drop-shadow-sm border-[0.1px] border-[#D09600] bg-[#fff] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] h-[20px] w-[20px] flex items-center justify-center rounded-[3.46px] sm:text-[14px] text-[11px]"
            >
              {val}
            </button>
          ))}

          <button className="active:bg-[#d09500ca] bg-[#D09600] mr-[10px] sm:w-[31.13px] sm:h-[31.13px] w-[20px] h-[20px] flex items-center justify-center rounded-[3.46px]">
            <img
              src="/images/caret-right.svg"
              alt="icon"
              className="max-[620px]:w-[10px] max-[620px]:h-[10px]"
            />
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default InvestorDashboard;
