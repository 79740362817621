import { useEffect, useState } from "react";
import "react-calendar/dist/Calendar.css";
import { motion } from "framer-motion";
import ReactApexChart from "react-apexcharts";
import { Modal } from "../../components";
import { toast } from "react-toastify";
import { convertToBase64 } from "../../utils/base64-converter";
import { ILoan } from "./sme";
import { errorToastMessage } from "../../utils/toast-message";
import axios from "axios";

interface ModalProps {
  invoiceAmount: string;
  setInvoiceAmount: (value?: any) => void;
  setModalType: (value?: any) => void;
  setShowModal: (value?: any) => void;
  modalType: string;
  showModal: boolean;
}

const ApplyForLoanModal = ({
  invoiceAmount,
  setInvoiceAmount,
  setModalType,
  setShowModal,
  modalType,
}: ModalProps) => {
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<any>();
  const MAX_FILE_SIZE = 1048576;

  const apply = async () => {
    let invoices = await convertToBase64(invoice);
    let amount_requested =
      parseFloat(invoiceAmount) - parseFloat(invoiceAmount) * 0.055;

    setLoading(true);
    let body = {
      amount_requested,
      loan_type: "invoice",
      invoice: invoices,
    };

    try {
      const response = await axios.post("user-loans/apply", body);

      console.log(response.data);
      setLoading(false);
      setModalType("processing");
    } catch (error: any) {
      setLoading(false);
      // let errorMessage = error?.response?.data?.errors[0];
      // console.log(errorMessage);
      errorToastMessage(error);
      // toast.error(errorMessage ? errorMessage.message : "An Error Occurred!", {
      //   position: "top-right",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      // });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="overflow-y-scroll rounded-sm md:w-[700px] bg-[#fff] px-[5%] pb-[5%] pt-[55px]"
    >
      <div className="flex w-[100%] items-center justify-between mb-[60px]">
        <div className="w-[10px]" />
        <h4 className="text-[#33323D] font-[600] sm:text-[24px] text-[18px]">
          {modalType === "apply"
            ? "Apply for Invoice discounting"
            : modalType === "review"
            ? "Review and apply"
            : ""}
        </h4>
        <button
          onClick={() => setShowModal(false)}
          className="active:scale-75 transform-gpu"
        >
          <img alt="close" src="/images/close-circle.svg" />
        </button>
      </div>

      {modalType === "apply" ? (
        <div className="px-[5%] w-[100%] flex flex-col">
          <div className="w-[100%] mt-[45px] mb-[23px]">
            <label
              htmlFor="amount"
              className="text-[#292D32] sm:text-[20px] text-[14px] font-[600]"
            >
              Invoice amount
            </label>

            <input
              id="amount"
              type={"number"}
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] text-[14px] sm:text-[16px]"
              onChange={(e) => setInvoiceAmount(e.target.value)}
            />
            <span className="block sm:text-[11px] text-[10px]">
              Discount percentage = 5.5%{" "}
            </span>
          </div>
          <div className="w-[100%] mb-[23px]">
            <label
              htmlFor="amount"
              className="text-[#292D32] sm:text-[20px] text-[14px] font-[600]"
            >
              Sell price
            </label>

            <input
              disabled
              id="amount"
              type={"number"}
              className="px-[26px] sm:h-[50px] h-[40px] outline-none border-[1px] w-[100%] border-[#000000] rounded-[8.43px] text-[14px] sm:text-[16px] "
              value={
                invoiceAmount
                  ? parseFloat(invoiceAmount) -
                    parseFloat(invoiceAmount) * 0.055
                  : ""
              }
            />
          </div>

          <div className="sm:text-[20px] text-[14px] text-[#333]">
            Upload Invoice
            <input
              type={"file"}
              className="hidden"
              id="upload"
              onChange={(e) => {
                if (e.target.files && e.target.files[0]) {
                  if (e.target.files[0].size > MAX_FILE_SIZE) {
                    toast.error("File size cannot be greater than 1mb", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    return;
                  }
                  setInvoice(e.target.files[0]);
                }
              }}
            />
            <label
              className="sm:text-[16px] text-[14px] active:bg-[#f5f5f5] flex items-center text-[#1E1E1E] mt-[14px] block px-[26px] sm:h-[50px] h-[40px] border-[#292D32] border-[1px] rounded-[8.43px]"
              htmlFor="upload"
            >
              {invoice ? invoice.name : "Choose file"}
            </label>
          </div>

          <button
            disabled={loading || !invoiceAmount || !invoice}
            onClick={apply}
            className={`flex items-center justify-center rounded-[8.43px] mt-[34px] self-center active:bg-[#d09500b3] bg-[#D09600] text-[#fff] sm:text-[20px] text-[14px] font-[600] px-[129px] sm:h-[64px] h-[45px] ${
              !invoice || !invoiceAmount ? "opacity-10" : "opacity-100"
            }`}
          >
            {loading && (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
            {loading ? "Processing..." : "Apply"}
          </button>
        </div>
      ) : (
        <div className="px-[5%] w-[100%] flex flex-col items-center">
          <img
            alt="processing"
            src="/images/processing-icon.svg"
            className="mb-[50px]"
          />
          <h5 className="text-center font-[600] sm:text-[24px] text-[18px] mb-[15px]">
            Application processing
          </h5>
          <p className="text-center sm:text-[20px] text-[14px] mb-[60px]">
            you will be notified on the status of your application
          </p>
          <button
            onClick={() => setShowModal(false)}
            className="rounded-[8.43px] self-center active:bg-[#d09500b3] bg-[#D09600] sm:ml-[37px] text-[#fff] sm:text-[24px] text-[15px] font-[600] px-[76px] py-[15px]"
          >
            Confirm
          </button>
        </div>
      )}
    </motion.div>
  );
};

function Invoice() {
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [invoice_amount, setInvoiceAmount] = useState("");

  const [myLoans, setMyLoans] = useState<ILoan[]>([]);

  const getLoans = async () => {
    try {
      const response = await axios.get("user-loans/?loan_type=invoice");
      console.log(response.data, myLoans);
      setMyLoans(response.data.loans);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLoans();
  }, []);

  const data: any = {
    series: [
      {
        color: "#D09600",
        data: [44, 55, 41, 64, 22, 43, 21],
      },
      {
        color: "#533C00",

        data: [53, 32, 33, 52, 13, 44, 32],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 430,
      },
      plotOptions: {
        bar: {
          // horizontal: true,
          // dataLabels: {
          //     position: 'top',
          // },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"],
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      xaxis: {
        categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      },
    },
  };

  return (
    <>
      <div className="flex sm:flex-row flex-col h-fit w-[100%] sm:p-[55px] p-[30px] pb-[80px] transition-opacity ease-in duration-300 bg-[#F5F5F5]">
        <div className="mb-[30px] w-[100%] flex sm:hidden flex-col items-center px-[36px]  sm:pt-[26px] pt-[22px] pb-[36px] bg-[#fff] mb-[48px] rounded-[10px]">
          <p className="text-[#292D32] sm:mb-[35px] mb-[25px] text-center sm:text-[16px] text-[14px]">
            Due balance
          </p>

          <h4 className="font-[600] sm:text-[48px] text-[36px] text-[#000000C7] text-center">
            ₦25,369
          </h4>
        </div>

        <div className="rounded-[10px] w-[100%] bg-[#533C00] py-[42px] flex sm:hidden flex-col text-[#fff] items-center">
          <p className="font-[600] sm:text-[24px] text-[14px] mb-[21px]">
            Instant Invoice Discounting
          </p>

          <button
            onClick={() => {
              setShowModal(true);
              setModalType("apply");
            }}
            className="px-[39px] py-[12px] active:bg-[#eae9e9] bg-[#fff] w-fit rounded-[10px] text-[#292D32C7]  sm:text-[20px] text-[14px] font-[600]"
          >
            Apply
          </button>
        </div>

        <div className="sm:flex-[8]">
          <div className="rounded-[10px] w-[100%] bg-[#533C00] py-[42px] sm:flex hidden flex-col text-[#fff] items-center">
            <p className="font-[600] text-[24px] mb-[21px]">
              Instant Invoice Discounting
            </p>

            <button
              onClick={() => {
                setShowModal(true);
                setModalType("apply");
              }}
              className="px-[39px] py-[12px] active:bg-[#eae9e9] bg-[#fff] w-fit rounded-[10px] text-[#292D32C7]  text-[20px] font-[600]"
            >
              Apply
            </button>
          </div>

          <div className="mt-[40px]  justify-between flex items-center">
            <h4 className="text-[24px] max-[620px]:hidden font-[500] text-[#252525]">
              Invoices
            </h4>
          </div>
          <div className=" overflow-x-auto mt-[12px] rounded-[10px] bg-[#fff]">
            <div className="flex mb-[54px] h-[60px] px-[16px] items-center border-b-[1px] border-b-[#E7E8F2]">
              <span className="font-[500] text-[14px]">Revenue</span>
            </div>

            <div className="mb-[60px] flex px-[16px] items-center justify-between">
              <span className="text-[#33343D] text-[14px] ">Monthly</span>
              <div className="flex items-center">
                <div className="flex items-center ml-[10px]">
                  <div className="bg-[#D09600] h-[12px] w-[12px] rounded-full" />
                  <span className="text-[#25233A] font-[200] text-[12px] ml-[5px]">
                    Product 1
                  </span>
                </div>
                <div className="flex items-center ml-[10px]">
                  <div className="bg-[#533C00] h-[12px] w-[12px] rounded-full" />
                  <span className="text-[#25233A] font-[200] text-[12px] ml-[5px]">
                    Product 1
                  </span>
                </div>
              </div>
            </div>
            <ReactApexChart
              options={data.options}
              series={data.series}
              type="bar"
              height={430}
            />
          </div>
        </div>

        <h4 className="sm:text-[24px] text-[16px] min-[620px]:hidden font-[500] text-[#252525] mt-[34px] mb-[10px]">
          Invoices
        </h4>

        {showModal && (
          <Modal>
            <ApplyForLoanModal
              invoiceAmount={invoice_amount}
              setInvoiceAmount={setInvoiceAmount}
              setModalType={setModalType}
              setShowModal={setShowModal}
              modalType={modalType}
              showModal={showModal}
            />
          </Modal>
        )}
      </div>
      <div className="w-[100%] h-[100px] bg-[#F5F5F5]" />
    </>
  );
}

export default Invoice;
