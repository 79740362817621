/* eslint-disable no-useless-escape */
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Modal } from "../../components"
import AuthHeader from "./auth-header"

import "./styles.css"
import {
  errorToastMessage,
  successToastMessage
} from "../../utils/toast-message"
import axios from "axios"
import OtpPopUp from "../../components/otp-popup"
import { toast } from "react-toastify"

function ResetPassword() {
  var preAction = localStorage.getItem("pre-action")

  if (preAction) {
    if (preAction === "Investment Marketplace") {
      preAction = "investor"
    } else {
      preAction = "business"
    }
  }

  const navigate = useNavigate()

  const [email, setEmail] = useState("")
  const [otp, setOtp] = useState("")
  const [bvn, setBvn] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [showOtpPopup, setShowOtpPopup] = useState(false)
  const [loading, setLoading] = useState(false)

  const requestOtp = async (e: any) => {
    e.preventDefault()
    try {
      if (email) {
        const response = await axios.patch("users/reset-password/start", {
          email
        })

        console.log(response?.data)
        if (response?.data?.message.includes("Password Reset Otp Sent!")) {
          toast.success(response.data.message, {
            position: "top-right",

            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light"
          })
        }
      } else {
        let errorMessage = "Email is required"
        console.log(errorMessage)

        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })

        setShowOtpPopup(true)
      }
    } catch (error: any) {
      console.log(error?.response?.data?.errors[0]?.message)
      let errorMessage = error?.response?.data?.errors[0]?.message

      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })
    }
  }

  const resetPasswordComplete = async (e: any) => {
    try {
      e.preventDefault()
      if (password !== confirmPassword) {
        toast.error("Passwords do not Match", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })
      } else {
        setLoading(true)
        const response = await axios.patch("users/reset-password", {
          otp,
          password
        })

        toast.success(response.data.message, {
          position: "top-right",

          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })

        setLoading(false)
      }
    } catch (error: any) {
      const errMsg = error.response.data.errors[0].message
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })
    }
  }

  return (
    <div className="h-[100%]">
      <AuthHeader />
      <div className="flex px-[48px] h-[80%] flex-wrap">
        <div className="login-side sm:flex sm:item-center sm:justify-center hidden mr-[100px] rounded-[20px] flex-1 w-[100%] h-[100%]  hidden ">
          <img alt="icon" src="/images/login-image.svg" className="" />

          <div className="absolute top-[20%] left-[40%] rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px] h-[130px]">
            <h6 className="mb-[6px] text-[#292D32] text-[16px]">
              Loan Request
            </h6>

            <div className="px-[9px] mb-[11px] text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
            <div className="px-[9px]  text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
          </div>

          <div className="bottom-[15%] xl:left-[15%] left-[10%] absolute rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px]">
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Tenure</span>
              <span className="">3 months</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Amount</span>
              <span className="">₦50,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Interest</span>
              <span className="">₦1,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Total payback</span>
              <span className="">₦51,000</span>
            </div>

            <button className="mt-[27px] text-[11.6px] outline-none bg-[#D09600] text-[#fff] py-[9px] w-[100%] rounded-[5.8px]">
              Request loan
            </button>
          </div>
        </div>

        <form className="flex-1 flex-col flex" onSubmit={resetPasswordComplete}>
          <div className="mb-[59px]">
            <h4 className=" md:text-[48px] text-[24px] sm:text-left text-center font-[700]">
              Reset Your Password
            </h4>
          </div>

          <label className="sm:text-[20px] text-[16px]">
            Enter email address
            <input
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
              type={"email"}
              placeholder="name@mail.com"
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="sm:text-[16px] text-[14px] mt-[-20px] flex justify-end items-center mb-[8px]">
            <button
              onClick={requestOtp}
              className="text-[#D09600] md:text-left text-left"
            >
              Request Otp
            </button>
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Enter Otp
            <input
              value={otp}
              placeholder="111113"
              required
              onChange={(e) => setOtp(e.target.value)}
              type={"number"}
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Enter new passwrd
            <input
              maxLength={11}
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={"text"}
              placeholder="Monzone123#"
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>

          <label className="sm:text-[20px] text-[16px]">
            Confirm passwrd
            <input
              maxLength={11}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={"text"}
              placeholder="Monzone123#"
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>

          <button
            type="button"
            className="flex items-center w-[100%] sm:mb-[45px] mb-[30px]"
          ></button>
          {/* <label className="text-[16px] check-label">
              <input
                onChange={(e) => {
                  setHasAccepted(e.target.checked);
                }}
                type={"checkbox"}
                className="outline-none mr-[0px]"
              />
              <span className="mt-[-2px] text-[12px] sm:text-[14px] leading-[16px]">
                {" "}
                By Clicking “Continue”, you have agreed to the{" "}
                <a href="/" className="text-[#D09600]">
                  Terms and Conditions
                </a>{" "}
                of MonzoneCredit
              </span>
            </label> */}
          <button
            type="submit"
            className={` opacity-100 rounded-[8.43px] h-[62px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] text-[20px] text-[#fff]`}
          >
            Continue
          </button>

          <span className="text-center sm:text-[16px] text-[12px] mt-[19px] mb-[50px]">
            Already have an account?{" "}
            <Link to={"/auth/login"} className="text-[#D09600]">
              Login
            </Link>
          </span>
        </form>
      </div>
    </div>
  )
}

export default ResetPassword
