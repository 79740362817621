import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notification: [],
  },
  reducers: {
    updateNotification: (state, action) => {
      state.notification = { ...action.payload };
    },
  },
});

export const { updateNotification } = notificationSlice.actions;

export default notificationSlice.reducer;
