import { configureStore } from "@reduxjs/toolkit";
import sidebarReducer from "./sidebar-reducer";
import userReducer from "./user-reducer";
import notificationReducer from "./notification-reducer";

export default configureStore({
  reducer: {
    sidebar: sidebarReducer,
    user: userReducer,
    notification: notificationReducer,
  },
});
