// import React, { useState } from 'react'
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { IUser } from "../pages/business-dashboard/loan";
import { logout } from "../utils/logout";

export default function Sidebar() {
  const currentUser: IUser = useSelector((state: any) => state.user.user);

  const [showLoanTypes, setShowLoanTypes] = useState(false);
  const [showUserTypes, setShowUserTypes] = useState(false);
  // const [currentMenu, setCurrentMenu] = useState('Dashboard')
  const [accountType, setAccountType] = useState(
    currentUser?.currentUser?.account_type
  );
  const location = useLocation();
  const navigate = useNavigate();

  let path = location.pathname;

  useEffect(() => {
    setAccountType(currentUser?.currentUser?.account_type);
  }, [currentUser?.currentUser]);

  const loanReqestMenu = [
    {
      title: "SME Loans",
      link: "/loan-requests/sme",
    },
    {
      title: "Local Purchase Order",
      link: "/loan-requests/lpo",
    },
    {
      title: "Invoice",
      link: "/loan-requests/invoice",
    },
  ].map(({ title, link }, index) => (
    <Link
      to={link}
      className={`block px-[44px] ${
        path.includes(link) ? "text-[#D09600]" : ""
      }  ${
        index === 0 ? "mt-[-10px]" : index === 2 ? "mb-[20px]" : ""
      } ml-[6px] mb-[16px]`}
    >
      {title}
    </Link>
  ));

  const userTypeMenu = [
    {
      title: "All Users",
      link: "/users",
    },
    {
      title: "Partners",
      link: "/users/partners",
    },
  ].map(({ title, link }, index) => (
    <Link
      to={link}
      className={`block px-[44px] ${path === link ? "text-[#D09600]" : ""}  ${
        index === 0 ? "mt-[-10px]" : index === 2 ? "mb-[20px]" : ""
      } ml-[6px] mb-[16px]`}
    >
      {title}
    </Link>
  ));
  return (
    <div className="sidebar overflow-y-scroll z-[10000] fixed left-0 h-[100%] bg-[#fff] w-[280px] pt-[51px] pb-[39px] flex flex-col items-center">
      <Link to={"/"}>
        <img src="/images/logo.svg" alt="logo" />
      </Link>
      {/* Dashboard Nav Items */}
      <div className="flex flex-col justify-between w-[100%] h-[100%]">
        <div className="w-[100%] mt-[100px]">
          <button
            onClick={() =>
              accountType === "business"
                ? navigate("/loan/dashboard")
                : accountType === "investor"
                ? navigate("/investor/dashboard")
                : accountType === "admin"
                ? navigate("/admin/dashboard")
                : navigate("/partner/dashboard")
            }
            className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
              (accountType === "business" && path.includes("loan")) ||
              path.includes("dashboard") ||
              path.includes("admin") ||
              path === "/"
                ? "bg-[#D09600] text-[#fff]"
                : "bg-[transparent] text-[#292D32]"
            } `}
          >
            <div className="flex items-center">
              <img
                src={
                  (accountType === "business" && path.includes("loan")) ||
                  path.includes("investor") ||
                  path.includes("dashboard") ||
                  path === "/"
                    ? "/images/category-active.svg"
                    : "/images/category.svg"
                }
                alt="icon"
              />
              <span className="text-[20px]  ml-[13px]">Dashboard</span>
            </div>

            <img
              className=""
              alt="icon"
              src={
                (accountType === "business" && path.includes("loan")) ||
                path.includes("investor") ||
                path.includes("dashboard") ||
                path === "/"
                  ? "/images/caret-down-white.svg"
                  : "/images/caret-down-black.svg"
              }
            />
          </button>
          {/* {dashboardMenu} */}

          {accountType === "admin" && (
            <button
              onClick={() => {
                setShowLoanTypes(!showLoanTypes);

                if (!path.includes("loan-requests")) {
                  navigate("/loan-requests/sme");
                }
              }}
              className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
                path.includes("loan-request")
                  ? "bg-[#D09600] text-[#fff]"
                  : "bg-[#fff] text-[#292D32]"
              }`}
            >
              <div className="flex items-center">
                <img
                  src={
                    path.includes("loan-requests")
                      ? "/images/loan-icon-active.svg"
                      : "/images/loan-icon.svg"
                  }
                  alt="icon"
                />
                <span className="text-[20px] ml-[13px]">Loan request</span>
              </div>

              <img
                className={`${showLoanTypes ? "rotate-180" : ""}`}
                alt="icon"
                src={
                  path.includes("/loan-request")
                    ? "/images/caret-down-white.svg"
                    : "/images/caret-down-black.svg"
                }
              />
            </button>
          )}
          {showLoanTypes && path.includes("loan-request") && loanReqestMenu}

          <button
            onClick={() => navigate("/wallet")}
            className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
              path.includes("wallet")
                ? "bg-[#D09600] text-[#fff]"
                : "bg-[#fff] text-[#292D32]"
            }`}
          >
            <div className="flex items-center">
              <img
                src={
                  path.includes("wallet")
                    ? "/images/wallet-icon-active.svg"
                    : "/images/wallet-icon.svg"
                }
                alt="icon"
              />
              <span className="text-[20px] ml-[13px]">Wallet</span>
            </div>

            <img
              className=""
              alt="icon"
              src={
                path.includes("/wallet")
                  ? "/images/caret-down-white.svg"
                  : "/images/caret-down-black.svg"
              }
            />
          </button>
          {accountType !== "business" &&
            accountType?.toLowerCase() !== "partner" && (
              <button
                onClick={() => navigate("/marketplace")}
                className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
                  path.includes("marketplace")
                    ? "bg-[#D09600] text-[#fff]"
                    : "bg-[#fff] text-[#292D32]"
                }`}
              >
                <div className="flex items-center">
                  <img
                    src={
                      path.includes("marketplace")
                        ? "/images/marketplace-icon-active.svg"
                        : "/images/marketplace-icon.svg"
                    }
                    alt="icon"
                  />
                  <span className="text-[20px] ml-[13px]">Marketplace</span>
                </div>

                <img
                  className=""
                  alt="icon"
                  src={
                    path.includes("/marketplace")
                      ? "/images/caret-down-white.svg"
                      : "/images/caret-down-black.svg"
                  }
                />
              </button>
            )}

          {/* {accountType === "partner" && (
            <button
              onClick={() => navigate("/buy-in-requests")}
              className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
                path.includes("buy-in-requests")
                  ? "bg-[#D09600] text-[#fff]"
                  : "bg-[#fff] text-[#292D32]"
              }`}
            >
              <div className="flex items-center">
                <img
                  src={
                    path.includes("buy-in-requests")
                      ? "/images/archive-book.svg"
                      : "/images/archive-book-dark.svg"
                  }
                  className="h-[24px] w-[24px]"
                  alt="icon"
                />
                <span className="text-[20px] ml-[13px]">Buy-in requests</span>
              </div>

              <img
                className=""
                alt="icon"
                src={
                  path.includes("buy-in-requests")
                    ? "/images/caret-down-white.svg"
                    : "/images/caret-down-black.svg"
                }
              />
            </button>
          )} */}
          {/* {accountType === "partner" && (
            <button
              onClick={() => navigate("/sme-referrals")}
              className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
                path.includes("sme-referrals")
                  ? "bg-[#D09600] text-[#fff]"
                  : "bg-[#fff] text-[#292D32]"
              }`}
            >
              <div className="flex items-center">
                <img
                  src={
                    path.includes("sme-referrals")
                      ? "/images/sme-referral-active.svg"
                      : "/images/sme-referral.svg"
                  }
                  className="h-[24px] w-[24px]"
                  alt="icon"
                />
                <span className="text-[20px] ml-[13px]">SME Referrals</span>
              </div>

              <img
                className=""
                alt="icon"
                src={
                  path.includes("sme-referrals")
                    ? "/images/caret-down-white.svg"
                    : "/images/caret-down-black.svg"
                }
              />
            </button>
          )} */}

          {accountType === "admin" && (
            <button
              onClick={() => {
                setShowUserTypes(!showUserTypes);

                if (!path.includes("users")) {
                  navigate("/users");
                }
              }}
              className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
                path.includes("users")
                  ? "bg-[#D09600] text-[#fff]"
                  : "bg-[#fff] text-[#292D32]"
              }`}
            >
              <div className="flex items-center">
                <img
                  src={
                    path.includes("users")
                      ? "/images/users-active.svg"
                      : "/images/users.svg"
                  }
                  alt="icon"
                />
                <span className="text-[20px] ml-[13px]">User</span>
              </div>

              <img
                className={`${showUserTypes ? "rotate-180" : ""}`}
                alt="icon"
                src={
                  path.includes("/users")
                    ? "/images/caret-down-white.svg"
                    : "/images/caret-down-black.svg"
                }
              />
            </button>
          )}
          {showUserTypes && path.includes("users") && userTypeMenu}

          <button
            onClick={() => navigate("/settings")}
            className={`mb-[30px] w-[100%] flex justify-between items-center px-[44px] py-[12px] ${
              path.includes("settings")
                ? "bg-[#D09600] text-[#fff]"
                : "bg-[#fff] text-[#292D32]"
            }`}
          >
            <div className="flex items-center">
              <img
                src={
                  path.includes("settings")
                    ? "/images/setting-icon-active.svg"
                    : "/images/setting-icon.svg"
                }
                alt="icon"
              />
              <span className="text-[20px] ml-[13px]">Settings</span>
            </div>

            <img
              className=""
              alt="icon"
              src={
                path.includes("settings")
                  ? "/images/caret-down-white.svg"
                  : "/images/caret-down-black.svg"
              }
            />
          </button>
        </div>

        <div className="px-[19px] mt-[100px] pb-[50px]">
          <p className="text-[20px] text-[#292D32] mb-[14.5px]">Profile</p>

          <div className="flex items-center">
            {!currentUser?.currentUser?.profile_image && (
              <div className="bg-[#000] flex items-center justify-center rounded-full h-[52px] w-[52px]">
                <h4 className="text-[18px] text-[#fff] font-[700]">
                  {currentUser?.currentUser?.name?.split(" ")[0][0]}
                  {currentUser?.currentUser?.name?.split(" ")[0].length > 1
                    ? currentUser?.currentUser?.name?.split(" ")[0][1]
                    : ""}
                </h4>
              </div>
            )}
            {currentUser?.currentUser?.profile_image && (
              <img
                src={currentUser?.currentUser?.profile_image}
                className="w-[52px] h-[52px] rounded-full  object-cover overflow-hidden"
                alt="profile"
              />
            )}
            <div className="ml-[10px]">
              <h5 className="text-[#292D32] text-[18px]">
                {currentUser?.currentUser?.name}
              </h5>
              <span className="mt-[4px] text-[#292D32] text-[14px] font-[300]">
                {currentUser?.currentUser?.email}
              </span>
            </div>
          </div>

          <button
            onClick={async () => {
              await logout();

              navigate("/auth/login");
            }}
            className="rounded-[5px] px-[64px] py-[10px] active:bg-[#ffece6c4] bg-[#FFECE6] mt-[37px] text-[#FF3D00] flex items-center font-[600] text-[18px]"
          >
            <img alt="icon" src="/images/logout.svg" />
            <span className="ml-[15px]">Log out</span>
          </button>
        </div>
      </div>
    </div>
  );
}
