/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom"
import AuthHeader from "../auth/auth-header"
import { LoanDetails, Marketplace } from "../investor-dashboard"
import Products from "../../components/Products"

const PARTNERS = [
  {
    image:
      "https://images.unsplash.com/photo-1496851473196-e26508c21494?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80",
    name: "Smart Business Hub",
    text: "Monzone has fast turnaround time for SME loans. So far they have funded 5 of our SMEs"
  },
  {
    image:
      "https://images.unsplash.com/photo-1496851473196-e26508c21494?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80",
    name: "CLIQIT",
    text: "We needed money to execute an LPO and while we were waiting for our bank account officer to get back to us, we applied to Monzone and got credited same day."
  },
  {
    image:
      "https://images.unsplash.com/photo-1496851473196-e26508c21494?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80",
    name: "Lions towers",
    text: "Monzone has been our financial partner for the last 6 months, they are genuinely interested in the growth of my business. They offer all round financial support."
  },
  {
    image:
      "https://images.unsplash.com/photo-1496851473196-e26508c21494?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1035&q=80",
    name: "Lumiere Apparels",
    text: "Monzone helped us expand our business and the process was seamless.  We were denied by banks and many financial institutions. Monzone studied our cashflow and made our  dream a reality."
  }
]

const FAQ = [
  {
    q: "Does Monzone give loan to any SME?",
    a: {
      __html:
        "<p>Yes, Monzone supports small and  medium scale businesses In selected sectors with the tools and finance to grow. <br/> These sectors are: Real Estate, Power, Logistics, Oil and gas and Trading. It is however subject to changes   depending on market conditions.</p>"
    }
  },
  {
    q: "What is the criteria?",
    a: {
      __html: `<p>The main criteria is that all entities requesting our facility must be registered and have an healthy/growing cashflow <br/> <br/>
        
        <b>For LPOs</b> <br/>
            - The LPO must come from one of our approved LPO issuing companies. <br/> <br/>

        <b>For Invoice Discounting</b> <br/>
        <ul>
        <li> - Invoice must be verifiable </li>
        <li> - Invoice must be reputable organisations </li>
        </ul>
        </p>
        `
    }
  },
  {
    q: "How much does Monzone finance?",
    a: {
      __html:
        "Monzone and, by extension, our marketplace, finances varying amount of loans but treats each request on a per case basis. Kindly reach out to us through your application as we make sure to review credit and service requests within 24 hours"
    }
  },
  {
    q: "Are investments in the marketplace safe? ",
    a: {
      __html:
        "<p> Yes they are. The loans are guaranteed by Monzone, Investors get their payouts on schedule. It is fully secure</p>"
    }
  }
]
interface LoanRequestProps {
  amount: number
  setAmount: (amount: any) => void
  duration: string
  setDuration: (duration: any) => void
  navigate: any
}
const RequestLoan = ({
  amount,
  setAmount,
  duration,
  setDuration,
  navigate
}: LoanRequestProps) => (
  <div
    className="drop-shadow-lg sm:mt-[0] mt-[100px] sm:ml-[32px] px-[32px] rounded-[10px] py-[67px] bg-[#201700] xl:max-w-[40vw] sm:block flex-1 sm:mt-[0]  min-w-[200px] max-[600px]:w-[100%] max-[600px]:h-[auto] self-center   sm:rotate-[30deg] active:rotate-[0] hover:rotate-[0] "
    style={{
      transition: ".5s ease-in-out"
    }}
  >
    <form className="w-[100%]" onSubmit={(e) => e.preventDefault()}>
      <h3 className="text-[20px] text-center text-[#fff] mb-[17px] font-[600]">
        Loan Request
      </h3>

      <label className="bg-[#3E2D00] rounded-[10px] py-[10px] px-[15px] flex w-[100%] justify-between items-center text-[#FFFFFF80] font-[700] text-[16px]">
        <span>Tenure</span>

        <select
          onChange={(e) => setDuration(e.target.value)}
          className="outline-none border-[1.5px] bg-[transparent] rounded-[7px] border-[#FFF] text-[#FFF] text-[14px] font-[400] px-[8px] py-[10px]"
        >
          <option value={3}>3 months</option>
          <option value={6}>6 months</option>
          <option value={10}>10 months</option>
          <option value={12}>12 months</option>
        </select>
      </label>

      <label className="bg-[#3E2D00] mt-[17px] rounded-[10px] py-[10px] px-[15px] flex w-[100%] justify-between items-center text-[#FFFFFF80] font-[700] text-[16px]">
        <span>Amount (&#8358;)</span>

        <input
          type={"number"}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-[91px] outline-none border-[1.5px] bg-[transparent] rounded-[7px] border-[#FFF] text-[#FFF] text-[14px] font-[400] px-[6px] py-[10px]"
        />
      </label>
      {/* 
      <p className="self-start text-[17px] mt-[5px] mb-[30px] text-[#fff]">
        We take <span className="text-[#D09600]">3%</span> interest
      </p> */}

      <div className="bg-[#3E2D00] text-[16px] text-[#FFF] font-[400] px-[15px] py-[25px] rounded-[10px]">
        <div className="mb-[17px] flex items-center justify-between">
          <span>Tenure</span>
          <span>{duration}</span>
        </div>
        <div className="mb-[17px] flex items-center justify-between">
          <span>Amount</span>
          <span>(&#8358;){(amount * 1).toLocaleString()}</span>
        </div>
        <div className="mb-[17px] flex items-center justify-between">
          <span>Interest</span>
          <span>
            (&#8358;)
            {(amount * 0.03 * parseInt(duration)).toLocaleString()}
          </span>
        </div>
        <div className="mb-[38px] flex items-center justify-between">
          <span>Total payback</span>
          <span>
            (&#8358;)
            {amount
              ? (
                  amount * 0.03 * (duration ? parseInt(duration) : 1) +
                  parseFloat(amount.toString())
                ).toLocaleString()
              : 0}
          </span>
        </div>

        <button
          onClick={() => navigate("/auth/register")}
          className="text-[16px] font-[400] text-[#fff] w-[100%] h-[46px] rounded-[8px] active:bg-[#d09500b1] bg-[#D09600]"
        >
          Request Loan
        </button>
      </div>
    </form>
  </div>
)

export const Footer = () => (
  <>
    <footer className="sm:hidden flex text-[#fff] sm:items-center sm:flex-row flex-col justify-between bg-[#2A1E00] py-[60px] xl:px-[150px] sm:px-[100px]  px-[5%]">
      <div className="sm:mr-[100px] flex-1">
        <img
          src="/images/lp_logo.svg"
          alt="logo"
          className="sm:min-w-[152.34px] sm:min-h-[152px] max-[600px]:w-[157.74px] max-[600px]:h-[43.11px]"
        />

        <h3 className="sm:block hidden mt-[49px] font-[600] text-[24px] mb-[14px]">
          Subscribe to our Newsletter
        </h3>

        <p className="sm:block hidden font-[300] sm:text-[20px] text-[13px]">
          Stay ahead of the rest by subscribing to receive news about our
          special offers and new features{" "}
        </p>

        <div className="mt-[26px] flex items-center p-[4px] bg-[#fff] rounded-[7.15px]">
          <input
            className="px-[10px] outline-none h-[36.07px] bg-[#fff] w-[100%]"
            placeholder="Your email"
          />

          <button className="bg-[#D09600] text-[#fff] text-[12.2px] h-[36.07px] px-[14px] rounded-[7.32px]">
            Subscribe
          </button>
        </div>

        <div className="sm:flex hidden items-center mt-[40px] cursor-pointer">
          <img alt="icon" src="/images/twitter.svg" />
          <img alt="icon" src="/images/facebook.svg" className="mx-[35px]" />
          <img alt="icon" src="/images/linkedin.svg" />
        </div>
      </div>

      {[
        { label: "Home", link: "/" },
        { label: "About", link: "/about" },
        { label: "Blog", link: "/blog" }
      ].map((d, index) => (
        <div key={index} className="xl:mr-[150px] sm:mr-[50px] cursor-pointer">
          <h3 className="mt-[49px] font-[600] sm:text-[24px] text-[20px] mb-[14px]">
            {/* {d.label} */}
          </h3>
          <div className="sm:block flex flex-col  sm:items-center sm:justify-between flex-wrap">
            {[d].map((v, index) => (
              <Link
                to={v.link}
                className="pr-[15px] font-[300] sm:text-[20px] text-[13px] mb-[14px]"
                key={index}
              >
                {v.label}
              </Link>
            ))}
          </div>
        </div>
      ))}

      <h3 className="sm:hidden block mt-[49px] font-[600] text-[20px] mb-[14px]">
        Subscribe!
      </h3>

      <p className="sm:hidden block font-[300]  text-[13px]">
        Stay ahead of the rest by subscribing to receive news about our special
        offers and new features{" "}
      </p>

      <div className="mt-[26px] flex sm:hidden items-center p-[4px] bg-[#fff] rounded-[7.15px]">
        <input className="px-[10px] outline-none h-[36.07px] bg-[#fff] w-[100%]" />

        <button className="bg-[#D09600] text-[#fff] text-[12.2px] h-[36.07px] px-[14px] rounded-[7.32px]">
          Subscribe
        </button>
      </div>
      <div className="sm:hidden flex items-center mt-[40px] cursor-pointer">
        <img
          alt="icon"
          src="/images/twitter.svg"
          className="w-[24px] h-[24px] min-w-[24px] min-h-[24px]"
        />

        <img
          alt="icon"
          src="/images/facebook.svg"
          className="mx-[35px] w-[24px] h-[24px] min-w-[24px] min-h-[24px]"
        />
        <img
          alt="icon"
          src="/images/linkedin.svg"
          className="w-[24px] h-[24px] min-w-[24px] min-h-[24px]"
        />
      </div>
    </footer>

    <footer className="sm:flex hidden bg-[#fff] px-[10%] py-[5%] p flex-col">
      <div className="bg-[#2A1E00] p-[5%] text-[#fff] flex justify-between rounded-[35px]">
        <div className="max-w-[60%]">
          <h3 className="sm:block hidden mt-[49px] font-[600] text-[40px] mb-[14px]">
            Subscribe to our Newsletter
          </h3>

          <p className="sm:block hidden font-[300] sm:text-[20px] text-[13px]">
            Stay ahead of the rest by subscribing to receive news about our
            special offers and new features{" "}
          </p>

          <div className="mt-[26px] flex items-center p-[4px] bg-[#fff] rounded-[7.15px]">
            <input
              className="px-[10px] outline-none h-[36.07px] bg-[#fff] w-[100%]"
              placeholder="Your email"
            />

            <button className="bg-[#2A1E00] text-[#fff] text-[12.2px] h-[36.07px] px-[14px] rounded-[7.32px]">
              Subscribe
            </button>
          </div>
        </div>

        <img
          alt="footer"
          src="/images/lady-mic.jpeg"
          style={{ width: "340px", height: "250px", borderRadius: "15px" }}
        />
      </div>

      <hr className="border-[1px]" />

      <p className="text-center mt-[20px]">
        Copyright 2023 &copy; MonzoneCredit. All rights reserved.
      </p>
    </footer>
  </>
)

function LandingHome() {
  const navigate = useNavigate()
  const isLoggedIn = localStorage.getItem("isLoggedIn")
  const accountType = localStorage.getItem("account-type")

  const [loanAmount, setLoanAmount] = useState(0)
  const [duration, setDuration] = useState("3 months")
  const [showLoanDetails, setShowLoanDetails] = useState(false)
  const [_, setSelectedLoan] = useState<any>()
  const [showAnswer, setShowAnswer] = useState(false)
  const [selectedFaq, setSelectedFaq] = useState(0)
  let location = useLocation()

  const redirect = () => {
    if (isLoggedIn !== null && accountType) {
      console.log(location, isLoggedIn, accountType)

      if (accountType === "business") {
        navigate("/loan/dashboard")
      } else if (accountType === "investor") {
        navigate("/investor/dashboard")
      } else if (accountType === "admin") {
        navigate("/admin/dashboard")
      }
    }
  }
  useEffect(() => {
    redirect()
  }, [location])

  return (
    <div className="bg-[#fff] min-h-[100vh] overflow-x-hidden">
      <div className="w-[100%] bg-[#2A1E00] ">
        <AuthHeader
          text_color="text-[#fff]"
          logo_image="/images/lp_logo.svg"
          background_color="bg-[#2A1E00]"
        />
        <div className="sm:px-[78px] items-center overflow-y-hidden overflow-x-hidden px-[20px] pb-[50px] sm:py-[30px] py-[10px]  flex flex-wrap max-[600px]:flex-col ">
          <div className="flex-1 z-[100] ">
            <h2 className="sm:leading-[68px] font-[600] sm:text-[64px] text-[40px] text-[#fff]">
              Get <span className="text-[#D09600]">funding</span> for your{" "}
              <br className="max-[412px]:hidden" />{" "}
              <span className="text-[#D09600]">business</span>
            </h2>
            <p className="my-[29px]  sm:text-[20px] text-[13px] text-[#fff] ">
              Access instant loans for your business, quick credit for Local
              Purchase Orders and access cash today for tomorrows invoice{" "}
            </p>

            <button
              onClick={() => navigate("/auth/register")}
              className=" rounded-[8px] bg-[#D09600] active:bg-[#d09500b1] text-[#fff] sm:px-[30px] sm:py-[15px] px-[23px] py-[12px] sm:text-[20px] text-[14px]"
            >
              Get started
            </button>
          </div>

          <RequestLoan
            navigate={navigate}
            amount={loanAmount}
            setAmount={setLoanAmount}
            duration={duration}
            setDuration={setDuration}
          />
          {/* <img alt='bg' src='/images/bg-image.svg' className='xl:max-w-[60vw] sm:block flex-1 sm:mt-[0]  min-w-[200px] max-[600px]:w-[100%] max-[600px]:h-[auto]  object-cover ' /> */}
        </div>
      </div>

      {/* <div className='bg-[#F9F9F9] sm:px-[100px] px-[20px] py-[48px] flex flex-col '>
                <h4 className='font-[600] text-[24px] text-center'>Trusted and backed by</h4>
                <div className='flex items-center justify-between sm:mt-[60px] mt-[25px] flex-wrap'>
                    {[0, 0, 0, 0].map((_, index) => (
                        <img alt='google' src='/images/google-logo.svg' className='mt-[30px] mr-[10px] max-[600px]:w-[79.79px] max-[600px]:h-[25px]' key={index} />
                    ))}
                </div>
            </div> */}

      <div className="py-[120px] sm:px-[75px] px-[20px] bg-[#fff]">
        <div className="sm:px-[68px] sm:py-[76px] px-[30px] py-[40px] bg-[#FAF5E6] rounded-[15px] flex items-center flex-wrap-reverse">
          <div className="sm:w-[493px] w-[100%] max-[640px]:mt-[25px] bg-[#2A1E00] text-[#fff] flex flex-col items-center rounded-[12px]">
            <img
              alt="close"
              src="/images/money.jpeg"
              className="rounded-[10px]"
            />
          </div>

          <div className="sm:ml-[100px] ml-[0] sm:mt-[0] max-w-[640px]:mt-[0px] mt-[25px] flex-1">
            <p className="text-[#D09600] font-[600] text-[24px] ">
              LPO Financing
            </p>

            <h4 className="font-[600] text-[36px] my-[25px]">
              Purchase orders should not wait
            </h4>
            <p className="text-[20px]">
              We finance LPO’s from thousands of companies so that you can get
              the job done quickly
            </p>
            <button
              onClick={() => navigate("/auth/register")}
              className="mt-[22px] w-[100%] py-[8px] rounded-[5.21px] active:bg-[#d09500b1]  bg-[#D09600] text-[#fff]"
            >
              Get Started
            </button>
          </div>
        </div>

        <div className="sm:mt-[100px] mt-[59px] sm:px-[68px] sm:py-[76px] px-[30px] py-[40px] bg-[#FAF5E6] rounded-[15px] flex items-center flex-wrap">
          <div className="sm:mr-[100px] mr-[0] sm:mb-[0] mb-[25px] max-w-[640px]:mb-[0px] flex-1">
            <p className="text-[#D09600] font-[600] sm:text-[24px] text-[20px]">
              Sell your invoice
            </p>

            <h4 className="font-[600] sm:text-[36px] text-[24px] my-[25px]">
              Need quick cash?
            </h4>
            <p className="text-[20px]">
              Done the job, need quick cash? Sell your invoices to thousands of
              buyers so that you can move on to the next one
            </p>
            <button
              onClick={() => navigate("/auth/register")}
              className="mt-[22px] w-[100%] py-[8px] rounded-[5.21px] active:bg-[#d09500b1]  bg-[#D09600] text-[#fff]"
            >
              Get Started
            </button>
          </div>

          <div className="sm:w-[493px] w-[100%] max-[640px]:mt-[25px] bg-[#2A1E00] text-[#fff] flex flex-col items-center rounded-[12px]">
            <img
              alt="close"
              src="/images/img-1.jpeg"
              className="rounded-[10px]"
            />
          </div>
        </div>

        <div className="flex flex-col items-center w-[100%] sm:mt-[200px] mt-[48px] sm:pb-[100px] ">
          <h3 className="font-[700] text-[24px] sm:text-[48px] text-center">
            High performance metrics
          </h3>
          <p className="sm:mt-[21px] mt-[13px] mb-[48px] font-[500] text-[14px] sm:text-[24px]">
            24/7 Credit guarantee backed by happy investors
          </p>
          <div className="w-[90%] flex items-center flex-wrap justify-center">
            <div className="text-center sm:mr-[110px] mr-[10px]">
              <h2 className="text-[#D09600] font-[700]  sm:text-[84px] text-[24px] mt-[10px]">
                ₦‎450M
              </h2>
              <p className="sm:mt-[20px] sm:text-[14px] text-[12px] font-[700]">
                Amount Disbursed
              </p>
            </div>
            <div className="text-center sm:mr-[110px] mr-[10px]">
              <h2 className="text-[#D09600] font-[700] sm:text-[84px] text-[24px] mt-[10px]">
                63
              </h2>
              <p className="sm:mt-[20px] sm:text-[14px] text-[12px] font-[700]">
                Happy Customers
              </p>
            </div>
            <div className="text-center ">
              <h2 className="text-[#D09600] font-[700]  sm:text-[84px] text-[24px] mt-[10px]">
                99%
              </h2>
              <p className="sm:mt-[20px] sm:text-[14px] text-[12px] font-[700]">
                Success Rate
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Market place beofre */}

      <Products
      // setSelectedLoan={setSelectedLoan}
      // setShowLoanDetails={setShowLoanDetails}
      />

      {/* Test starts */}
      {/* <div className="Testimonials-module--Testimonials_main--TbGSH">
        <div className="Testimonials-module--Testimonials_content--rWHU4">
          <h3 className="Testimonials-module--Testimonials_content_title--CSTvX">
            Brass, with the sub-accounts feature, makes it easy to segment our
            cash and in turn optimise it for targeted goals
          </h3>
          <p>Feranmi Ajetunmobi, Ni-Fries</p>
          <div className="Testimonials-module--Testimonials_content_indicators--GIrKl">
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2"></span>
            <span className="Testimonials-module--Testimonials_content_indicator--FSkc2 Testimonials-module--Testimonials_content_indicator_active--9LSlf"></span>
          </div>
         
        </div>
        <div className="Testimonials-module--Testimonials_image--Vxscn">
          <img src="/images/shake.jpg" alt="Feranmi Ajetunmobi, Ni-Fries" />
        </div>
      </div> */}

      {/* Test ends */}

      <div className="sm:py-[200px] py-[120px] bg-[#fff] px-[5%] text-[#292D32] flex flex-col text-center items-center">
        <h4 className="font-[700] sm:text-[48px] text-[30px] mb-[21px]">
          Testimonials
        </h4>
        <p className="font-[500] sm:text-[24px] text-[18px]">
          Here's what our partners say
        </p>

        <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 text-left sm:mt-[120px] mt-[60px]">
          {PARTNERS.map(({ name, image, text }, index) => (
            <div
              key={index}
              className="px-[47px] text-[#fff] py-[35px] bg-[#2A1E00] rounded-[10px]"
            >
              <h6 className="font-[500] sm:text-[24px] text-[15.81px]">
                {name}
              </h6>
              <div className="mt-[15px] flex items-center">
                <img
                  src={image}
                  alt="partner"
                  className="sm:w-[81px] sm:h-[81px] w-[53.35px] h-[53.35px]  rounded-full object-contain"
                />
                <span className="font-[400] text-left sm:text-[18px] text-[13.17px] sm:ml-[29px] ml-[19px]">
                  {text}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="sm:py-[100px] pb-[40px] bg-[#fff] px-[5%] text-[#292D32] flex flex-col text-center items-center">
        <h4 className="font-[700] sm:text-[48px] text-[24px] mb-[21px]">
          Frequently asked questions
        </h4>

        {FAQ.map(({ q, a }, index) => (
          <div
            key={index}
            className="xl:max-w-[1000px] sm:max-w-[900px] w-[100%]  py-[33px] border-b-[1px] border-b-[#292D3280]"
          >
            <div
              className="flex items-center justify-between cursor-pointer"
              onClick={() => {
                setSelectedFaq(index)
                setShowAnswer(!showAnswer)
              }}
            >
              <p className="sm:text-[24px] text-[14px] font-[500] ">{q}</p>
              <button
                className={
                  selectedFaq === index && showAnswer
                    ? "border-[2px] border-[#000] h-[22px] w-[22px] rounded-full flex items-center justify-center "
                    : "rotate-45"
                }
                onClick={() => {
                  setSelectedFaq(index)
                  setShowAnswer(!showAnswer)
                }}
              >
                <img
                  alt="close"
                  className={
                    selectedFaq === index && showAnswer
                      ? "w-[10px] h-[2px] object-cover"
                      : ""
                  }
                  src={
                    selectedFaq === index && showAnswer
                      ? "/images/line.svg"
                      : "/images/close-circle.svg"
                  }
                />
              </button>
            </div>
            {selectedFaq === index && showAnswer && (
              // eslint-disable-next-line react/no-danger-with-children
              <div className="text-left mt-[9px]" dangerouslySetInnerHTML={a} />
            )}
          </div>
        ))}
      </div>

      <Footer />
    </div>
  )
}

export default LandingHome
