import React, { FormEvent, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import AuthHeader from "./auth-header"
import "./styles.css"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { updateUser } from "../../store/user-reducer"
import axios from "axios"
import OtpPopUp from "../../components/otp-popup"
import { Modal } from "../../components"

interface ISignupResponse {
  url?: string
}

function Signin() {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [showOtpPopup, setShowOtpPopup] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const [verifyBVN, setVerifyBVN] = useState(false)
  const [signupResponse, setSignupResponse] = useState<ISignupResponse>({})

  const login = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    try {
      const response = await axios.post("users/signin", {
        email,
        password
      })

      console.log(response)

      if (
        !response.data?.currentUser?.consent_given ||
        response.data?.consentResponse?.url
      ) {
        toast.error("Please Grant BVN Consent!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light"
        })
        setSignupResponse({
          url: response.data?.consentResponse?.url
        })
        setVerifyBVN(true)
      } else {
        let data = {
          currentUser: response.data.currentUser,
          credentials: response.data.credentials
        }

        dispatch(updateUser(data))

        localStorage.setItem("@user", JSON.stringify(data))
        localStorage.setItem("@token", JSON.stringify(response.data.token))
      }

      // /window.location.reload();
      if (response?.data?.currentUser?.account_type === "business") {
        navigate("/loan/dashboard")
      } else if (response?.data?.currentUser?.account_type === "investor") {
        navigate("/investor/dashboard")
      } else if (response?.data?.currentUser?.account_type === "partner") {
        navigate("/partner/dashboard")
      } else if (response?.data?.currentUser?.account_type === "admin") {
        navigate("/admin/dashboard")
      }
      toast.success(response.data.message, {
        position: "top-right",

        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })
      setLoading(false)
    } catch (error: any) {
      console.log(error)
      setLoading(false)
      let errorMessage = error?.response?.data?.errors[0]
      console.log(errorMessage)

      toast.error(errorMessage ? errorMessage.message : "An Error Occurred!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light"
      })

      if (errorMessage.message.includes("Please Verify Your Email")) {
        setShowOtpPopup(true)
      }
    }
  }
  return (
    <div className="h-[100%]">
      <AuthHeader />
      <div className="flex sm:px-[48px] px-[24px] h-[80%]">
        <div className="login-side sm:flex sm:item-center sm:justify-center hidden mr-[100px] rounded-[20px] flex-1 w-[100%] min-h-[710px]   hidden ">
          <img alt="icon" src="/images/login-image.svg" className="" />

          <div className="absolute top-[20%] left-[40%] rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px] h-[130px]">
            <h6 className="mb-[6px] text-[#292D32] text-[16px]">
              Loan Request
            </h6>

            <div className="px-[9px] mb-[11px] text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
            <div className="px-[9px]  text-[#fff] font-[700] text-[10.32px] flex justify-between items-center bg-[#D09600] w-[100%] h-[34px] rounded-[6.45px]">
              <span>Tenure</span>

              <select
                className="px-[6px] border-[1px] border-[#fff] rounded-[4.52px] bg-[#D09600]"
                disabled
              >
                <option>3 months</option>
              </select>
            </div>
          </div>

          <div className="bottom-[15%] xl:left-[15%] left-[10%] absolute rounded-[7.74px] bg-[#FFF8E5] p-[13px] w-[246px]">
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Tenure</span>
              <span className="">3 months</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Amount</span>
              <span className="">₦50,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Interest</span>
              <span className="">₦1,000</span>
            </div>
            <div className="flex mb-[12px] items-center justify-between text-[11.6px] text-[#292D32]">
              <span className="">Total payback</span>
              <span className="">₦51,000</span>
            </div>

            <button className="mt-[27px] text-[11.6px] outline-none bg-[#D09600] text-[#fff] py-[9px] w-[100%] rounded-[5.8px]">
              Request loan
            </button>
          </div>
        </div>

        <form
          className="flex-1 flex-col flex"
          onSubmit={(e) => {
            login(e)
          }}
        >
          <div className="sm:mb-[59px] mb-[55px]">
            <h4 className=" md:text-[48px] text-[24px] sm:text-left text-center font-[700]">
              Sign in to Monzone{" "}
            </h4>
            <div className="flex items-center sm:justify-start justify-center md:text-[20px] text-[15px] mt-[7px]">
              <p className="mr-[5px] md:text-left text-center">
                New to Monzone?{" "}
              </p>
              <Link
                to={"/auth/register"}
                className="text-[#D09600] md:text-left text-center"
              >
                Create account
              </Link>
            </div>
          </div>

          <label className="sm:text-[20px] text-[16px]">
            Enter email address
            <input
              value={email}
              type={"email"}
              onChange={(e) => setEmail(e.target.value)}
              className="mb-[24px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>
          <label className="sm:text-[20px] text-[16px]">
            Enter password
            <input
              value={password}
              type={"password"}
              onChange={(e) => setPassword(e.target.value)}
              className="sm:mb-[65px] mb-[46px] outline-none mt-[9px] sm:h-[55px] h-[40px] w-[100%] rounded-[5px] px-[26px] border-[1px] border-[#292D32] sm:text-[16px] text-[14px]"
            />
          </label>
          <label className="sm:text-[16px] text-[14px] mt-[-40px] flex justify-end items-center mb-[8px]">
            <Link
              to={"/auth/resetpassword"}
              className="text-[#D09600] md:text-left text-left"
            >
              Forgot Password?
            </Link>
          </label>

          <button
            disabled={loading}
            type="submit"
            className={`${
              !email.length && !password.length ? "opacity-10" : "opacity-100"
            } flex items-center justify-center rounded-[8.43px] sm:min-h-[62px] sm:h-[62px] h-[40px] active:bg-[#d09500c1] bg-[#D09600] w-[100%] sm:text-[20px] text-[14px] text-[#fff]`}
          >
            {loading && (
              <svg
                aria-hidden="true"
                className="w-[20px] h-[20px] mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#fff"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            )}
            {loading ? "Logging in..." : "Sign In"}
          </button>
        </form>
      </div>

      {showOtpPopup && (
        <OtpPopUp setShowPopup={setShowOtpPopup} email={email} />
      )}

      {verifyBVN && (
        <div>
          <Modal>
            <div
              className="bg-[#fff] w-[45%] h-[20%] relative"
              // style={{
              //   display: "none"
              // }}
            >
              <button
                onClick={() => {
                  setVerifyBVN(!verifyBVN)
                  // setConsentGiven(true)
                }}
                className="active:scale-75 transform-gpu absolute right-[10px] top-[10px]"
              >
                <img
                  alt="close"
                  src="/images/close-circle.svg"
                  className="sm:h-[21.5px] sm:w-[21.5px] h-[15px] w-[15px]"
                />
              </button>
              {/* <iframe
                src={signupResponse.url}
                title="Grant Bvn Consent"
                width="97%"
                height="100%"
                // allowFullScreen={true}
                ref={iframeRef}
              ></iframe> */}
              <div
                className="mt-[15px] border-[1px] p-[10px] flex flex-col items-center text-center"
                style={{ marginTop: "39px" }}
              >
                <p className="font-[600]">
                  <p>
                    <a
                      href={signupResponse.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      Kindly click Here to grant BVN Consent
                    </a>
                  </p>
                </p>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  )
}

export default Signin
